import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import cn from 'classnames'
import { useWindowSize } from '../../hook'
import VideoModal from './Teaser/VideoPlayerModal'

const Container = styled.div`
  min-height: calc(100vh);
  cursor: url(${(props) => props.cursorUrl}), auto;
  z-index : 0;
  svg:first-child {
    // height: 1200px;
  }
`
const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`
const TextCenterTop = styled.div`
  margin-top : 2vh;
  h2,
  h3 {
    font-family: 'grotesque_pro_light';
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.05rem;
  }
  bottom: -4%;
  @media (min-width: 768px) {
    // bottom: 0%;
  }
  @media (min-width: 1600px) {
    bottom: 2%;
  }
`

const StickerContainer = styled.div`
  top: ${(props) => props.top};
  left: ${(props) => props.imgLeft || 28}px;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'inherit')};
  img {
    width: ${(props) => props.imgWidth || 84}px;
  }
  p {
    position: absolute;
    top: calc(${(props) => props.top} + 16px);
    font-family: Reckless;
    transform: rotate(${(props) => props.textRotate}deg);
  }
`
const CenterContainer = styled.div`
  @media (min-width: 1280px) {
    // top: 10%;
    // max-height: 40vh;
  }
  @media (min-width: 1920px) {
    // top: 20%;
  }
`
const TextCenter = styled.h4`
  font-family: 'sharp_grotesque_05';
  font-size: 420px;
  line-height: 360px;
  // max-height : 50vh;
  // transform: scaleY(1.25);
  // transform-origin: 0 0;
  @media (min-width: 360px) {
    font-size: 460px;
    line-height: 400px;
  }
  @media (min-width: 768px) {
    font-size: 550px;
    line-height: 550px;
  }
  @media (min-width: 1280px) {
    font-size: 700px;
    line-height: 600px;
  }
  @media (min-width: 1680px) {
    font-size: 800px;
    line-height: 700px;
  }
  @media (min-width: 1920px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2560px) {
    font-size: 900px;
    line-height: 900px;
  }
  @media (min-width: 2880px) {
    font-size: 1000px;
    line-height: 1200px;
  }
  @media (min-width: 2880px) {
    font-size: 1400px;
    line-height: 1400px;
  }
`
const LiveInContainer = styled.div`
  width: 100%;
  top: 5%;
  height: 80vh;
`
const Sticker5Days = styled.img`
  top: 0;
  left: 15%;
  cursor: inherit;
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  @media (min-width: 1024px) {
    top: 5%;
    left: 20%;
  }
  @media (min-width: 1440px) {
    left: 25%;
  }
  @media (min-width: 1920px) {
    left: 30%;
  }
  @media (min-width: 2400px) {
    left: 35%;
  }
`
const StickerMay = styled.img`
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  bottom: 20%;
  right: 5%;
  cursor: inherit;
  @media (min-width: 768px) {
    bottom: -5%;
  }
  @media (min-width: 1024px) {
    right: 15%;
  }
  @media (min-width: 1280px) {
    right: 20%;
  }
  @media (min-width: 1600px) {
    right: 25%;
  }
`

const END_DATE_COUNTDOWN = '04/19/2021'

const randDirX = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY = Math.floor(Math.random() * 2) === 1 ? 1 : -1

export default function Teaser() {
  const ref = useRef()
  const endDay = dayjs(END_DATE_COUNTDOWN, 'MM/DD/YYYY')
  const daysLeft = endDay.diff(dayjs(), 'days')
  const hoursLeft = endDay.diff(dayjs(), 'hours') - (daysLeft || 0) * 24

  const [state, setState] = useState({ x: 0, y: 0})

  const onMouseMove = useCallback((e) => {
    setState({ x: e.nativeEvent.x, y: e.nativeEvent.y})
  }, [])


  const { screen } = useWindowSize()
  const isMobile = screen === 'sm'

  const rect = ref?.current?.getBoundingClientRect()

  const [videoOpen, setVideoOpen] = useState(false)

  const toggleOpenVideo = useCallback(() => setVideoOpen(v => !v), [])

  return (
    <>
    <VideoModal
      videoOpen={videoOpen}
      setVideoClose={toggleOpenVideo}
    />
    <Container
      className='w-full h-full bg-blue relative'
      cursorUrl='/static/svg/cursor-with-shadow.svg'
      onMouseMove={onMouseMove}
    >
      <div className='absolute bottom-0 w-full'>
        <img
          src={`/static/svg/teaser-bg${isMobile ? '' : '-xl'}.svg`}
          alt='bg-teaser'
          className='w-full'
        />
      </div>
      <StickerNavs />
      <StickerContainer
        imgWidth={isMobile ? 84 : 120}
        className='absolute'
        top='75%'
        textRotate={10}
      >
        <img src='/static/svg/yes-blue-circle.svg' alt='yes-blue-circle' />
      </StickerContainer>
      <StickerContainer
        imgWidth={isMobile ? 124 : 160}
        imgLeft={isMobile ? 76 : 84}
        className='absolute'
        top='78%'
        textRotate={10}
      >
        <img src='/static/svg/sod-circle-blue.svg' className='shadow-md' alt='yes-blue-circle' />
      </StickerContainer>
      <CenterContainer className='w-full absolute mx-auto'>
        <div className='text-center relative h-full '>
          <TextCenter>
            <span className='text-secondary' style={{letterSpacing: 4}}>{daysLeft}</span>
            <span className='text-light-grey' style={{letterSpacing: 4}}>d</span>{' '}
            <span className='text-secondary' style={{letterSpacing: 4}}>{hoursLeft}</span>
            <span className='text-light-grey' style={{letterSpacing: 4}}>h</span>
          </TextCenter>
          <Sticker5Days
            src='/static/svg/5-days.svg'
            alt='5-days'
            className={cn('absolute mx-auto cursor-pointer w-32 md:w-64 max-w-sm')}
          />
          <StickerMay
            src='/static/svg/may3-7.svg'
            alt='5-days'
            className={cn('absolute mx-auto cursor-pointer w-1/3 md:w-1/5 max-w-md')}
          />
          <TextCenterTop className='absolute w-full z-10'>
            <h2 className='text-white uppercase text-center'>George Brown College</h2>
            <h2 className='text-white uppercase text-center'>School of design</h2>
            <h3 className='text-white text-center'>
              Year End Show
              <a
                className='text-secondary'
                href='https://www.instagram.com/sod_yes/'
                // target='_blank'
                rel='noreferrer'
              >
                {' '}
                @sod_yes
              </a>
            </h3>
          </TextCenterTop>
        </div>
      </CenterContainer>
      <LiveInContainer
        className={cn('absolute mx-auto flex justify-center items-center clicker')}
        style={{
          position: 'absolute',
          top: '30%',
          left: '43%',
          width: '196px',
          height: '301px',
          transform: `translate3d(${(state.x / 20 - (rect?.x || 0) / 20) * randDirX}px, ${
            (state.y / 20 - (rect?.y || 0) / 20) * randDirY
          }px, 0)`,
        }}
      >
        <svg
          width={196}
          height={301}
          viewBox='0 0 196 301'
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          className='w-32 md:w-48 xl:w-64 max-w-sm cursor-pointer transition hover:text-blue'
          ref={ref}
          onClick={toggleOpenVideo}
        >
          <defs>
            <filter
              x='-34.9%'
              y='-24.6%'
              width='169.8%'
              height='149.2%'
              filterUnits='objectBoundingBox'
              id='prefix__a'
            >
              <feOffset dy={2} in='SourceAlpha' result='shadowOffsetOuter1' />
              <feGaussianBlur
                stdDeviation={15.5}
                in='shadowOffsetOuter1'
                result='shadowBlurOuter1'
              />
              <feColorMatrix
                values='0 0 0 0 0.349019608 0 0 0 0 0.2 0 0 0 0 1 0 0 0 1 0'
                in='shadowBlurOuter1'
                result='shadowMatrixOuter1'
              />
              <feMerge>
                <feMergeNode in='shadowMatrixOuter1' />
                <feMergeNode in='SourceGraphic' />
              </feMerge>
            </filter>
            <path id='prefix__b' d='M0 .98h182v257H0z' />
          </defs>
          <g filter='url(#prefix__a)' transform='translate(7 29)' fill='none'  fillRule='evenodd'>
            <g transform='matrix(-1 0 0 1 182 0)'>
              <mask id='prefix__c' fill='#fff'>
                <use xlinkHref='#prefix__b' />
              </mask>
              <path
                d='M22.996 15V.98h134.008V15c0 67.494-14.299 106.947-26.269 119.337-8.979-2.283-20.284-3.913-40.568-3.913-20.951 0-31.593 1.305-40.238 3.26-10.972-12.715-26.933-49.56-26.933-118.683zm66.837 123.576c51.21 0 69.167 10.434 69.167 50.864 0 41.735-15.962 50.54-69.167 50.54C32.97 239.98 21 230.197 21 189.44c0-40.43 15.962-50.864 68.833-50.864z'
                fill='#DEFC1C'
                mask='url(#prefix__c)'
              />
              <path
                fill='currentColor'
                mask='url(#prefix__c)'
                transform='rotate(-90 85 192.098)'
                d='M85 171.098l24.706 42H60.294z'
              />
            </g>
            <path
              d='M65.132 53.98l.84-6.408.216-5.928.168 5.928.768 6.408h8.448l2.4-17.28h-6.936l-.456 6.504.072 5.088-.456-5.112-.816-6.48h-6.384l-.936 6.48-.48 5.112.096-5.088-.36-6.504h-7.008l2.208 17.28h8.616zm25.68 0v-5.952h-6.096V47.5h6.096v-4.536h-6.096v-.504h6.096V36.7H77.924v17.28h12.888zm1.104-7.56c4.44-.024 5.616-1.92 5.616-5.904 0-3.072-.696-3.888-3.072-3.888-2.4 0-3.216.648-3.216 3.048 0 2.444.632 2.834 2.465 2.855l.439.001c-.192.936-.792 1.2-2.232 1.176v2.712zm13.008 7.56v-5.16h.024c.504 0 .648.096.648 1.176v2.832c0 .576.144.984.456 1.152h6.96c-.36-.216-.528-.624-.528-1.296v-2.712c0-2.664-.456-3.576-2.904-3.864 2.016-.528 3.216-1.704 3.216-4.416 0-3.312-1.656-4.992-6.864-4.992h-7.944v17.28h6.936zm.312-10.008h-.312v-1.824h.288c.456 0 .624.384.624.888v.024c0 .6-.192.912-.6.912zm20.832 10.008v-5.952h-6.096V47.5h6.096v-4.536h-6.096v-.504h6.096V36.7H113.18v17.28h12.888zm-47.904 22v-6.816h-4.992V58.7H66.14v17.28h12.024zm7.608 0V58.7h-6.984v17.28h6.984zm12.336 0l4.056-17.28h-7.08l-.936 5.472-.288 6.408-.168-6.408-.984-5.472h-7.08l3.816 17.28h8.664zm16.8 0v-5.952h-6.096V69.5h6.096v-4.536h-6.096v-.504h6.096V58.7H102.02v17.28h12.888zm-29.208 22V80.7h-6.984v17.28H85.7zm7.008 0v-4.608l-1.128-6.36 2.808 10.968h7.968V80.7h-6.312v3.96l1.392 7.416-3-11.376H86.3v17.28h6.408z'
              fill='#05001A'
              fillRule='nonzero'
            />
          </g>
        </svg>
      </LiveInContainer>
      <link rel="stylesheet" href="/static/transition-css.css" type="text/css" />
      <div className="left-layer z-10"></div>
      <div className="right-layer z-10"></div>
    </Container>
    </>
  )
}

function StickerNavs() {

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])


  return (
    <>
      <div className='w-full flex justify-between pl-10'>
        <div className='z-10 inline-block'>
          <TextLogo
            className='text-secondary uppercase'
            // onClick={() => navigate('/2021/teaser')}
          >
            Yes!2021
          </TextLogo>
        </div>
      </div>
    </>
  )
}
