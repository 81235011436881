import React, { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
// import { gql } from '@apollo/client'
import styled from 'styled-components'
import Grad from '../2020/Grad'
import { client } from '../..'
import { NavContext } from './states/nav'
import sort from 'fast-sort'
import Loading from './Loading'
import { QUERY } from '../../graphql'

const Center = styled.div`
  text-align: center;

  @media (min-width: 768px) {
    margin-top: 100px;
  }
`

const GradList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px 80px;
  margin: 0 auto;
  padding: 0 24px;
  font-family: 'reckless_light';

  @media (min-width: 576px) {
    grid-gap: 32px 80px;
  }

  @media (min-width: 768px) {
    max-width: ${(props) => props.theme.maxWidth};
    padding: 0;
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 80px;
  }
`

const Title = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 20px;
  margin: 0 auto 20px;
  padding: 0 24px;
  font-size: 32px;
  line-height: 1.1;
  letter-spacing: -1px;
  font-family: 'reckless_light';

  @media (min-width: 576px) {
    font-size: 44px;
    grid-template-columns: 1fr auto;
  }

  @media (min-width: 768px) {
    grid-gap: 50px 80px;
    margin-bottom: 50px;
    max-width: ${(props) => props.theme.maxWidth};
    padding: 0;
  }

  div {
    position: relative;
    display: block;
  }

  .dropdown {
    text-align: left;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  p {
    float: left;
  }
`

const Dropdown = styled.div`
  font-family: 'grotesque_pro_light';
  .dropbtn {
    color: black;
    padding: 4px;
    font-size: 20px;
    border: none;
    background-color: white;
    text-decoration: underline;
    font-family: 'grotesque_pro_light' !important;
  }

  .dropdown {
    position: relative;
    width: 100%;
    display: inline-block;
    float: right;

    @media (min-width: 768px) {
      padding-right: 150px;
      width: auto;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 400px;
    z-index: 1;
    text-align: left;
    padding: 0 5px 5px 5px;
  }

  .dropdown-content a {
    font-size: 20px;
    color: black;
    padding: 1px 4px;
    text-decoration: none;
    display: block;
    background-color: white;
    line-height: 24px;
  }
  .dropdown-content a:hover {
    text-decoration: underline;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
`

const links = [
  {
    name: 'Graphic Design',
    pathname: '/2020/grads',
    search: '?program=Graphic%20Design',
  },
  {
    name: 'Interaction Design',
    pathname: '/2020/grads',
    search: '?program=Interaction%20Design',
  },
  {
    name: 'Game - Art',
    pathname: '/2020/grads',
    search: '?program=Game%20-%20Art',
  },
  {
    name: 'Art & Design Foundation',
    pathname: '/2020/grads',
    search: '?program=Art%20%26%20Design%20Foundation',
  },
  {
    name: 'Interactive Media Management',
    pathname: '/2020/grads',
    search: '?program=Interactive%20Media%20Management',
  },
  {
    name: 'Digital Design - Game Design',
    pathname: '/2020/grads',
    search: '?program=Digital%20Design%20-%20Game%20Design',
  },
  {
    name: 'Concept Art for Entertainment',
    pathname: '/2020/grads',
    search: '?program=Concept%20Art%20for%20Entertainment',
  },
  {
    name: 'Design Management',
    pathname: '/2020/grads',
    search: '?program=Design%20Management',
  },
  {
    name: 'Interdisciplinary Design Strategy',
    pathname: '/2020/grads',
    search: '?program=Interdisciplinary%20Design%20Strategy',
  },
]

const Graduates = (props) => {
  /* eslint-disable-next-line */
  const shuffleArr = (arra1) => {
    var ctr = arra1.length,
      temp,
      index
    // While there are elements in the array
    while (ctr > 0) {
      // Pick a random index
      index = Math.floor(Math.random() * ctr)
      // Decrease ctr by 1
      ctr--
      // And swap the last element with it
      temp = arra1[ctr]
      arra1[ctr] = arra1[index]
      arra1[index] = temp
    }
    return arra1
  }

  const { state } = useContext(NavContext)
  const [users, setUsers] = useState([])
  const [usersFiltered, setUsersFiltered] = useState([])
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    client
      .query({
        query: QUERY.USERS_QUERY,
        variables: {
          where: {
            studentYear: 2020,
            activeAt_gt: '2019-12-31',
          },
        },
      })
      .then(({ data, loading, errors }) => {
        if (errors) {
          setError(errors)
        }
        if (!loading) {
          setLoading(false)
        }
        let arrayForSort = [...data.users]
        // const randUsers = shuffleArr(data.users);
        const sortLastnameUsers = sort(arrayForSort)
          .asc('lastName')
          .filter((user) => !!user.image)
        setUsers(sortLastnameUsers)
        setUsersFiltered(sortLastnameUsers)
      })
      .catch()
  }, [])

  useEffect(() => {
    if (state.keyword && state.keyword.length > 0) {
      const newUsers = users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(state.keyword.toLowerCase()) ||
          user.lastName.toLowerCase().includes(state.keyword.toLowerCase()),
      )
      setUsersFiltered(newUsers)
    } else {
      setUsersFiltered(users)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [state.keyword])

  if (loading) return <Loading />
  if (error) return <p>Error: {error.message}</p>

  return (
    <Center>
      {props.program ? (
        <>
          <Title>
            <div>
              <p>CLASS 2020</p>
            </div>
            <Dropdown>
              <div className='dropdown'>
                <button className='dropbtn'>Program</button>
                <div className='dropdown-content'>
                  {links.map((item) => (
                    <Link
                      key={item.name}
                      to={{
                        pathname: `/2020${item.pathname}`,
                        search: item.search,
                      }}
                    >
                      <span className='text'>{item.name}</span>
                    </Link>
                  ))}
                </div>
              </div>
            </Dropdown>
          </Title>
          <GradList>
            {usersFiltered.map((grad) => {
              if (grad.program === decodeURIComponent(props.program)) {
                return <Grad grad={grad} key={grad.id} />
              }
              return null
            })}
          </GradList>
        </>
      ) : (
        <>
          <Title>
            <div>
              <p>CLASS 2020</p>
            </div>
            <Dropdown>
              <div className='dropdown'>
                <button className='dropbtn'>Program</button>
                <div className='dropdown-content'>
                  {links.map((item) => (
                    <Link
                      key={item.name}
                      to={{
                        pathname: `/2020${item.pathname}`,
                        search: item.search,
                      }}
                    >
                      <span className='text'>{item.name}</span>
                    </Link>
                  ))}
                </div>
              </div>
            </Dropdown>
          </Title>
          <GradList>
            {usersFiltered.map((grad) => (
              <Grad grad={grad} key={grad.id} />
            ))}
          </GradList>
        </>
      )}
    </Center>
  )
}

export default Graduates
