import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import qs from 'query-string';
import { NavContext } from "../2020/states/nav";
import NavStyles from "./styles/NavStyles";
import { Link as LinkScroll, animateScroll as scroll } from 'react-scroll'
import Confetti from "./Confetti";

const MenuToggle = styled.div`
  align-items: center;
  align-self: end;
  padding: 1rem 1.5rem;
  display: flex;
  overflow: hidden;

  @media (min-width: 768px) {
    display: none;
  }

  .toggle-btn {
    height: 50px;
    width: 50px;
    padding: 0;
    background-color: #fff;
    border: none;
    justify-content: center;

    &:focus,
    &:hover {
      outline: none;
    }
  }
`;

const SearchContainer = styled.div`
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
  overflow: hidden;

  .search-btn {
    height: 50px;
    width: 50px;
    padding: 0;
    border-radius: 50%;
    justify-content: center;

    @media (min-width: 768px) {
      &:hover {
        background: #f3d7de;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
      }
    }
  }

  .search-overlay {
    display: none;
    max-width: 200px;

    &.open {
      display: block;
    }
  }

  .search-input {
    display: block;
    width: 100%;
    padding: 0 0.5rem;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-bottom: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      outline: none;
    }

    @media (max-width: 768px) {
      padding: 0;
      margin-right: 60px;
    }
  }
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showSearch: false,
      showConfetti: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  static contextType = NavContext;

  toggleMenu() {
    this.setState(state => ({
      showMenu: !state.showMenu
    }));
  }

  toggleSearch() {
    this.setState(state => ({
      showSearch: !state.showSearch
    }));
  }

  keyPress = (e) => {
    const { value } = e.target;
    if (e.keyCode === 13){
      if(this.props.location && this.props.location.pathname.startsWith('/2021/grads')){
      } else {
        const search = qs.stringify({ keyword: value });
        this.props.history.push({
          pathname: '/2021/work',
          search
        })
      }
    }
  };

  handleChange = (e) => {
    const { value } = e.target;
    if (this.props.location && this.props.location.pathname.startsWith('/2021/grads')){
      const { dispatch } = this.context;
      dispatch({ type: "SET_KEYWORD", payload: value })
    }
  };

  handleNav = (path) => {
    this.props.history.push(path)
  }
  handleScroll = () => {
    if(this.props.location.pathname !== '/'){
      this.props.history.push('/2021')
      setTimeout(() => {
        if(window.innerWidth < 768){
          scroll.scrollTo(900);
        } else {
          scroll.scrollTo(750);
        }

      },300)
    } else {
      this.setState({ showConfetti: true })
    }
  }

  render() {

    return (
      <>
        {this.state.showConfetti && <Confetti /> }
        <MenuToggle>
          <button className="toggle-btn" onClick={this.toggleMenu}>
            <img src={"/static/menu.svg"} alt="show menu" />
          </button>
        </MenuToggle>
        <NavStyles className={ this.state.showMenu ? 'open' : '' }>
          <Link to="/2020/work">Work</Link>
          <Link to="/2020/grads">Grads</Link>
          {/* <Link to="/#show">Show</Link> */}
          <LinkScroll to='inTroduce' offset={150} spy smooth duration={600} onClick={this.handleScroll} >Show</LinkScroll>
          <SearchContainer tabindex="1">
            <div className={ `search-overlay${ this.state.showSearch ? ' open' : '' }` }>
              <input
                className="search-input"
                placeholder="search"
                type="text"
                autoFocus={this.state.showSearch}
                onKeyDown={this.keyPress}
                onChange={this.handleChange}
              />
            </div>
            <button
              className="search-btn"
              onClick={this.toggleSearch}
            >
              <img src={`/static/${ this.state.showSearch ? 'close' : 'search' }.svg`} alt="search" />
            </button>
          </SearchContainer>
        </NavStyles>
      </>
    );
  }
}

export default withRouter(Nav);
