import React, { useEffect, useMemo, useContext, useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import { disciplines, projectTypeMap, programToImage, programColour } from '../../../constant'
import { A, H2, P, H3 } from '../../../styles/2024/TextStyles'
import { useLazyQuery } from '@apollo/client'
import { QUERY } from '../../../graphql'
import take from 'lodash.take'
import shuffle from 'lodash.shuffle'
import { GradCard } from '../../../components/2024/GradCard'
import { SpringContext } from '../../../context/SpringContext'

const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))

export default function Profile(props) {
  const { search } = useLocation()
  const { id } = qs.parse(search)

  const { data: studentData } = useQuery(SINGLE_USER_QUERY, {
    variables: {
      id,
    },
  })

  const user = useMemo(() => studentData?.student || {}, [studentData])
  const userId = props.id || user?.id

  const [userImage, setUserImage] = useState(programToImage[user?.program])
  const spring = useContext(SpringContext)

  const { data: studentProjectData } = useQuery(STUDENT_PROJECTS_QUERY, {
    variables: {
      id,
    },
  })

  const projects = useMemo(
    () =>
      (studentProjectData?.studentProjects || [])
        .map((prj) => {
          const { orderList } = prj
          const meOrdersList = orderList.find((ord) => ord.userId === userId)
          return { ...prj, order: meOrdersList ? meOrdersList.order : 0 }
        })
        .sort((a, b) => a.order - b.order),
    [studentProjectData, userId],
  )

  const [fetchGrads, { data: gradsOther }] = useLazyQuery(QUERY.GRADS_PAGE_QUERY, {
    variables: {
      where: {
        studentYear: 2024,
        activeAt_gt: '2023-12-31',
      },
      first: 30,
    },
  })

  const handleScrollTop = () => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
      fetchGrads()
    }
  }

  useEffect(() => {
    if (user?.image) {
      const _userImage =
        user?.image !== null && user?.image !== ''
          ? encodeURI(user?.image)
          : programToImage[user?.program]
      setUserImage(_userImage)
    }
  }, [user])

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring, fetchGrads])

  useEffect(() => {
    if (userId) {
      fetchGrads()
    }
  }, [fetchGrads, userId])

  const otherGrads = useMemo(() => {
    if (gradsOther?.users) {
      const grads = gradsOther?.users.map(
        (grad) => grad.id !== undefined && grad.id !== userId && grad,
      )
      if (grads?.length === 1 && grads[0] === false) {
        return []
      }
      return take(shuffle(grads), 3)
    }
    return []
  }, [gradsOther, userId])

  require('./profile.scss')
  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {Countdown && <Countdown />}
      {Header && <Header />}
      <div className='relative w-full bg-almost-black max-w-[1440px]'>
        <div className='relative px-[16px] lg:px-[48px] max-w-[1440px]'>
          <div className='w-full bg-almost-black grid grid-cols-1 gap-4 pb-4 lg:pt-20'>
            <div className='w-full text-beige-yellow relative grid lg:grid-cols-3 lg:gap-16'>
              <img
                src={userImage}
                onError={() => setUserImage(programToImage[user?.program])}
                className='object-cover mx-auto pt-4 my-[48px] lg:col-span-1 lg:mt-0 lg:pt-0'
                alt='user-avatar'
              />
              <div className='lg:col-span-2'>
                {user?.portfolio && (
                  <A
                    href={user?.portfolio}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-beige-yellow visited:text-beige-yellow hover:text-comfort-green'
                    style={{
                      borderBottom: '2px',
                      borderStyle: 'dashed',
                      borderColor: '#FAF4D9',
                      paddingBottom: '8px',
                    }}
                  >
                    <a
                      className='text-beige-yellow visited:text-beige-yellow hover:text-comfort-green cursor-pointer'
                      href={user?.portfolio}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Portfolio{' '}
                    </a>
                  </A>
                )}

                <br />
                <H2 className='inline-block pt-2'>
                  {user?.firstName} {user?.lastName}
                </H2>
                <P className='inline pl-4'>{user?.tagline}</P>
                <div className='flex gap-4'>
                  <P className='text-beige-yellow'>
                    <span className={programColour[user?.program]}>●</span> {user?.program}
                  </P>
                  {disciplines[projectTypeMap[user?.primaryExpertise]] && (
                    <P className=''>● {disciplines[projectTypeMap[user?.primaryExpertise]]}</P>
                  )}
                  {disciplines[projectTypeMap[user?.secondaryExpertise]] &&
                    disciplines[projectTypeMap[user?.primaryExpertise]] !==
                      disciplines[projectTypeMap[user?.secondaryExpertise]] && (
                      <P className=''>● {disciplines[projectTypeMap[user?.secondaryExpertise]]}</P>
                    )}
                </div>
                <P className=''>{user?.about}</P>
                <div className='flex justify-start pb-32'>
                  <div className='flex'>
                    {user.instagram && (
                      <a href={user.instagram} target='_blank' rel='noreferrer'>
                        <img
                          src='/static/2024/svg/gram.svg'
                          alt='instagram logo'
                          className='pt-6 pr-6'
                        />
                      </a>
                    )}
                    {user.dribble && (
                      <a href={user.dribble} target='_blank' rel='noreferrer'>
                        <img
                          src='/static/2024/svg/tiktok.svg'
                          alt='tiktok logo'
                          className='pt-6 pr-6'
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full h-5/6 text-beige-yellow relative py-10'>
              <div className='relative w-full h-5/6 bg-almost-black grid grid-cols-1 gap-4 pb-4'>
                <H2>PROJECTS</H2>
                {projects.map((project) => (
                  <div
                    key={project.id}
                    className='w-full relative grid grid-cols-1 bg-almost-black py-16 lg:grid-cols-2 gap-4 pb-4 lg:gap-20'
                  >
                    <Link to={{ pathname: '/2024/project', search: `id=${project.id}` }}>
                      <img src={project.coverImage} alt={project.title} className='lg:hidden' />
                      <div
                        style={{
                          background: 'url(' + encodeURI(project?.coverImage) + ')',
                          width: '100%',
                          height: '280px',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                        className='hidden lg:block' // Show this div on larger screens
                      ></div>
                    </Link>
                    <div>
                      <Link to={{ pathname: '/2024/project', search: `id=${project.id}` }}>
                        <H3 className='text-beige-yellow visited:text-beige-yellow hover:text-comfort-green'>
                          {project.title}
                        </H3>
                      </Link>
                      <div className='flex gap-4'>
                        <Link
                          to={{
                            pathname: '/2024/work',
                            search: `?program=${user?.program}`,
                          }}
                        >
                          <P className='text-beige-yellow'>
                            <span className={programColour[user?.program]}>●</span> {user?.program}
                          </P>
                        </Link>
                      </div>
                      <div className='flex gap-4'>
                        <Link
                          className='cursor-pointer'
                          to={{
                            pathname: '/2024/work',
                            search: `?type=${project['primaryProjectType']}`,
                          }}
                        >
                          {disciplines[project['primaryProjectType']] && (
                            <P className='text-beige-yellow'>
                              ● {disciplines[project['primaryProjectType']]}
                            </P>
                          )}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='relative w-full h-5/6 bg-almost-black grid grid-cols-1 gap-8'>
            <H2 className='text-beige-yellow'>OTHER GRADUATES</H2>
            <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 pb-20 pt-30'>
              {gradsOther?.users &&
                otherGrads.length > 0 &&
                otherGrads.map((_grad, idx) => (
                  <GradCard key={idx} grad={_grad} scrollTop={handleScrollTop} />
                ))}
            </div>
          </div>
          <div className='relative w-full bg-almost-black grid grid-cols-1 gap-4 pb-4'>
            {Footer && <Footer />}
          </div>
        </div>
      </div>
    </div>
  )
}

const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($id: ID!) {
    student(where: { id: $id }) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjects(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      orderList {
        userId
        order
      }
    }
  }
`
