import UpdateProject from '../../components/CMS/UpdateProject'
import PleaseSignIn from '../../components/CMS/PleaseSignin'
import React from 'react'

const Update = (props) => {
  const id = props.location.search.split('id=')[1]
  return (
      <PleaseSignIn >
        <UpdateProject {...props} />

      </PleaseSignIn>
  )
}

export default Update
