import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import cn from 'classnames'
import upperFirst from 'lodash.upperfirst'
import { useDisclosure } from 'react-use-disclosure'
import { Link } from 'react-router-dom'
import { VscMenu } from 'react-icons/vsc'
import { default as UserProfile, ProfileSmall } from '../../../components/2022/Profile'
import StickerNavs from '../StickerNavs'
import SearchIcon from '../../../components/2022/SearchIcon'
import { projectType } from '../../../constant'
import { MobileNavsBottom, MobileRightNavs } from '../MobileRightNavs'
import FixedBottomNav from '../../../components/2022/FixedBottomNav'
import { Element } from 'react-scroll'
import Footer from '../../../components/2022/Footer'

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`


const WorksContainer = styled.div`
  background: #e3e3de;
  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .curve-container {
    background: #e3e3de;
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 15%;
    vertical-align: middle;
    overflow: hidden;
  }
`
const StudentName = styled.h3`
  font-family: 'reckless_light';
`
const FooterContainer =styled.div`
  height: 130vh;
  @media (min-width: 768px) {
    height: 150vh;
  }
  @media (min-width: 1024px) {
    height: 120vh;
  }
  @media (min-width: 2560px) {
      height: 90vh;
  }
`

export default function Profile(props) {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { id } = qs.parse(search)
  // console.log('🚀 ~ Profile ~ id', id)

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  useEffect(() => {
    let body = document.getElementsByTagName('body')?.[0]
    body.style.height = 'auto'
    window.scroll(0, 0)
  }, [])

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  const { data: studentData } = useQuery(SINGLE_USER_QUERY, {
    variables: {
      id,
    },
  })

  const { data: studentProjectData } = useQuery(STUDENT_PROJECTS_QUERY, {
    variables: {
      id,
    },
  })

  const userId = props.id
  const user = studentData?.student || {}
  // console.log('🚀 ~ Profile ~ user', user)
  const projects = useMemo(
    () =>
      (studentProjectData?.studentProjects || [])
        .map((prj) => {
          const { orderList } = prj
          const meOrdersList = orderList.find((ord) => ord.userId === userId)
          return { ...prj, order: meOrdersList ? meOrdersList.order : 0 }
        })
        .sort((a, b) => a.order - b.order),
    [studentProjectData, userId],
  )

  const { isOpen, toggle } = useDisclosure()
  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()
  require("./profile.scss");
  return (
    <>
      <Element name="myScrollToElement" id="scroll2022top"></Element>
      <div className='flex min-h-screen mt-28 lg:mt-0'  style={{background:'#e3e3de'}}>
        <div className='w-96 hidden lg:block' style={{background:'#e3e3de'}}>
        <Link to='/2022/home'>
          <TextLogo
                className='text-red-coral uppercase text-center px-6 cursor-pointer'
                onClick={() => navigate('/2022/home')}
                >
                Yes!2022
            </TextLogo>
          </Link>
          <div
            className='border-t border-b border-red-coral border-dashed my-8 text-center text-charcoal hover:bg-red-coral hover:text-charcoal transition cursor-pointer'
            onClick={toggle}
          >
            <VscMenu className='text-5xl text-center mx-auto my-4' />
          </div>
          {!isOpen ? (
            <UserProfile user={user} />
          ) : (
            <StickerNavs withTop={false} className={cn('mt-16')} />
          )}
        </div>
        <div className={cn('w-full text-charcoal lg:flex', {
          // 'bg-dark': !isOpenMenuNavs,
          // 'bg-blue': isOpenMenuNavs
        })}>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
          <WorksContainer className={cn('w-full mx-auto mt-8 transition', {
            'flex visible opacity-100': !isOpenMenuNavs,
            'hidden invisible opacity-0': isOpenMenuNavs,
          })}>
            {/* Avatar small screen */}
            <img src={user?.image} className='w-64 h-64 rounded-full object-cover mx-auto pt-4' alt='user-avatar' style={{ filter: 'grayscale(100%)' }} />
            <StudentName className='text-6xl lg:text-7xl text-center my-8 lg:my-16'>
              {user?.firstName} {user?.lastName}
            </StudentName>
            {/* Profile small screen */}
            <div className='px-8 py-4 lg:hidden'>
              <ProfileSmall user={user} />
            </div>
            {projects.map((project) => (
              <div key={project.id} className='px-8 md:px-24 lg:px-96 mt-8 flex flex-col'>
                <Link
                  to={{ pathname: '/2022/project', search: `id=${project.id}` }}

                >
                  <img src={project.coverImage} alt={project.title} />
                </Link>

                <div className='flex flex-wrap py-4 lg:py-0'>
                  {['primaryProjectType', 'secondaryProjectType', 'secondaryProjectType_2'].map(
                    (key) =>
                      project[key] && (
                        <div key={key} className='flex items-center mr-12'>
                          <Link
                            to={{
                              pathname: '/2022/work',
                              search: `?type=${project[key]}`,
                            }}
                          >
                            <span className='w-3 h-3 bg-red-coral rounded-full inline-block'></span>
                            <span className='break-normal pt-2 inline-block ml-2 border-b border-red-coral border-dashed leading-relaxed text-charcoal hover:text-red-coral transition'>
                              {projectType[project[key]] || upperFirst(project[key])}
                            </span>
                          </Link>
                        </div>
                      ),
                  )}
                </div>
                <Link
                  to={{ pathname: '/2022/project', search: `id=${project.id}` }}
                  className='text-5xl inline-block mt-6 mb-20 text-charcoal hover:text-red-coral transition'
                >
                  {project.title}
                </Link>
              </div>
            ))}
            {/* {projects.length > 1 ? (
              <div className='curve-container'>
                <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
                  <path
                    d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                    style={{ keyStroke: 'none', fill: '#333' }}
                  ></path>
                </svg>
              </div>
            ) : (
              <CurvedContainer>
                <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
                  <path
                    d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                    style={{ keyStroke: 'none', fill: '#333' }}
                  ></path>
                </svg>
              </CurvedContainer>
            )} */}
          </WorksContainer>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        </div>
      </div>
        <SearchIcon className='hidden lg:block' />
        < FixedBottomNav className='hidden lg:block' />
        <FooterContainer className="single-item bg-light-grey-2022" data-color="off_white" style={{position:'relative',background:'#191919', overflow:'hidden', flex:'none' ,minWidth: '10vh', minHeight: '10vh'}}>
                    <div style={{position:'absolute', top: '-30px', width:'100vw', height:'100px', background:'#e3e3de', zIndex:'18'}}></div>
                    <Footer/>
        </FooterContainer>
      </>
  )
}

const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($id: ID!) {
    student(where: { id: $id }) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjects(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      orderList {
        userId
        order
      }
    }
  }
`
