import React, { useCallback, useEffect, forwardRef } from 'react'
import UserProfile from '../../../components/2021/Profile'
import cn from 'classnames'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`

export const ProjectScroll = forwardRef(({ className, user }, ref) => {
  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  return (
    <div className={cn(className)} ref={ref}>
      <TextLogo
        className='text-center px-6 text-secondary cursor-pointer uppercase'
        onClick={() => navigate('/2021/')}
      >
        Yes!2021
      </TextLogo>
      <UserProfile user={user} className='mt-4' borderBlack={false}  />
    </div>
  )
})
