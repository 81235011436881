import React, { useContext, useEffect } from 'react'
import { P, H2, H1M, H3, A } from '../../../styles/2024/TextStyles'
import { useWindowSize } from '../../../hook/2024/hooks/useWindowSize'
import { TextCenterDiv } from '../../../styles/2024/GradsStyles'
import { gradComments } from '../../../constant'
import { SpringContext } from '../../../context/SpringContext'
import { Link } from 'react-router-dom'
const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))
const SlickSlider = React.lazy(() => import('../../../components/2024/SlickSlider'))

export default function Index() {
  const { screen } = useWindowSize()
  const spring = useContext(SpringContext)
  const isMobile = screen === 'sm'

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  return (
    <div className='absolute w-full min-h-screen bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {Countdown && <Countdown />}
      {Header && <Header />}

      <div className='relative px-[16px] lg:px-[48px] max-w-[1440px] pb-16'>
        <div className='w-full xl:w-full '>
          <TextCenterDiv>
            <H1M className='text-comfort-green lg:!text-[72px]' style={{ pointerEvents: 'none' }}>
              About
            </H1M>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  YES! We are the
                </H3>
                <H3 className='text-beige-yellow normal-case m-0' style={{ pointerEvents: 'none' }}>
                  School of Design.
                </H3>
              </div>
            ) : (
              <H3 className='text-beige-yellow normal-case' style={{ pointerEvents: 'none' }}>
                YES! We are the School of Design.
              </H3>
            )}
          </TextCenterDiv>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-comfort-green flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-comfort-green flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]'>
          <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
            <div className='relative flex flex-col'>
              <div className='flex flex-col text-lg font-light text-almost-black '>
                <H2 className='uppercase'>ABOUT OUR CAMPUS</H2>
                <P className='mt-4 lg:pr-[20%]'>
                  The School of Design resides within a 103,000-square-foot facility in the Daniels
                  Waterfront – City of the Arts development that houses our School of Design
                  programs and a research hub.
                </P>
                <br />
                <P className='relative mt-4 lg:pr-[20%]'>
                  This state-of-the-art building supports academic programs and industry projects,
                  with features such as a motion capture studio, maker lab, usability and testing
                  lab, as well as a series of design-focused gallery spaces. We are located at 3
                  Lower Jarvis Street.
                </P>
                <Link to='/2024/work'>
                  <A className='text-almost-black justify-center self-start p-1 mt-4 border-b border-dotted border-almost-black text-3xl hover:text-empowering-blue'>
                    Learn More About Programs ↗
                  </A>
                </Link>
              </div>
            </div>
            <div className='relative flex flex-col'>
              <img
                loading='lazy'
                src='/static/2024/img/About1.png'
                alt=''
                className='grow w-full'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='relative w-full h-full bg-warm-orange flex flex-col items-center justify-center'>
        <div className='relative w-full h-full bg-warm-orange flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]'>
          <div className='flex flex-col lg:flex-row-reverse gap-14'>
            <div className='flex flex-col flex-1'>
              <div className='flex flex-col text-lg font-light text-almost-black '>
                <H2 className='uppercase lg:w-[320px]'>About our grads</H2>
                <P className='mt-4 lg:pr-[20%]'>
                  Students across the School of Design train and hone their crafts in Visual,
                  Interactive, Game,  and Strategic Design respectively. YES! celebrates the
                  multidisciplinarity of our graduates, each of whom have benefitted from the wisdom
                  and support of faculty, peers, staff, industry partners, and the much broader
                  design.eas, fostering a strong cohort that values inclusivity and diversity.
                </P>
                <br />
                <P className='relative mt-4 lg:pr-[20%]'>
                  We offer a diverse array of programs, encompassing various disciplines. We’re a
                  multi-disciplinary cohort who breathe, live and learn in the same space. As we
                  prepare for the world outside of this space, we remember the lessons we learned,
                  the people who helped us on our journey and the fond memories we’ll take with us.
                </P>
              </div>
            </div>
            <div className='flex flex-col flex-1'>
              <img
                loading='lazy'
                src='/static/2024/img/gbc_sod_wf_2.jpeg'
                alt=''
                className='grow w-full aspect-[1.49]'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='relative w-full min-h-max bg-friendly-yellow flex flex-col items-center justify-center w-full'>
        <div className='relative w-full bg-friendly-yellow px-[16px] lg:px-[48px] py-20 pb-40 max-w-[1440px] text-center'>
          <H2 className='uppercase text-almost-black pb-[36px]'>HEAR FROM OUR GRADUATES</H2>
          {isMobile ? (
            <div className='grid grid-cols-1 gap-40'>
              {gradComments.map((comment, index) => (
                <div key={index} className='!grid !grid-cols-1 gap-20'>
                  <div className='col-span-1 text-almost-black'>
                    <P className='text-left'>{comment.content}</P>
                    <P className='text-left'>{comment.who}</P>
                  </div>
                  <img src={comment.image} alt='Graduates' className='w-full' />
                </div>
              ))}
            </div>
          ) : (
            <SlickSlider />
          )}
        </div>
      </div>
      <div className='relative w-full bg-almost-black flex flex-col items-center justify-center w-full'>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-almost-black max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
