import React from 'react'
import { Route } from 'react-router-dom'

import {
  Teaser as Teaser2023,
  Homepage as Homepage2023,
  Grads as Grads2023,
  Work as Work2023,
  Project as Project2023,
  Profile as Profile2023,
  Search as Search23,
  Events as Events2023,
} from '../pages/2023'

export const getRoutes2023 = () => [
  <Route key={"Homepage2023"} exact path='/2023' component={Homepage2023} />,
  <Route key={"Teaser2023"} exact path='/2023/teaser' component={Teaser2023} />,
  <Route key={"Home2023"} exact path='/2023/home' component={Homepage2023} />,
  <Route key={"Grads2023"} exact path='/2023/grads' component={Grads2023} />,
  <Route key={"Work2023"} exact path='/2023/work' component={Work2023} />,
  <Route key={"Project2023"} exact path='/2023/project' component={Project2023} />,
  <Route key={"Profile2023"} exact path='/2023/student' component={Profile2023} />,
  <Route key={"Search23"} exact path='/2023/search' component={Search23} />,
  <Route key={"Events2023"} exact path='/2023/events' component={Events2023} />,
]
