import React, { useEffect, useMemo, useRef, useContext } from 'react'
import styled from 'styled-components'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import qs from 'query-string'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import take from 'lodash.take'
import shuffle from 'lodash.shuffle'
import { ProjectCard } from '../../../components/2025/ProjectCard'
import { H2, H3, P, A } from '../../../styles/2024/TextStyles'
import {
  projectType,
  programToImage,
  programColour,
  projectTypeMap,
  disciplines,
} from '../../../constant'
import { SpringContext } from '../../../context/SpringContext'
import { ThemeContext } from '../../../context/2025/ThemeContext'
import { useViewport } from '../../../hook/useViewport'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const FullScreenLoader = React.lazy(() => import('../../../components/FullScreenLoader'))

const Image = styled.img`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export default function PublishedProject(props) {
  const relatedWorkRef = useRef()
  const spring = useContext(SpringContext)
  const { isDarkMode } = useContext(ThemeContext)
  const { width } = useViewport()
  const isMobile = width < 1024

  const { search } = useLocation()
  const { id } = qs.parse(search)

  const { data, loading: projectLoading } = useQuery(PUBLISHED_PROJECT, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.publishedProject || {}, [data])
  const projectID = project?.id

  const [fetchSimilar, { data: projectSimilar, loading: similarLoading }] = useLazyQuery(ALL_PROJECTS_QUERY, {
    variables: {
      where: {
        published: true,
        primaryProjectType: project?.primaryProjectType,
        createdAt_gt: '2024-12-31',
        createdAt_lt: '2026-01-01',
      },
      first: 30,
    },
  })

  const handleScrollTop = () => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
      fetchSimilar()
    }
  }

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  useEffect(() => {
    if (!project?.primaryProjectType) {
      return
    }
    fetchSimilar()
  }, [project, fetchSimilar])

  const similarProjects = useMemo(() => {
    if (projectSimilar?.projects?.length > 0) {
      const projects = projectSimilar?.projects
      const filteredProjects = projects.filter((prj) => prj.id !== projectID)
      return take(shuffle(filteredProjects), 3)
    }
    return []
  }, [projectSimilar, projectID])

  const backgroundColors = {
    header: isDarkMode ? 'bg-black' : 'bg-white',
    content: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    details: isDarkMode ? 'bg-black' : 'bg-white',
    related: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    footer: isDarkMode ? 'bg-black' : 'bg-light-grey-25'
  }

  const textColors = {
    heading: isDarkMode ? 'text-lime' : 'text-lavender',
    subheading: isDarkMode ? 'text-white' : 'text-black',
    body: isDarkMode ? 'text-white' : 'text-black',
    link: isDarkMode ? 'text-lime hover:text-white' : 'text-lavender hover:text-black',
    accent: isDarkMode ? 'text-lime' : 'text-lavender'
  }

  const loading = projectLoading || similarLoading;

  require('./index.scss')

  return (
    <div className={`absolute w-full min-h-screen ${backgroundColors.header} flex flex-col items-center justify-start pb-20 lg:pb-0`}>
      {project.title && (
        <Helmet>
          <title>YES! | {project.title}</title>
        </Helmet>
      )}

      {loading && <FullScreenLoader color={isDarkMode ? '#DAFF01' : '#8A74D0'} />}
      {Header && <Header />}

      <section className={`w-full ${backgroundColors.header} transition-colors duration-300`}>
        <div className='w-full'>
          {project?.coverImage && (
            <div className='relative h-[380px] md:h-[480px] lg:h-[580px] w-full overflow-hidden'>
              <div
                style={{
                  background: `url(${encodeURI(project?.coverImage)})`,
                  width: '100%',
                  height: '100%',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className="transition-transform duration-500 hover:scale-105"
              ></div>
              <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            </div>
          )}
        </div>
      </section>

      <section className={`w-full ${backgroundColors.content} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-16'>
            <div className='relative flex flex-col items-start justify-start'>
              <H2 className={`${textColors.heading} heading-2 text-left mb-8`}>{project?.title}</H2>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 w-full'>
                {project.members?.map((member) => (
                  <div key={member?.user?.id} className='pt-2'>
                    <div className='flex w-full flex-col items-start justify-start'>
                      <div className='flex flex-wrap justify-start items-center gap-4 py-6'>
                        <P className={`${textColors.body} body-text inline-block p-0 m-0`}>
                          <span className={`${textColors.heading}`}>◼</span>{' '}
                          {member?.user?.program}
                        </P>
                        {disciplines[projectTypeMap[member?.user?.primaryExpertise]] && (
                          <P className={`${textColors.body} body-text inline-block p-0 m-0`}>
                            ◼ {disciplines[projectTypeMap[member?.user?.primaryExpertise]]}
                          </P>
                        )}
                        {disciplines[projectTypeMap[member?.user?.secondaryExpertise]] &&
                          disciplines[projectTypeMap[member?.user?.secondaryExpertise]] !==
                            disciplines[projectTypeMap[member?.user?.primaryExpertise]] && (
                            <P className={`${textColors.body} body-text inline-block p-0 m-0`}>
                              ◼ {disciplines[projectTypeMap[member?.user?.secondaryExpertise]]}
                            </P>
                          )}
                      </div>
                      <Link
                        to={{
                          pathname: '/student',
                          search: `id=${member?.user.id}`,
                        }}
                        className='inline-block flex items-center'
                      >
                        <div className="relative overflow-hidden rounded-full w-20 h-20">
                          <img
                            src={member?.user?.image || programToImage[member?.user?.program]}
                            alt={`${member?.user?.firstName} ${member?.user?.lastName}`}
                            className='w-full h-full object-cover transition-transform duration-300 hover:scale-[1.04]'
                            style={{ filter: 'grayscale(100%)' }}
                          />
                        </div>
                        <A className={`border-b border-dotted ${isDarkMode ? 'border-lime' : 'border-lavender'} ${textColors.body} mt-2 ml-4 text-xl hover:${textColors.accent}`}>
                          {member?.user?.firstName} {member?.user?.lastName}
                        </A>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <P className={`${textColors.body} body-text`}>
              {project?.contentData?.length > 0 && project?.contentData[0]}
            </P>
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.details} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-16'>
          <div className={`${textColors.body}`}>
            <div className='mt-8'>
              {project?.contentType?.map((type, i) => {
                if (i === 0) {
                  return null
                } else if (type === 'Heading') {
                  return (
                    <H3 key={i} className={`pb-16 capitalize heading-3 ${textColors.body}`}>
                      {project.contentData[i]}
                    </H3>
                  )
                } else if (type === 'Text') {
                  return (
                    <P className={`w-full pb-8 first-letter:uppercase body-text ${textColors.body}`} key={`contenttype-${i}`}>
                      <ReactMarkDown key={i} children={project.contentData[i]} />
                    </P>
                  )
                } else if (type === 'Image') {
                  return (
                    <div className='w-full mb-16' key={i}>
                      <Image src={project.contentData[i]} alt='Project image' className='pb-4 transition-transform duration-300 hover:scale-[1.01]' />
                    </div>
                  )
                } else if (type === 'Video') {
                  return (
                    <div key={i} className='w-full lg:w-4/6 mx-auto pb-8 lg:pb-16 lg:px-0'>
                      <video controls className='w-full mx-auto rounded-lg overflow-hidden shadow-lg'>
                        <source type='video/mp4' src={project.contentData[i]} />
                      </video>
                    </div>
                  )
                }
                return null
              })}
            </div>
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.related} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-16'>
          <H2 className={`pt-8 pb-16 heading-2 ${textColors.heading}`} ref={relatedWorkRef}>
            RELATED WORK
          </H2>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16'>
            {similarProjects?.map((_project) => (
              <ProjectCard
                key={_project.id}
                project={_project}
                scrollTop={handleScrollTop}
                isDarkMode={isDarkMode}
              />
            ))}
          </div>
        </div>
      </section>

      <div className={`relative w-full ${backgroundColors.footer} flex flex-col items-center justify-center w-full`}>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}

const PUBLISHED_PROJECT = gql`
  query PUBLISHED_PROJECT($id: ID!) {
    publishedProject(id: $id) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const ALL_PROJECTS_QUERY = gql`
  query ALL_PROJECTS_QUERY($where: ProjectWhereInput, $skip: Int = 0, $first: Int = 24) {
    projects(where: $where, first: $first, skip: $skip, orderBy: createdAt_DESC) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`