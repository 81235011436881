import React, { useCallback, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Marquee from 'react-fast-marquee'
import { MobileRightNavs, MobileNavsBottom } from '../MobileRightNavs'
import VideoModal from '../Teaser/VideoPlayerModal'
import VideoModalLive from '../Teaser/VideoPlayerModalLive'
import {
  Container,
  SwirlBG,
  ContainerSectionOne,
  TextLogo,
  TextCenterYes,
  StickerContainer,
  StickersWrapper,
  Sticker3Days,
  Sticker1Container,
  Sticker2,
  TextCenterTop,
  EmailSignUpCenter,
  ContainerSectionTwo,
  MarqueeSubjectBox,
  SectionTwoText1,
  SectionTwoText2,
  ContainerSectionThree,
  Sticker3,
  Sticker4,
  SectionThreeHeader1,
  SubjectList,
  SectionThreeHeader2,
  EventsTimeline,
  ViewEventDetails,
  Sticker5,
  Sticker6,
  Sticker10,
  ContainerSectionFour,
  ShowBox,
  ContainerSectionFive,
  Sticker11,
  Sticker12,
  Sticker13,
  Sticker14,
  Sticker15,
  Sticker16,
  Sticker17,
  Sticker18,
  BGImages,
} from '../../../styles/2023/HomepageStyles'
import SearchIcon from '../../../components/2023/SearchIcon'
import cn from 'classnames'
import Footer from '../Footer'
import FixedBottomNav from '../FixedBottomNav'
import { Element } from 'react-scroll'
import { useDisclosure } from 'react-use-disclosure'

const MarqueeComp = (props) => {
  return (
    <Marquee
      play={props.isPaused}
      pauseOnHover={true}
      speed={100}
      gradient={false}
      direction={props.direction}
      className='mb-10'
    >
      <MarqueeSubjectBox
        className='marqueeContent'
        boxColor={props.bgColor1}
        fontColor={props.fontColor1}
      >
        <div className='mSquare'></div>
        <div className='mSubject'>
          <a href={`work?program=${props.subject1}`}>{props.subject1}</a>
        </div>
      </MarqueeSubjectBox>
      <MarqueeSubjectBox
        className='marqueeContent'
        boxColor={props.bgColor2}
        fontColor={props.fontColor2}
      >
        <div className='mSquare'></div>
        <div className='mSubject'>
          <a href={`work?program=${props.subject2}`}>{props.subject2}</a>
        </div>
      </MarqueeSubjectBox>
    </Marquee>
  )
}

export default function Homepage() {
  const history = useHistory()
  const { pathname } = useLocation()
  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()
  const [isAnimPaused, setIsAnimPaused] = useState(true)
  const [errorForm, setErrorForm] = useState(false)
  const [submitForm, setSubmitForm] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [videoMute, setVideoMute] = useState(true)
  const [videoLiveOpen, setVideoLiveOpen] = useState(false)
  const [videoLiveMute, setVideoLiveMute] = useState(true)
  const [email, setEmail] = useState('')

  const navigate = useCallback(
    (url) => {
      if (url !== '/' && pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1450)
      }
    },
    [history, pathname],
  )

  const toggleMarqueeAnimation = () => {
    setIsAnimPaused(!isAnimPaused)
  }

  const toggleOpenVideo = useCallback(() => {
    !videoOpen && setVideoMute(true)
    setVideoOpen((v) => !v)
  }, [videoOpen])
  const toggleMuteVideo = useCallback(() => setVideoMute((v) => !v), [])

  const toggleOpenVideoLive = useCallback(() => {
    !videoLiveOpen && setVideoLiveMute(true)
    setVideoLiveOpen((v) => !v)
  }, [videoLiveOpen])
  const toggleMuteVideoLive = useCallback(() => setVideoLiveMute((v) => !v), [])

  const onHandleForm = useCallback(
    (e) => {
      e.preventDefault()
      const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
      if (!email || !email.match(emailFormat)) {
        setErrorForm(true)
        console.log('form failed')
        return
      }
      const baseUrl = process.env.REACT_APP_API_ENDPOINT_BASE || 'https://api.georgebrown.ca'
      fetch(`${baseUrl}/join-waiting-list`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      })
        .then((response) => response.json())
        .then(setSubmitForm(true))
        .then((data) => {
          //console.log(data)
        })
    },
    [email],
  )

  const onInputChange = useCallback((e) => {
    const email = e.target.value
    setEmail(email)
  }, [])

  useEffect(() => {
    const script2 = document.createElement('script')
    script2.src = '/static/movingsvg.js'
    script2.async = true
    script2.type = 'text/javascript'
    document.body.appendChild(script2)
  }, [])

  const currentDate = new Date()

  const date2 = new Date('2023-05-04T00:00:01')
  const date3 = new Date('2023-05-05T00:00:01')

  const areDatesEqual = (dateA, dateB) =>
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()

  let displayedImage = '/static/svg/2023/Homepage/timeline1.svg'
  if (currentDate < date2) {
    displayedImage = '/static/svg/2023/Homepage/timeline1.svg'
  } else if (areDatesEqual(currentDate, date2)) {
    displayedImage = '/static/svg/2023/Homepage/timeline2.svg'
  } else if (currentDate >= date3) {
    displayedImage = '/static/svg/2023/Homepage/timeline3.svg'
  }

  return (
    <Container>
      <VideoModal
        videoOpen={videoOpen}
        setVideoClose={toggleOpenVideo}
        videoMute={videoMute}
        setVideoMute={toggleMuteVideo}
      />
      <VideoModalLive
        videoOpen={videoLiveOpen}
        setVideoClose={toggleOpenVideoLive}
        videoMute={videoLiveMute}
        setVideoMute={toggleMuteVideoLive}
      />

      <Element name='myScrollToElement' id='scroll2023top'></Element>
      <SwirlBG className='w-full'>
        <img
          src={`/static/svg/2023/Homepage/SwirlsFullAlpha.png`}
          className='w-full bgDesktop'
          alt=''
        />

        <img
          src={`/static/svg/2023/Homepage/SwirlsFullPicPNGMobile.png`}
          className='w-full bgMobile'
          alt=''
        />
      </SwirlBG>
      <BGImages>
        <img
          src={`/static/svg/2023/Homepage/BGImage1.png`}
          className='bgImageAll bgImage1'
          alt=''
        />
        <img
          src={`/static/svg/2023/Homepage/BGImage2.png`}
          className='bgImageAll bgImage2'
          alt=''
        />
        <img
          src={`/static/svg/2023/Homepage/BGImage3.png`}
          className='bgImageAll bgImage3'
          alt=''
        />
      </BGImages>
      {!videoOpen && <SearchIcon className='hidden lg:block' />}
      <ContainerSectionOne>
        <TextLogo className='text-mad-blue uppercase'>Yes!2023</TextLogo>

        <TextCenterYes className='text-mad-blue'>Yes!</TextCenterYes>
        <StickersWrapper
          className={cn(
            'absolute w-96 top-10 h-screen transition-opacity duration-700 ease-in-out hidden lg:block',
          )}
          top='210px'
          imgLeft='0%'
        >
          <StickerContainer
            pointer
            className='relative w-max'
            textRotate={-35}
            onClick={() => navigate('/2023/work')}
          >
            <svg
              className='no-hover'
              width={88}
              height={88}
              viewBox='0 0 110 110'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                fill={'#26439B'}
                cx={55}
                cy={55}
                r={55}
                fillRule='evenodd'
                style={{ display: 'unset' }}
              />
            </svg>
            <p
              className={cn('text-5xl no-hover')}
              style={{
                top: 'calc(25% + 4px)',
                left: 10,
                color: '#C7C2E1',
              }}
            >
              Work
            </p>
            <svg
              className='yes-hover'
              width={88}
              height={88}
              viewBox='0 0 110 110'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle
                fill={'#C7C2E1'}
                cx={55}
                cy={55}
                r={55}
                fillRule='evenodd'
                style={{ display: 'unset' }}
              />
            </svg>
            <p
              className={cn('text-5xl yes-hover')}
              style={{
                top: 'calc(25% + 4px)',
                left: 10,
                color: '#26439B',
              }}
            >
              Work
            </p>
          </StickerContainer>
          <StickerContainer
            pointer
            imgWidth={124}
            className='relative -mt-4 w-max'
            textRotate={-15}
            onClick={() => navigate('/2023/grads')}
          >
            <svg
              className='no-hover'
              width={128}
              height={87}
              viewBox='0 0 164 87'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                fill={'#26439B'}
                fillRule='evenodd'
              />
            </svg>
            <p
              className={cn('text-5xl no-hover')}
              style={{
                top: 'calc(35% + 0px)',
                left: 42,
                color: '#C7C2E1',
              }}
            >
              Grads
            </p>
            <svg
              className='yes-hover'
              width={128}
              height={87}
              viewBox='0 0 164 87'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M.203 71.712S60.29 13.564 113.301 1.427c51.212-11.726 71.525 64.233 21.237 79.456-26.56 8.04-68.41 8.844-134.335-9.171'
                fill={'#C7C2E1'}
                fillRule='evenodd'
              />
            </svg>
            <p
              className={cn('text-5xl yes-hover')}
              style={{
                top: 'calc(35% + 0px)',
                left: 42,
                color: '#26439B',
              }}
            >
              Grads
            </p>
          </StickerContainer>
          <StickerContainer
            pointer
            imgWidth={124}
            className='relative w-max'
            textRotate={10}
            onClick={() => navigate('/2023/events')}
          >
            <img
              className='no-hover'
              src='/static/svg/2023/Homepage/nav-event.svg'
              alt='events-nav'
            />
            <p
              className='text-5xl text-blue no-hover'
              style={{
                top: 'calc(45% - 12px)',
                left: 24,
                color: '#C7C2E1',
              }}
            >
              Events
            </p>
            <img
              className='yes-hover'
              src='/static/svg/2023/Grads/nav-event.svg'
              alt='events-nav'
            />
            <p
              className='text-5xl text-blue yes-hover'
              style={{
                top: 'calc(45% - 12px)',
                left: 24,
                color: '#26439B',
              }}
            >
              Events
            </p>
          </StickerContainer>
          <StickerContainer
            pointer
            imgWidth={94}
            className='relative w-max'
            textRotate={10}
            onClick={() => window.open('https://instoregbc.com/', '_blank')}
          >
            <img className='no-hover' src='/static/svg/2023/Homepage/nav-show.svg' alt='show-nav' />
            <p
              className='text-5xl text-blue no-hover'
              style={{
                top: 'calc(56% - 16px)',
                left: 13,
                color: '#C7C2E1',
              }}
            >
              Shop<span style={{ fontSize: '20px' }}>&#8599;</span>
            </p>
            <img className='yes-hover' src='/static/svg/2023/Grads/nav-show.svg' alt='show-nav' />
            <p
              className='text-5xl text-blue yes-hover'
              style={{
                top: 'calc(56% - 16px)',
                left: 13,
                color: '#26439B',
              }}
            >
              Shop<span style={{ fontSize: '20px' }}>&#8599;</span>
            </p>
          </StickerContainer>
        </StickersWrapper>
        <Sticker3Days
          src='/static/svg/2023/Homepage/sticker-3-days.svg'
          alt='3-days'
          className={cn('mx-auto cursor-pointer w-32 md:w-64 max-w-sm')}
        />
        <Sticker1Container
          textRotate={-18}
          top={'1%'}
          left={'80%'}
          imgWidth={'257px'}
          imgHeight={'205px'}
          style={{ cursor: 'pointer', filter: 'drop-shadow(1px 1px 1px #26439B' }}
          onClick={toggleOpenVideoLive}
        >
          <img src='/static/svg/2023/Homepage/Sticker1.svg' alt='Watch Live' />
          <p className={cn('text-5xl')}>Watch Live</p>
        </Sticker1Container>
        <Sticker2 src='/static/svg/2023/Homepage/Sticker2.svg' alt='May 3-5' />
        <TextCenterTop className='w-full'>
          <h2 className='uppercase text-center'>George Brown College</h2>
          <h2 className='uppercase text-center'>School of design</h2>
          <h3 className='text-center'>
            Year End Show
            <a
              className='text-mad-blue'
              href='https://www.instagram.com/sod_yes/'
              // target='_blank'
              rel='noreferrer'
            >
              {' '}
              @sod_yes
            </a>
          </h3>
        </TextCenterTop>
        <EmailSignUpCenter className='w-full' hasError={errorForm} submitted={submitForm}>
          <form onSubmit={onHandleForm}>
            <label className='text-black'>Sign up to our mailing list to receive updates</label>
            <input
              id='email'
              type='text'
              name='name'
              placeholder='Enter Your Email'
              value={email}
              onChange={onInputChange}
            />
            <input id='submit' type='submit' value='Sign Up!' />
            <div className='inline-error' id='inline-error'>
              Please enter a valid email address
            </div>
            <div id='submitted'>
              <span className='text-mad-blue'>Success!</span>You’re in!
            </div>
          </form>
        </EmailSignUpCenter>
      </ContainerSectionOne>
      <ContainerSectionTwo>
        <MarqueeComp
          isPaused={isAnimPaused}
          subject1={'Graphic Design'}
          subject2={'Interaction Design'}
          bgColor1={'#26439B'}
          bgColor2={'#E3E3DE'}
          fontColor1={'#FFFFFF'}
          fontColor2={'#26439B'}
        />
        <MarqueeComp
          isPaused={isAnimPaused}
          subject1={'Game - Art'}
          subject2={'Art & Design Foundation'}
          direction={'right'}
          bgColor1={'#F0EEE9'}
          bgColor2={'#26439B'}
          fontColor1={'#26439B'}
          fontColor2={'#FFFFFF'}
        />
        <MarqueeComp
          isPaused={isAnimPaused}
          subject1={'Interactive Media Management'}
          subject2={'Game Design'}
          bgColor1={'#E3E3DE'}
          bgColor2={'#F0EEE9'}
          fontColor1={'#26439B'}
          fontColor2={'#26439B'}
        />
        <MarqueeComp
          isPaused={isAnimPaused}
          subject1={'Concept Art for Entertainment'}
          subject2={'Design Management'}
          direction={'right'}
          bgColor1={'#E3E3DE'}
          bgColor2={'#26439B'}
          fontColor1={'#26439B'}
          fontColor2={'#FFFFFF'}
        />
        <MarqueeComp
          isPaused={isAnimPaused}
          subject1={'Front-End Design'}
          subject2={'Digital Experience Design'}
          bgColor1={'#F0EEE9'}
          bgColor2={'#26439B'}
          fontColor1={'#26439B'}
          fontColor2={'#FFFFFF'}
        />
        <MarqueeComp
          isPaused={isAnimPaused}
          subject1={'Brand Design'}
          subject2={'Graphic Design'}
          direction={'right'}
          bgColor1={'#E3E3DE'}
          bgColor2={'#F0EEE9'}
          fontColor1={'#26439B'}
          fontColor2={'#26439B'}
        />

        <SectionTwoText1>
          <p>
            YES! is the official grad show for George Brown College’s School of Design. We’re
            thrilled to return to an in-person exhibition!
          </p>
        </SectionTwoText1>
        <SectionTwoText2>
          <p>
            This spring, more than 450 students will celebrate the culmination of their studies at
            the School of Design. Each student has benefitted from the wisdom and support of
            faculty, peers, staff, industry partners, and the much broader design community. Join us
            May 3–5 as we showcase the talent of our twelve diverse programs through an exciting
            in-person program of activity. Yes! We’re designers. Yes! We’re future-oriented. Yes! We
            can’t wait to show you what we’ve been up to.
          </p>
        </SectionTwoText2>
      </ContainerSectionTwo>
      <ContainerSectionThree>
        <Sticker3
          src='/static/svg/2023/Homepage/Sticker3.png'
          alt='Yes'
          id='moving1'
          className={cn('moving1 absolute')}
        />
        <Sticker4
          src='/static/svg/2023/Homepage/Sticker4.png'
          alt='Yes'
          id='moving2'
          className={cn('moving2 absolute')}
        />
        <Sticker5
          src='/static/svg/2023/Homepage/Sticker5.png'
          alt='Yes'
          id='moving3'
          className={cn('moving3 absolute')}
        />
        <Sticker6
          src='/static/svg/2023/Homepage/Sticker6.png'
          alt='Yes'
          id='moving4'
          className={cn('moving4 absolute')}
        />
        <Sticker10
          src='/static/svg/2023/Homepage/Sticker10.png'
          alt='Yes'
          id='moving5'
          className={cn('moving5 absolute')}
        />
        <SectionThreeHeader1>Grads</SectionThreeHeader1>
        <SubjectList>
          <div>
            <ul>
              <li>
                <a href='/2023/grads?program=Graphic Design'>Graphic Design</a>
              </li>
              <li>
                <a href='/2023/grads?program=Brand Design'>Brand Design</a>
              </li>
              <li>
                <a href='/2023/grads?program=Interaction Design'>Interaction Design</a>
              </li>
              <li>
                <a href='/2023/grads?program=Game - Art<'>Game - Art</a>
              </li>
              <li>
                <a href='/2023/grads?program=Art & Design Foundation<'>Art & Design Foundation</a>
              </li>
              <li>
                <a href='/2023/grads?program=Interactive Media Management'>
                  Interactive Media Management
                </a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li>
                <a href='/2023/grads?program=Concept Art for Entertainment'>
                  Concept Art for Entertainment
                </a>
              </li>
              <li>
                <a href='/2023/grads?program=esign Management'>Design Management</a>
              </li>
              <li>
                <a href='/2023/grads?program=Interdisciplinary Design Strategy'>
                  Interdisciplinary Design Strategy
                </a>
              </li>
              <li>
                <a href='/2023/grads?program=Digital Experience Design'>Digital Experience Design</a>
              </li>
              <li>
                <a href='/2023/grads?program=Digital Design - Game Design'>
                  Digital Design - Game Design
                </a>
              </li>
              <li>
                <a href='/2023/grads?program=Web Development - Front-End Design'>
                  Web Development - Front-End Design
                </a>
              </li>
            </ul>
          </div>
        </SubjectList>
        <SectionThreeHeader2>Events</SectionThreeHeader2>
        <EventsTimeline>
          <div className='timelineGraphic'>
            <img src={displayedImage} alt='timeline' />
          </div>
          <div className='timelineEventDays'>
            <div className='timelineEventDaySingle padding1'>
              <div className='timelineInfoTop'>
                <div className='timelineDayHeading'>
                  <h3>Day 1</h3>
                </div>
                <div className='timelineDateTime'>
                  <p className='timelineDate'>Wednesday May 3, 2023</p>
                  {/*<p className="timelineTime">4pm EST • <a>Virtual Link</a></p>*/}
                  <p className='timelineDateSmall'>May 3, 2023</p>
                </div>
              </div>
              <div className='timelineEventNames'>
                <ul>
                  <li>
                    <a
                      href='https://www.eventbrite.ca/e/experimental-typography-workshop-with-dominic-ayre-tickets-608638984587'
                      target='_blank' rel='noopener noreferrer'>
                      Workshop: Experimental Typography with Dominic Ayre
                    </a>
                    <span>2pm-3:30pm</span>
                  </li>
                  <li>
                    <a
                      href='https://www.eventbrite.ca/e/design-at-the-intersection-of-mental-health-well-being-panel-discussion-tickets-608551442747'
                      target='_blank' rel='noopener noreferrer'>
                      Panel: Design at the Intersection of Mental Health & Well-Being
                    </a>
                    <span>4pm-5:30pm</span>
                  </li>
                  <li>
                    <p>Public Exhibition Opening</p> <span>6pm-9pm</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='timelineEventDaySingle padding2'>
              <div className='timelineInfoTop'>
                <div className='timelineDayHeading'>
                  <h3>Day 2</h3>
                </div>
                <div className='timelineDateTime'>
                  <p className='timelineDate'>Thursday May 4, 2023</p>
                  {/*<p className="timelineTime">4pm EST • <a>Virtual Link</a></p>*/}
                  <p className='timelineDateSmall'>May 4, 2023</p>
                </div>
              </div>
              <div className='timelineEventNames'>
                <ul>
                  <li>
                    <a
                      href='https://www.eventbrite.ca/e/collage-workshop-with-nicola-hamilton-mitchell-keys-tickets-608575655167'
                      target='_blank' rel='noopener noreferrer'
                    >
                      Workshop: Collaging with Nicola Hamilton & Mitchell Keys
                    </a>
                    <span>2pm-4pm</span>
                  </li>
                  <li>
                    <p>Exclusive: Industry Reception</p> <span>5pm-6pm</span>
                  </li>
                  <li>
                    <p>Public Exhibition</p> <span>6pm-9pm</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className='timelineEventDaySingle padding3'>
              <div className='timelineInfoTop'>
                <div className='timelineDayHeading'>
                  <h3>Day 3</h3>
                </div>
                <div className='timelineDateTime'>
                  <p className='timelineDate'>Friday May 5, 2023</p>
                  {/*<p className="timelineTime">4pm EST • <a>Virtual Link</a></p>*/}
                  <p className='timelineDateSmall'>May 5, 2023</p>
                </div>
              </div>
              <div className='timelineEventNames'>
                <ul>
                  <li>
                    <a
                      href='https://www.eventbrite.ca/e/design-at-the-intersection-of-mental-health-well-being-panel-discussion-tickets-608551442747'
                      target='_blank' rel='noopener noreferrer'
                    >
                      Panel: Where It All Started: School of Design Alumni
                    </a>
                    <span>1pm-2:30pm</span>
                  </li>
                  <li>
                    <p>award: Annual Ceremony</p> <span>4pm-6pm</span>
                  </li>
                  <li>
                    <p>Public Exhibition Closing</p> <span>6pm-9pm</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </EventsTimeline>
        <ViewEventDetails onClick={() => navigate('/2023/events')}>
          <p>View Event Details</p>
        </ViewEventDetails>
      </ContainerSectionThree>
      <ContainerSectionFour>
        <Sticker11
          src='/static/svg/2023/Homepage/Sticker11.png'
          alt='Yes'
          id='moving6'
          className={cn('moving6 absolute')}
        />
        <Sticker12
          src='/static/svg/2023/Homepage/Sticker12.png'
          alt='Yes'
          id='moving7'
          className={cn('moving7 absolute')}
        />
        <Sticker13
          src='/static/svg/2023/Homepage/Sticker13.png'
          alt='Yes'
          id='moving8'
          className={cn('moving8 absolute')}
        />
        <Sticker14
          src='/static/svg/2023/Homepage/Sticker14.png'
          alt='Yes'
          id='moving9'
          className={cn('moving9 absolute')}
        />
        <Sticker15
          src='/static/svg/2023/Homepage/Sticker15.png'
          alt='Yes'
          id='moving10'
          className={cn('moving10 absolute')}
        />
        <Sticker16
          src='/static/svg/2023/Homepage/Sticker16.png'
          alt='Yes'
          id='moving11'
          className={cn('moving11 absolute')}
        />
        <Sticker17
          src='/static/svg/2023/Homepage/Sticker17.png'
          alt='Yes'
          id='moving12'
          className={cn('moving12 absolute')}
        />
        <Sticker18
          src='/static/svg/2023/Homepage/Sticker18.png'
          alt='Yes'
          id='moving13'
          className={cn('moving13 absolute')}
        />
        <ShowBox>
          <h2>Show</h2>
          <div className={'buttonContainer'}>
            <button onClick={toggleOpenVideo}>⏵</button>
          </div>

          <p>LIVE: MAY 3-5, 2023 @ 07PM EST</p>
        </ShowBox>
      </ContainerSectionFour>
      <ContainerSectionFive>
        <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} />
        <div
          className={cn('transition', {
            'flex visible opacity-100': !isOpenMenuNavs,
            'hidden invisible opacity-0': isOpenMenuNavs,
          })}
        ></div>
        <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
        <Footer />
        <FixedBottomNav
          toggleMarqueeAnimation={toggleMarqueeAnimation}
          className='hidden lg:block'
        />
      </ContainerSectionFive>
    </Container>
  )
}