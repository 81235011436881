import React from 'react'
import { Route } from 'react-router-dom'

import {
  Teaser as Teaser24,
  Home as Home24,
  Grads as Grads24,
  Work as Work24,
  Profile24,
  Project24,
  Search as Search24,
  About24,
  Events24,
  Letters,
} from '../pages/2024'

export const getRoutes2024 = (getScrollTop) => [
  <Route
    key='home24'
    exact
    path={['/2024', '/2024/home']}
    render={(props) => <Home24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='teaser'
    exact
    path='/2024/teaser'
    render={(props) => <Teaser24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='grads'
    exact
    path='/2024/grads'
    render={(props) => <Grads24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='work'
    exact
    path='/2024/work'
    render={(props) => <Work24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='student'
    exact
    path='/2024/student'
    render={(props) => <Profile24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='project'
    exact
    path='/2024/project'
    render={(props) => <Project24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='search'
    exact
    path='/2024/search'
    render={(props) => <Search24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='about'
    exact
    path='/2024/about'
    render={(props) => <About24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='events'
    exact
    path='/2024/events'
    render={(props) => <Events24 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='letters'
    exact
    path='/2024/letters'
    render={(props) => <Letters {...props} getScrollTop={getScrollTop} />}
  />,
]
