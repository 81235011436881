import styled from 'styled-components'

export const TextCenterDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding: 120px 0 60px 0;
`
