import React, { useEffect, useMemo, useRef, useContext } from 'react'
import styled from 'styled-components'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import qs from 'query-string'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import take from 'lodash.take'
import shuffle from 'lodash.shuffle'
import { ProjectCard } from '../../../components/2024/ProjectCard'
import { H2, H3, P, A } from '../../../styles/2024/TextStyles'
import {
  projectType,
  programToImage,
  programColour,
  projectTypeMap,
  disciplines,
} from '../../../constant'
import { SpringContext } from '../../../context/SpringContext'
const Header = React.lazy(() => import('../../../components/2024/Header'))
const Countdown = React.lazy(() => import('../../../components/2024/Countdown'))
const Footer = React.lazy(() => import('../../../components/2024/Footer'))

const Image = styled.img`
  width: 100%;
`

export default function PublishedProject(props) {
  const relatedWorkRef = useRef()
  const spring = useContext(SpringContext)
  const { search } = useLocation()
  const { id } = qs.parse(search)

  const { data } = useQuery(PUBLISHED_PROJECT, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.publishedProject || {}, [data])
  const projectID = project?.id

  const [fetchSimilar, { data: projectSimilar }] = useLazyQuery(ALL_PROJECTS_QUERY, {
    variables: {
      where: {
        published: true,
        primaryProjectType: project?.primaryProjectType,
        createdAt_gt: '2023-12-31',
        createdAt_lt: '2025-01-01',
      },
      first: 30,
    },
  })

  const handleScrollTop = () => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
      fetchSimilar()
    }
  }

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  useEffect(() => {
    if (!project?.primaryProjectType) {
      return
    }
    fetchSimilar()
  }, [project, fetchSimilar])

  const similarProjects = useMemo(() => {
    if (projectSimilar?.projects?.length > 0) {
      const projects = projectSimilar?.projects
      const filteredProjects = projects.filter((prj) => prj.id !== projectID)
      return take(shuffle(filteredProjects), 3)
    }
    return []
  }, [projectSimilar])

  return (
    <div className='absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0'>
      {project.title && (
        <Helmet>
          <title>YES! | {project.title}</title>
        </Helmet>
      )}

      {Countdown && <Countdown />}
      {Header && <Header />}

      <div className='relative w-full'>
        {project?.coverImage && (
          <div
            style={{
              background: 'url(' + encodeURI(project?.coverImage) + ')',
              width: '100%',
              height: '380px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          ></div>
        )}{' '}
      </div>
      <div className='relative px-[16px] lg:px-[48px] pt-32 w-screen !max-w-[1440px]'>
        <div className='relative bg-almost-black text-beige-yellow grid grid-cols-1 gap-16 pb-32 lg:grid-cols-2'>
          <div className='relative flex flex-col items-start justify-start'>
            <H2 className='text-beige-yellow text-left'>{project?.title}</H2>
            <div className='lg:mx-auto bg-almost-black text-beige-yellow grid grid-cols-1 lg:gap-16 pb-32 lg:grid-cols-2'>
              {project.members?.map((member) => (
                <div key={member?.user?.id} className='pt-2'>
                  <div className='flex w-full  flex-col items-start justify-start'>
                    <div className='flex flex-wrap justify-start items-center gap-4 py-6'>
                      <P className='text-beige-yellow inline-block p-0 m-0'>
                        <span className={programColour[member?.user?.program]}>●</span>{' '}
                        {member?.user?.program}
                      </P>
                      {disciplines[projectTypeMap[member?.user?.primaryExpertise]] && (
                        <P className='text-beige-yellow inline-block p-0 m-0'>
                          ● {disciplines[projectTypeMap[member?.user?.primaryExpertise]]}
                        </P>
                      )}
                      {disciplines[projectTypeMap[member?.user?.secondaryExpertise]] &&
                        disciplines[projectTypeMap[member?.user?.secondaryExpertise]] !==
                          disciplines[projectTypeMap[member?.user?.primaryExpertise]] && (
                          <P className='text-beige-yellow inline-block p-0 m-0'>
                            ● {disciplines[projectTypeMap[member?.user?.secondaryExpertise]]}
                          </P>
                        )}
                    </div>
                    <Link
                      to={{
                        pathname: '/student',
                        search: `id=${member?.user.id}`,
                      }}
                      className='inline-block flex items-center'
                      style={{ color: '#191919' }}
                    >
                      <img
                        src={member?.user?.image || programToImage[member?.user?.program]}
                        alt='member'
                        className='w-20 h-20 rounded-full mx-auto object-cover'
                        style={{ filter: 'grayscale(100%)' }}
                      />
                      <A className='border-b border-dotted border-beige-yellow text-beige-yellow mt-2 ml-4 text-4xl hover:text-comfort-green'>
                        {member?.user?.firstName} {member?.user?.lastName}
                      </A>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <P>{project?.contentData?.length > 0 && project?.contentData[0]}</P>
        </div>
        <div className='text-beige-yellow'>
          <div className='mt-16'>
            {project?.contentType?.map((type, i) => {
              if (i === 0) {
                return null
              } else if (type === 'Heading') {
                return (
                  <H3 key={i} className='pb-16 capitalize'>
                    {project.contentData[i]}
                  </H3>
                )
              } else if (type === 'Text') {
                return (
                  <P className='w-full pb-8 first-letter:uppercase' key={`contenttype-${i}`}>
                    <ReactMarkDown key={i} children={project.contentData[i]} />
                  </P>
                )
              } else if (type === 'Image') {
                return (
                  <div className='w-full' key={i}>
                    <Image src={project.contentData[i]} alt='Image Preview' className='pb-16' />
                  </div>
                )
              } else if (type === 'Video') {
                return (
                  <div key={i} className='w-full lg:w-4/6 mx-auto pb-4 lg:pb-12 lg:px-0'>
                    <video controls className='w-full mx-auto'>
                      <source type='video/mp4' src={project.contentData[i]} />
                    </video>
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>

        <div className='text-beige-yellow pb-32'>
          <H2 className='py-12' ref={relatedWorkRef}>
            RELATED WORK
          </H2>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-16'>
            {similarProjects?.map((_project, idx) => {
              return (
                <div key={_project.id} className='w-full '>
                  <ProjectCard project={_project} key={_project.id} scrollTop={handleScrollTop} />
                </div>
              )
            })}
          </div>
        </div>
        <div className='relative w-full bg-almost-black grid grid-cols-1 gap-4 pb-4'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}

const PUBLISHED_PROJECT = gql`
  query PUBLISHED_PROJECT($id: ID!) {
    publishedProject(id: $id) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const ALL_PROJECTS_QUERY = gql`
  query ALL_PROJECTS_QUERY($where: ProjectWhereInput, $skip: Int = 0, $first: Int = 24) {
    projects(where: $where, first: $first, skip: $skip, orderBy: createdAt_DESC) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`
