import React, { useContext, useEffect } from 'react'
import { useViewport } from '../../../hook/useViewport'
import { TextCenterDiv } from '../../../styles/2025/GradsStyles'
import { gradComments } from '../../../constant'
import { SpringContext } from '../../../context/SpringContext'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../../context/2025/ThemeContext'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const SlickSlider = React.lazy(() => import('../../../components/2025/SlickSlider'))

export default function Index() {
  const { width } = useViewport()
  const spring = useContext(SpringContext)
  const isMobile = width < 1024
  const { isDarkMode } = useContext(ThemeContext)

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const backgroundColors = {
    header: isDarkMode ? 'bg-black' : 'bg-white',
    campus: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    grads: isDarkMode ? 'bg-black' : 'bg-white',
    testimonials: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    footer: isDarkMode ? 'bg-black' : 'bg-light-grey-25',
  }

  const textColors = {
    heading: isDarkMode ? 'text-lime' : 'text-lavender',
    subheading: isDarkMode ? 'text-white' : 'text-black',
    dark: isDarkMode ? 'text-white' : 'text-black',
    light: isDarkMode ? 'text-white' : 'text-black',
    accent: isDarkMode ? 'text-lime' : 'text-lavender',
  }

  return (
    <div
      className={`absolute w-full min-h-screen ${backgroundColors.header} flex flex-col items-center justify-start pb-20 lg:pb-0`}
    >
      {Header && <Header />}

      <section className={`w-full ${backgroundColors.header} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-center gap-32'>
          <TextCenterDiv className='px-[16px] lg:px-[48px]'>
            <h1
              className={`${isMobile ? 'heading-1-mobile' : 'heading-1'} ${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } `}
              style={{ pointerEvents: 'none' }}
            >
              About
            </h1>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <h3
                  className={`heading-3b ${textColors.subheading} normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  YES! We are the
                </h3>
                <h3
                  className={`heading-3b ${textColors.subheading} normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  School of Design.
                </h3>
              </div>
            ) : (
              <h3
                className={`heading-3b ${textColors.subheading} normal-case`}
                style={{ pointerEvents: 'none' }}
              >
                YES! We are the School of Design.
              </h3>
            )}
          </TextCenterDiv>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.campus} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto w-full'>
          <div className='relative w-full flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-32'>
            <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
              <div className='relative flex flex-col'>
                <div className='flex flex-col text-lg font-light text-black'>
                  <h2
                    className={`heading-2 uppercase ${
                      isDarkMode ? 'text-lime' : 'text-lavender'
                    } pb-6 relative w-2/3 lg:w-full`}
                  >
                    ABOUT OUR CAMPUS
                  </h2>

                  <p className={`mt-4 lg:pr-[20%] body-text ${textColors.dark}`}>
                    The School of Design resides within a 103,000-square-foot facility in the
                    Daniels Waterfront – City of the Arts development that houses our School of
                    Design programs and a research hub.
                  </p>
                  <p className={`relative mt-4 lg:pr-[20%] body-text ${textColors.dark}`}>
                    This state-of-the-art building supports academic programs and industry projects,
                    with features such as a motion capture studio, maker lab, usability and testing
                    lab, as well as a series of design-focused gallery spaces. We are located at 3
                    Lower Jarvis Street.
                  </p>

                  <Link
                    to='/work'
                    className={`${
                      isDarkMode ? 'text-white' : 'text-lavender'
                    } link inline-block wavy-text-link theme-transition w-fit`}
                  >
                    Learn More About Programs <span className='body-text-bold'>↗</span>
                  </Link>
                </div>
              </div>
              <div className='relative flex flex-col'>
                <img
                  loading='lazy'
                  src='/static/2024/img/About1.png'
                  alt='School of Design campus'
                  className='grow w-full rounded-lg shadow-lg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.grads} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto w-full'>
          <div className='relative w-full flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-32'>
            <div className='flex flex-col lg:flex-row-reverse gap-14'>
              <div className='flex flex-col flex-1'>
                <div className={`flex flex-col text-lg font-light ${textColors.dark}`}>
                  <h2
                    className={`heading-2 uppercase ${
                      isDarkMode ? 'text-lime' : 'text-lavender'
                    } pb-6 relative w-2/3 lg:w-full`}
                  >
                    About our grads
                  </h2>

                  <p className={`mt-4 lg:pr-[20%] body-text ${textColors.dark}`}>
                    Students across the School of Design train and hone their crafts in Visual,
                    Interactive, Game, and Strategic Design respectively. YES! celebrates the
                    multidisciplinarity of our graduates, each of whom have benefitted from the
                    wisdom and support of faculty, peers, staff, industry partners, and the much
                    broader design.eas, fostering a strong cohort that values inclusivity and
                    diversity.
                  </p>
                  <p className={`relative mt-4 lg:pr-[20%] body-text ${textColors.dark}`}>
                    We offer a diverse array of programs, encompassing various disciplines. We're a
                    multi-disciplinary cohort who breathe, live and learn in the same space. As we
                    prepare for the world outside of this space, we remember the lessons we learned,
                    the people who helped us on our journey and the fond memories we'll take with
                    us.
                  </p>
                </div>
              </div>
              <div className='flex flex-col flex-1'>
                <img
                  loading='lazy'
                  src='/static/2024/img/gbc_sod_wf_2.jpeg'
                  alt='School of Design graduates'
                  className='grow w-full aspect-[1.49] rounded-lg shadow-lg'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.testimonials} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto w-full'>
          <div className='relative w-full px-[16px] lg:px-[48px] py-32 text-white'>
            <h2
              className={`heading-2 uppercase ${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } pl-6 pb-6 relative w-2/3 lg:w-full`}
            >
              HEAR FROM OUR GRADS
            </h2>
            {isMobile ? (
              <div className='grid grid-cols-1 gap-20'>
                {gradComments.map((comment, index) => (
                  <div
                    key={index}
                    className='!grid !grid-cols-1 gap-12 bg-white bg-opacity-20 p-6 rounded-lg shadow-sm'
                  >
                    <div className={`col-span-1 ${textColors.dark}`}>
                      <p className={`text-left body-text italic ${textColors.dark}`}>
                        {comment.content}
                      </p>
                      <p className={`text-left body-text font-bold mt-4 ${textColors.dark}`}>
                        {comment.who}
                      </p>
                    </div>
                    <img
                      src={comment.image}
                      alt='Graduate'
                      className='w-full rounded-lg shadow-md'
                    />
                  </div>
                ))}
              </div>
            ) : (
              <SlickSlider />
            )}
          </div>
        </div>
      </section>

      <div
        className={`relative w-full ${backgroundColors.footer} flex flex-col items-center justify-center w-full`}
      >
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
