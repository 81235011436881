import React from 'react';
import { LoadingLottie } from './LottieModal';

export default function FullScreenLoader({ bgColor = "bg-black", color = "#DAFF01" }) {
  return (
    <div className={`fixed top-0 left-0 w-full h-full ${bgColor} bg-opacity-10 backdrop-blur-sm flex justify-center items-center z-[2000]`}>
      <LoadingLottie color={color} />
    </div>
  );
}
