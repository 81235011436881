import React from 'react'
import { Route } from 'react-router-dom'

import { default as Login } from '../pages/CMS/Login'
import { default as Projects } from '../pages/CMS/Projects'
import { default as Add } from '../pages/CMS/Add'
import { default as Preview } from '../pages/2025/Preview'
import { default as Update } from '../pages/CMS/Update'
import { default as Edit } from '../pages/CMS/Edit'
import { default as ReqForgotPasswd } from '../pages/CMS/ForgotPasswd/ReqForgotPasswd'
import { default as SubForgotPasswd } from '../pages/CMS/ForgotPasswd/SubForgotPasswd'


export const getRoutesCMS = ({ getScrollTop }) => [
  <Route
    key='login'
    exact
    path='/login'
    render={(props) => <Login {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='projects'
    exact
    path='/projects'
    render={(props) => <Projects {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='add'
    exact
    path='/add'
    render={(props) => <Add {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='preview'
    exact
    path='/preview'
    render={(props) => <Preview {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='update'
    exact
    path='/update'
    render={(props) => <Update {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='edit'
    exact
    path='/edit'
    render={(props) => <Edit {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='forgotpassword'
    exact
    path='/forgotpassword'
    render={(props) => <ReqForgotPasswd {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='new-password'
    exact
    path='/new-password'
    render={(props) => <SubForgotPasswd {...props} getScrollTop={getScrollTop} />}
  />
]
