import React, { useEffect, useMemo, useContext, useState, useRef } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import qs from 'query-string'
import { Link } from 'react-router-dom'
import { disciplines, projectTypeMap, programToImage, programColour } from '../../../constant'
import { useLazyQuery } from '@apollo/client'
import { QUERY } from '../../../graphql'
import take from 'lodash.take'
import shuffle from 'lodash.shuffle'
import { GradCard } from '../../../components/2025/GradCard'
import { SpringContext } from '../../../context/SpringContext'
import { ThemeContext } from '../../../context/2025/ThemeContext'
import { useViewport } from '../../../hook/useViewport'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const FullScreenLoader = React.lazy(() => import('../../../components/FullScreenLoader'))

export default function Profile(props) {
  const { search } = useLocation()
  const { id } = qs.parse(search)
  const { isDarkMode } = useContext(ThemeContext)
  const { width } = useViewport()
  const isMobile = width < 1024
  const imageRef = useRef(null)

  const { data: studentData, loading: studentLoading } = useQuery(SINGLE_USER_QUERY, {
    variables: {
      id,
    },
  })

  const user = useMemo(() => studentData?.student || {}, [studentData])
  const userId = props.id || user?.id

  const [userImage, setUserImage] = useState(programToImage[user?.program])
  const spring = useContext(SpringContext)

  const { data: studentProjectData, loading: projectsLoading } = useQuery(STUDENT_PROJECTS_QUERY, {
    variables: {
      id,
    },
  })

  const projects = useMemo(
    () =>
      (studentProjectData?.studentProjects || [])
        .map((prj) => {
          const { orderList } = prj
          const meOrdersList = orderList.find((ord) => ord.userId === userId)
          return { ...prj, order: meOrdersList ? meOrdersList.order : 0 }
        })
        .sort((a, b) => a.order - b.order),
    [studentProjectData, userId],
  )

  const [fetchGrads, { data: gradsOther, loading: gradsLoading }] = useLazyQuery(QUERY.GRADS_PAGE_QUERY, {
    variables: {
      where: {
        studentYear: 2025,
        activeAt_gt: '2024-12-31',
      },
      first: 30,
    },
  })

  const handleScrollTop = () => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
      fetchGrads()
    }
  }

  useEffect(() => {
    if (user?.image) {
      const _userImage =
        user?.image !== null && user?.image !== ''
          ? encodeURI(user?.image)
          : programToImage[user?.program]
      setUserImage(_userImage)
    }
  }, [user])

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring, fetchGrads])

  useEffect(() => {
    if (userId) {
      fetchGrads()
    }
  }, [fetchGrads, userId])

  const otherGrads = useMemo(() => {
    if (gradsOther?.users) {
      const grads = gradsOther?.users.map(
        (grad) => grad.id !== undefined && grad.id !== userId && grad,
      )
      if (grads?.length === 1 && grads[0] === false) {
        return []
      }
      return take(shuffle(grads), 3)
    }
    return []
  }, [gradsOther, userId])

  const backgroundColors = {
    header: isDarkMode ? 'bg-black' : 'bg-white',
    content: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    projects: isDarkMode ? 'bg-black' : 'bg-white',
    graduates: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    footer: isDarkMode ? 'bg-black' : 'bg-light-grey-25'
  }

  const textColors = {
    heading: isDarkMode ? 'text-lime' : 'text-lavender',
    subheading: isDarkMode ? 'text-white' : 'text-black',
    body: isDarkMode ? 'text-white' : 'text-black',
    link: isDarkMode ? 'text-lime hover:text-white' : 'text-lavender hover:text-black',
    accent: isDarkMode ? 'text-lime' : 'text-lavender'
  }

  const loading = studentLoading || projectsLoading || gradsLoading;

  require('./profile.scss')
  return (
    <div className={`absolute w-full min-h-screen ${backgroundColors.header} flex flex-col items-center justify-start pb-20 lg:pb-0`}>
      {loading && <FullScreenLoader color={isDarkMode ? '#DAFF01' : '#8A74D0'} />}
      {Header && <Header />}

      <section className={`w-full ${backgroundColors.content} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32'>
          <div className='grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-16'>
            <div className='flex flex-col items-center lg:items-start'>
              <div className='relative overflow-hidden rounded-lg w-full max-w-[320px]'>
                <img
                  ref={imageRef}
                  src={userImage}
                  onError={() => setUserImage(programToImage[user?.program])}
                  className='object-cover w-full aspect-square rounded-lg shadow-md'
                  alt={`${user?.firstName} ${user?.lastName}`}
                />
              </div>
            </div>

            <div className='lg:col-span-2 mt-8 lg:mt-0'>
            {user?.portfolio && (
                <a
                  href={user?.portfolio}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`${textColors.link} mt-6`}
                  style={{
                    borderBottom: '2px',
                    borderStyle: 'dashed',
                    borderColor: isDarkMode ? '#DAFF01' : '#8A74D0',
                    paddingBottom: '8px',
                  }}
                >
                  <a
                    className={textColors.link}
                    href={user?.portfolio}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Portfolio ↗
                  </a>
                </a>
              )}
              <h2 className={`${textColors.heading} heading-2`}>
                {user?.firstName} {user?.lastName}
              </h2>
              <p className={`${textColors.body} body-text mt-2`}>{user?.tagline}</p>

              <div className='flex flex-wrap gap-4 mt-4'>
                <p className={`${textColors.body} body-text`}>
                  <span className={`${textColors.heading} body-text`}>◼</span> {user?.program}
                </p>
                {disciplines[projectTypeMap[user?.primaryExpertise]] && (
                  <p className={`${textColors.body} body-text`}>◼ {disciplines[projectTypeMap[user?.primaryExpertise]]}</p>
                )}
                {disciplines[projectTypeMap[user?.secondaryExpertise]] &&
                  disciplines[projectTypeMap[user?.primaryExpertise]] !==
                    disciplines[projectTypeMap[user?.secondaryExpertise]] && (
                    <p className={`${textColors.body} body-text`}>◼ {disciplines[projectTypeMap[user?.secondaryExpertise]]}</p>
                  )}
              </div>

              <p className={`${textColors.body} body-text mt-6`}>{user?.about}</p>
              <div className='flex mt-6 gap-4'>
                {user.instagram && (
                  <a href={user.instagram} target='_blank' rel='noreferrer' className='hover:opacity-80 transition-opacity'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 2.163C15.204 2.163 15.584 2.175 16.85 2.233C20.102 2.381 21.621 3.924 21.769 7.152C21.827 8.417 21.838 8.797 21.838 12.001C21.838 15.206 21.826 15.585 21.769 16.85C21.62 20.075 20.105 21.621 16.85 21.769C15.584 21.827 15.206 21.839 12 21.839C8.796 21.839 8.416 21.827 7.151 21.769C3.891 21.62 2.38 20.07 2.232 16.849C2.174 15.584 2.162 15.205 2.162 12C2.162 8.796 2.175 8.417 2.232 7.151C2.381 3.924 3.896 2.38 7.151 2.232C8.417 2.175 8.796 2.163 12 2.163ZM12 0C8.741 0 8.333 0.014 7.053 0.072C2.695 0.272 0.273 2.69 0.073 7.052C0.014 8.333 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.272 21.306 2.69 23.728 7.052 23.928C8.333 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C21.302 23.728 23.73 21.31 23.927 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.333 23.928 7.053C23.732 2.699 21.311 0.273 16.949 0.073C15.668 0.014 15.259 0 12 0Z" fill={isDarkMode ? "#DAFF01" : "#8A74D0"}/>
                      <path d="M12 5.838C8.597 5.838 5.838 8.597 5.838 12C5.838 15.403 8.597 18.163 12 18.163C15.403 18.163 18.162 15.404 18.162 12C18.162 8.597 15.403 5.838 12 5.838ZM12 16C9.791 16 8 14.21 8 12C8 9.791 9.791 8 12 8C14.209 8 16 9.791 16 12C16 14.21 14.209 16 12 16Z" fill={isDarkMode ? "#DAFF01" : "#8A74D0"}/>
                      <path d="M18.406 7.034C19.2013 7.034 19.846 6.38929 19.846 5.594C19.846 4.79871 19.2013 4.154 18.406 4.154C17.6107 4.154 16.966 4.79871 16.966 5.594C16.966 6.38929 17.6107 7.034 18.406 7.034Z" fill={isDarkMode ? "#DAFF01" : "#8A74D0"}/>
                    </svg>
                  </a>
                )}
                {user.dribble && (
                  <a href={user.dribble} target='_blank' rel='noreferrer' className='hover:opacity-80 transition-opacity'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 0C5.373 0 0 5.373 0 12C0 18.627 5.373 24 12 24C18.627 24 24 18.627 24 12C24 5.373 18.627 0 12 0ZM19.753 5.609C21.139 7.295 21.999 9.427 22.047 11.738C21.803 11.691 18.42 11.044 15.008 11.475C14.958 11.366 14.911 11.254 14.861 11.142C14.671 10.725 14.467 10.306 14.255 9.899C17.964 8.302 19.541 5.917 19.753 5.609ZM12 1.954C14.513 1.954 16.817 2.864 18.588 4.384C18.408 4.666 16.998 6.903 13.425 8.327C11.749 5.13 9.881 2.521 9.591 2.122C10.361 2.006 11.167 1.954 12 1.954ZM7.637 2.85C7.912 3.228 9.747 5.847 11.444 8.983C6.659 10.312 2.431 10.282 1.978 10.275C2.655 6.976 4.78 4.204 7.637 2.85ZM1.952 12.01C1.952 11.919 1.954 11.827 1.956 11.736C2.395 11.745 7.341 11.816 12.471 10.213C12.741 10.741 12.996 11.278 13.232 11.816C13.077 11.857 12.919 11.899 12.766 11.944C7.454 13.713 4.645 18.273 4.416 18.644C2.866 16.863 1.952 14.536 1.952 12.01ZM12 22.066C9.714 22.066 7.612 21.309 5.95 20.035C6.128 19.676 8.244 15.752 14.024 13.656C14.045 13.647 14.066 13.642 14.087 13.634C15.536 17.422 16.149 20.59 16.323 21.615C14.995 21.915 13.577 22.066 12 22.066ZM18.155 20.653C18.037 19.906 17.474 16.903 16.129 13.179C19.341 12.664 22.149 13.451 22.399 13.525C21.95 16.495 20.391 19.074 18.155 20.653Z" fill={isDarkMode ? "#DAFF01" : "#8A74D0"}/>
                    </svg>
                  </a>
                )}
                {user.linkedIn && (
                  <a href={user.linkedIn} target='_blank' rel='noreferrer' className='hover:opacity-80 transition-opacity'>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.447 20.452H16.893V14.883C16.893 13.555 16.866 11.846 15.041 11.846C13.188 11.846 12.905 13.291 12.905 14.785V20.452H9.351V9H12.765V10.561H12.811C13.288 9.661 14.448 8.711 16.181 8.711C19.782 8.711 20.448 11.081 20.448 14.166V20.452H20.447ZM5.337 7.433C4.193 7.433 3.274 6.507 3.274 5.368C3.274 4.23 4.194 3.305 5.337 3.305C6.477 3.305 7.401 4.23 7.401 5.368C7.401 6.507 6.476 7.433 5.337 7.433ZM7.119 20.452H3.555V9H7.119V20.452ZM22.225 0H1.771C0.792 0 0 0.774 0 1.729V22.271C0 23.227 0.792 24 1.771 24H22.222C23.2 24 24 23.227 24 22.271V1.729C24 0.774 23.2 0 22.222 0H22.225Z" fill={isDarkMode ? "#DAFF01" : "#8A74D0"}/>
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.projects} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-16'>
          <h2 className={`${textColors.heading} heading-2 mb-12`}>PROJECTS</h2>

          <div className='flex flex-col gap-16'>
            {projects.map((project) => (
              <div
                key={project.id}
                className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16'
              >
                <Link to={{ pathname: '/project', search: `id=${project.id}` }} className='block'>
                  <div className='overflow-hidden rounded-lg shadow-md'>
                    <img
                      src={project.coverImage}
                      alt={project.title}
                      className='w-full h-full object-cover transition-transform duration-300 hover:scale-105'
                    />
                  </div>
                </Link>
                <div className='flex flex-col'>
                  <Link to={{ pathname: '/project', search: `id=${project.id}` }}>
                    <h3 className={`${textColors.subheading} heading-3`}>
                      {project.title}
                    </h3>
                  </Link>

                  <div className='flex flex-wrap gap-4 mt-4'>
                    <Link
                      to={{
                        pathname: '/work',
                        search: `?program=${user?.program}`,
                      }}
                    >
                      <p className={`${textColors.body} body-text`}>
                        <span className={`${textColors.heading} body-text`}>◼</span> {user?.program}
                      </p>
                    </Link>

                    <Link
                      className='cursor-pointer'
                      to={{
                        pathname: '/work',
                        search: `?type=${project['primaryProjectType']}`,
                      }}
                    >
                      {disciplines[project['primaryProjectType']] && (
                        <p className={`${textColors.body} body-text`}>
                          ◼ {disciplines[project['primaryProjectType']]}
                        </p>
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.graduates} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-16'>
          <h2 className={`${textColors.heading} heading-2 mb-12`}>OTHER GRADUATES</h2>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
            {gradsOther?.users &&
              otherGrads.length > 0 &&
              otherGrads.map((_grad, idx) => (
                <GradCard key={idx} grad={_grad} scrollTop={handleScrollTop} isDarkMode={isDarkMode} />
              ))}
          </div>
        </div>
      </section>

      <div className={`relative w-full ${backgroundColors.footer} flex flex-col items-center justify-center w-full`}>
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}

const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($id: ID!) {
    student(where: { id: $id }) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjects(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      orderList {
        userId
        order
      }
    }
  }
`