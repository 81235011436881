import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import { Helmet } from 'react-helmet-async'
import ReactMarkDown from 'react-markdown'
import { Link } from 'react-router-dom'
import { projectType, programToImage } from '../../constant'
import { useTheme } from '../../context/2025/ThemeContext'


export default function PreviewProject(props) {
  const { isDarkMode } = useTheme()
  const history = useHistory()
  const { search } = useLocation()
  const { id } = qs.parse(search)

  const { data } = useQuery(SINGLE_PROJECT_QUERY, {
    variables: {
      id,
    },
  })
  const project = useMemo(() => data?.project || {}, [data])

  const [publishProject] = useMutation(PUBLISH_PROJECT_MUTATION, {
    variables: {
      id,
    },
  })

  const handlePublish = useCallback(async () => {
    try {
      await publishProject()
      history.push({
        pathname: '/project',
        search: `?id=${id}`,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }, [publishProject, id, history])

  return (
    <div className={`relative min-w-full min-h-screen ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      {project.title && (
        <Helmet>
          <title>YES! | {project.title}</title>
        </Helmet>
      )}

      {project?.coverImage && (
        <div
          style={{
            background: 'url(' + encodeURI(project?.coverImage) + ')',
            width: '100%',
            height: '380px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      )}
      <div className='px-[16px] lg:px-[48px] pt-32'>
        <div className='w-full mx-auto grid grid-cols-1 gap-16 pb-32 lg:grid-cols-2'>
          <div className='flex flex-col items-start justify-start'>
            <h2 className={`heading-2 ${isDarkMode ? 'text-lime' : 'text-lavender'}`}>{project?.title}</h2>
            {project.members?.map((member) => (
              <div key={member?.user?.id} className='pt-2'>
                <div className='flex flex-col items-start justify-start'>
                  <div className='flex flex-wrap justify-start items-center gap-4 py-6'>
                    <p className={isDarkMode ? 'text-lime' : 'text-lavender'}>
                      ● {member?.user?.program}
                    </p>
                    {member?.user?.primaryExpertise && (
                      <p className={isDarkMode ? 'text-white' : 'text-black'}>
                        ● {projectType[member?.user?.primaryExpertise]}
                      </p>
                    )}
                    {member?.user?.secondaryExpertise && (
                      <p className={isDarkMode ? 'text-white' : 'text-black'}>
                        ● {projectType[member?.user?.secondaryExpertise]}
                      </p>
                    )}
                  </div>
                  <Link
                    to={{
                      pathname: '/student',
                      search: `id=${member?.user.id}`,
                    }}
                    className='inline-block flex items-center'
                  >
                    <img
                      src={member?.user?.image || programToImage[member?.user?.program]}
                      alt='member'
                      className='w-20 h-20 rounded-full mx-auto object-cover'
                      style={{ filter: 'grayscale(100%)' }}
                    />
                    <p className={`wavy-text-link ${isDarkMode ? 'text-white' : 'text-black'} mt-2 ml-4 text-4xl`}>
                      {member?.user?.firstName} {member?.user?.lastName}
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <p className={isDarkMode ? 'text-white' : 'text-black'}>
            {project?.contentData?.length > 0 && project?.contentData[0]}
          </p>
        </div>
        <div className={isDarkMode ? 'text-white' : 'text-black'}>
          <div className='mt-16'>
            {project?.contentType?.map((contentType, i) => {
              if (i === 0) {
                return null
              } else if (contentType === 'Heading') {
                return (
                  <h3 key={i} className={`heading-3 pb-16 capitalize ${isDarkMode ? 'text-lime' : 'text-lavender'}`}>
                    {project.contentData[i]}
                  </h3>
                )
              } else if (contentType === 'Text') {
                return (
                  <p className={`w-full pb-8 first-letter:uppercase ${isDarkMode ? 'text-white' : 'text-black'}`} key={`contenttype-${i}`}>
                    <ReactMarkDown key={i} children={project.contentData[i]} />
                  </p>
                )
              } else if (contentType === 'Image') {
                return (
                  <div className='w-full' key={i}>
                    <Image src={project.contentData[i]} alt='Image Preview' className='pb-16' />
                  </div>
                )
              } else if (contentType === 'Video') {
                return (
                  <div key={i} className='w-full lg:w-4/6 mx-auto pb-4 lg:pb-12 lg:px-0'>
                    <video controls className='w-full mx-auto'>
                      <source type='video/mp4' src={project.contentData[i]} />
                    </video>
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
        <div className='flex justify-end mt-6'>
          <div className='w-1/2 flex justify-between'>
            <Link
              to={{
                pathname: '/update',
                search: `id=${project.id}`,
              }}
            >
              <Button isDarkMode={isDarkMode} className={`${
                isDarkMode ? 'bg-lime text-black' : 'bg-lavender text-white'
                } mx-4 hover:scale-[1.04]`}>Edit</Button>
            </Link>
            <Button isDarkMode={isDarkMode} className={`${
                isDarkMode ? 'bg-lime text-black' : 'bg-lavender text-white'
                } mx-4 hover:scale-[1.04]`} onClick={handlePublish}>
              Publish
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const SINGLE_PROJECT_QUERY = gql`
  query SINGLE_PROJECT_QUERY($id: ID!) {
    project(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      members {
        role
        user {
          id
          firstName
          lastName
          image
          tagline
          program
          contactEmail
          primaryExpertise
          secondaryExpertise
          about
          image
          portfolio
          behance
          linkedIn
          instagram
          twitter
          facebook
          dribble
          youtube
          vimeo
          medium
        }
      }
      published
    }
  }
`

const PUBLISH_PROJECT_MUTATION = gql`
  mutation PUBLISH_PROJECT_MUTATION($id: ID!) {
    publishProject(id: $id) {
      id
      title
      tags
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      members {
        id
        role
        user {
          id
        }
      }
      coverImage
      published
    }
  }
`

const Image = styled.img`
  width: 100%;
`

const Button = styled.button`
  width: 100%;
  border: 0;
  font-family: 'grotesk-regular';
  font-size: 1.6rem;
  padding: 1.2rem 2.4rem;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border-radius: 3rem;
  width: 240px;
  transition: background-color 0.3s ease, color 0.3s ease;

  @media (min-width: 768px) {
    font-size: 1.8rem;
    min-width: 180px;
  }
`
