import * as React from 'react'
import { useEffect, useRef, useContext } from 'react'
import { P } from '../../styles/2024/TextStyles'
import { ThemeContext } from '../../context/2025/ThemeContext'

const bgColorList = {
  light: ['bg-lavender', 'bg-white', 'bg-lime'],
  dark: ['bg-lavender', 'bg-white', 'bg-lime']
}

const scrollBarClasses = ['green-scrollbar', 'blue-scrollbar']

const LetterBox = ({ letter, index, isDarkMode }) => {
  const arrIndex = index % 3
  const messageRef = useRef(null)
  const { isDarkMode: contextDarkMode } = useContext(ThemeContext)

  // Use passed prop or context value
  const darkMode = isDarkMode !== undefined ? isDarkMode : contextDarkMode

  const colorMode = darkMode ? 'dark' : 'light'
  const bgColor = bgColorList[colorMode][arrIndex]
  const scrollBarIndex = arrIndex === 1 ? 0 : 1
  const scrollBarClass = scrollBarClasses[scrollBarIndex]
  const { sender, recipient, message } = letter

  useEffect(() => {
    // You can control the scrollbar with messageRef here if needed
  }, []) // Empty array ensures effect only runs on mount

  const _message = message.replace(/^\s+|\s+$/g, '');

  require('./scrollbar.scss')
  return (
    <div
      className={`relative flex flex-col justify-between items-start rounded-lg shadow-md self-strecth h-[318px] flex-[1_0_0] p-[24px] ${bgColor} transition-colors duration-300`}
    >
      <div className='flex flex-col items-start self-stretch gap-[10px]'>
        <P className='text-black font-medium text-lg'>To: {recipient}</P>
      </div>

      <div
        className={`flex relative items-start flex-[1_0_0] ${scrollBarClass}`}
        ref={messageRef}
        style={{ overflowX: 'hidden', overflowY: 'auto'}}
      >
        <div className='pr-4'>
          <P className='flex flex-wrap text-black body-text lg:max-w-[250px] break-word'>{_message}</P>
        </div>
      </div>
      <div className='flex flex-col items-end self-stretch gap-[10px]'>
        <P className='text-black font-medium text-lg'>From: {sender}</P>
      </div>
    </div>
  )
}

export default LetterBox