import { useState, useEffect, useMemo } from 'react'

export function useViewport() {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  })

  useEffect(() => {
    if (typeof window === 'undefined') return

    let timeoutId

    const handleResize = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }, 100) // Debounce resize events
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      clearTimeout(timeoutId)
    }
  }, [])

  const screenInfo = useMemo(() => {
    const { width } = windowSize

    // Tailwind CSS breakpoints
    const breakpoints = {
      'sm': 640,
      'md': 768,
      'lg': 1024,
      'xl': 1280,
      '2xl': 1536,
    }

    let screen = 'sm'
    if (width >= breakpoints['2xl']) screen = '2xl'
    else if (width >= breakpoints.xl) screen = 'xl'
    else if (width >= breakpoints.lg) screen = 'lg'
    else if (width >= breakpoints.md) screen = 'md'
    else screen = 'sm'

    return {
      ...windowSize,
      screen,
      isMobile: width < breakpoints.md,
      isTablet: width >= breakpoints.md && width < breakpoints.lg,
      isDesktop: width >= breakpoints.lg,
    }
  }, [windowSize])

  return screenInfo
}