import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { SpringSystem, MathUtil } from 'rebound'
import 'semantic-ui-css/semantic.min.css'

export default class ColoredScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.scrollbars = React.createRef()
    this.state = { top: 0 }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.renderView = this.renderView.bind(this)
    this.renderThumb = this.renderThumb.bind(this)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
  }

  handleUpdate(values) {
    const { top } = values
    this.setState({ top })
  }

  renderView({ style, ...props }) {
    // const { top } = this.state
    const viewStyle = {
      padding: 0,
      overflowX: 'hidden',
      backgroundColor: ` #5933ff`,
      color: `#d5fd00`,
      top: 0,
      left: 0,
      width: '100%',
      minHeight: '100vh',
    }
    return <div className='box' style={{ ...style, ...viewStyle }} {...props} />
  }

  renderThumb({ style, ...props }) {
    // const { top } = this.state
    const thumbStyle = {
      position: 'absolute',
      right: window.innerWidth < 1000 ? '-2px' : '30px',
      bottom: '140px',
      top: 0,
      borderRadius: '3px',
      zIndex: 1001,
      width: '8px',
      backgroundColor: `#d5fd00`,
      marginTop: 0,
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  componentDidMount() {
    this.springSystem = new SpringSystem()
    this.spring = this.springSystem.createSpring()
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring)
    this.springSystem.removeAllListeners()
    this.springSystem = undefined
    this.spring.destroy()
    this.spring = undefined
  }

  getScrollTop() {
    return this.scrollbars.current.getScrollTop()
  }

  getScrollHeight() {
    return this.scrollbars.current.getScrollHeight()
  }

  getHeight() {
    return this.scrollbars.current.getHeight()
  }

  scrollTop(top) {
    const scrollTop = this.scrollbars.current.getScrollTop()
    const scrollHeight = this.scrollbars.current.getScrollHeight()
    const val = MathUtil.mapValueInRange(
      top,
      0,
      scrollHeight,
      scrollHeight * 0.2,
      scrollHeight * 0.8,
    )
    this.spring.setCurrentValue(scrollTop).setAtRest()
    this.spring.setEndValue(val)
  }

  handleSpringUpdate(spring) {
    const val = spring.getCurrentValue()
    this.scrollbars.current.scrollTop(val)
  }

  render() {
    return (
      <Scrollbars
        renderView={this.renderView}
        renderThumbVertical={this.renderThumb}
        autoHide={true}
        {...this.props}
        ref={this.scrollbars}
      />
    )
  }
}
