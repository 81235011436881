import React, { useState, useContext, useEffect, useRef } from 'react'
import { SpringContext } from '../../../context/SpringContext'
import { useViewport } from '../../../hook/useViewport'
import { TextCenterDiv } from '../../../styles/2024/GradsStyles'
import { events25, directions } from '../../../constant'
import { ThemeContext } from '../../../context/2025/ThemeContext'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const ThreeSlider = React.lazy(() =>
  import('../../../components/2025/SlickSlider').then((module) => ({
    default: module.ThreeSlider,
  })),
)

const ChevronIcon = ({ isUp, isDarkMode }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={`chevron ${isUp ? 'chevron-up' : ''}`}
  >
    <path
      d='M6 9L12 15L18 9'
      stroke={isDarkMode ? '#DAFF01' : '#9167F0'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const Locationpin = ({ isDarkMode }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='inline cursor-pointer'
  >
    <path
      d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
      fill={isDarkMode ? '#DAFF01' : '#9167F0'}
    />
    <path
      d='M12 22C12 22 20 16 20 10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10C4 16 12 22 12 22Z'
      stroke={isDarkMode ? '#DAFF01' : '#9167F0'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default function Index() {
  const { width } = useViewport()
  const spring = useContext(SpringContext)
  const [visibleEvent, setVisibleEvent] = useState(null)
  const { isDarkMode } = useContext(ThemeContext)
  const mapRef = useRef(null)

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const toggleEventVisibility = (id) => {
    setVisibleEvent(visibleEvent === id ? null : id)
  }

  const isMobile = width < 1024
  const handleDragStart = (e) => e.preventDefault()

  const showInMapClicked = () => {
    window.open('https://maps.google.com?q=43.6444842,-79.3688826')
  }

  require('./events.scss')

  const backgroundColors = {
    header: isDarkMode ? 'bg-black' : 'bg-white',
    agenda: isDarkMode ? 'bg-lime' : 'bg-friendly-yellow',
    expect: isDarkMode ? 'bg-purple25' : 'bg-warm-orange',
    directions: isDarkMode ? 'bg-[#222222]' : 'bg-black',
    footer: isDarkMode ? 'bg-black' : 'bg-black',
  }

  const textColors = {
    heading: isDarkMode ? 'text-lime' : 'text-comfort-green',
    subheading: isDarkMode ? 'text-white' : 'text-white',
    dark: 'text-black',
    light: isDarkMode ? 'text-white' : 'text-black',
    accent: isDarkMode ? 'text-lime' : 'text-comfort-green',
  }

  return (
    <div
      className={`absolute w-full min-h-screen ${backgroundColors.header} flex flex-col items-center justify-start pb-20 lg:pb-0`}
    >
      {Header && <Header />}

      <section className={`w-full ${backgroundColors.header} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-center gap-32'>
          <TextCenterDiv className='px-[16px] lg:px-[48px]'>
            <h1
              className={`${isMobile ? 'heading-1-mobile' : 'heading-1'} ${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } `}
              style={{ pointerEvents: 'none' }}
            >
              Events
            </h1>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <h3
                  className={`heading-3b ${
                    isDarkMode ? 'text-white' : 'text-black'
                  } normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  YES!
                </h3>
                <h3
                  className={`heading-3b ${
                    isDarkMode ? 'text-white' : 'text-black'
                  } normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  The party's this way.
                </h3>
              </div>
            ) : (
              <h3
                className={`heading-3b ${isDarkMode ? 'text-white' : 'text-black'} normal-case m-0`}
                style={{ pointerEvents: 'none' }}
              >
                YES! The party's this way.
              </h3>
            )}
          </TextCenterDiv>
        </div>
      </section>

      <section
        className={`w-full ${
          isDarkMode ? 'bg-black' : 'bg-light-grey-25'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-between gap-32'>
          <div
            className={`relative w-full flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-20 max-w-[1440px]`}
          >
            <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
              <div className='relative flex flex-col'>
                <img
                  loading='lazy'
                  src='/static/2024/img/gbc_sod_wf_3.png'
                  alt=''
                  className='grow w-full'
                />
              </div>
              <div className='relative flex flex-col'>
                <div className='flex flex-col text-lg font-light text-lime'>
                  <h2
                    className={`heading-2 uppercase ${isDarkMode ? 'text-lime' : 'text-lavender'}`}
                  >
                    Join us on May 1-2!
                  </h2>
                  <p
                    className={`mt-4 lg:pr-[20%] body-text ${
                      isDarkMode ? 'text-white' : 'text-black'
                    }`}
                  >
                    YES! is an interactive showcase of creativity and talent nurtured across our
                    campus. Immerse yourself in inspiration, participate in engaging workshops, and
                    interact with our vibrant design community during this celebration of students'
                    achievement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-between gap-32'>
          <h2
            className={`heading-2 uppercase w-full align-start ${
              isDarkMode ? 'text-lime' : 'text-lavender'
            }`}
          >
            AGENDA
          </h2>
          <div className='flex flex-col w-full'>
            <div className='hidden lg:grid grid-cols-1 lg:grid-cols-10'>
              <div className={`col-span-1 text-white`}></div>
              <div
                className={`grid grid-cols-1 lg:grid-cols-3 lg:col-span-9 text-white} ml-[48px] border-b ${
                  isDarkMode ? 'border-white' : 'border-black'
                } border-dashed`}
              >
                <h3 className={`heading-3 col-span-1 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                  Event
                </h3>

                <div></div>
                <h3 className={`heading-3 col-span-1 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                  Time
                </h3>
              </div>
            </div>
            {Object.entries(events25).map(([key, events]) => (
              <div key={key} className='grid grid-cols-1 lg:grid-cols-10 mb-[24px]'>
                <div className={`col-span-1  ${isDarkMode ? 'text-white' : 'text-black'}`}>
                  {key === 'dayOne' ? (
                    <div className='flex items-center justify-center w-[125px] h-[125px] rounded-full bg-lavender text-white heading-2 !text-[32px] '>
                      May 1
                    </div>
                  ) : (
                    <div className='flex items-center justify-center w-[125px] h-[125px] rounded-full bg-lavender text-white heading-2 !text-[32px]'>
                      May 2
                    </div>
                  )}
                </div>
                <div
                  className={`grid lg:col-span-9 ${
                    isDarkMode ? 'text-white' : 'text-black'
                  } heading-4-bold lg:ml-[48px]`}
                >
                  {events.map((event) => (
                    <React.Fragment key={event.id}>
                      <div
                        className={`grid grid-cols-3 border-b ${
                          isDarkMode ? 'border-white' : 'border-black'
                        } border-dashed pt-[16px] pb-[24px] cursor-pointer`}
                        onClick={() => toggleEventVisibility(event.id)}
                      >
                        <h4 className='heading-4-bold-bold col-span-1 m-0'>{event.name}</h4>
                        <div className='col-span-1'></div>
                        <div className='grid lg:grid-cols-3 col-span-1 w-full justify-items-end'>
                          <h4 className='heading-4-bold hidden lg:block m-0 col-span-2 justify-self-start'>
                            {event.time}{' '}
                          </h4>
                          <ChevronIcon
                            isUp={visibleEvent === event.id}
                            isDarkMode={isDarkMode}
                            className='justify-self-end'
                          />
                        </div>
                        <div
                          className={`col-span-3 transition-max-height duration-500 ease-in-out overflow-hidden ${
                            visibleEvent === event.id ? 'max-h-[600px] lg:max-h-96' : 'max-h-0'
                          }`}
                        >
                          <p className='body-text' style={{ whiteSpace: 'pre-line' }}>{event.content}</p>
                          {event.link && event.link !== '' && (
                            <span className='border-b border-black border-dashed'>
                              <a href={event.link} target='_blank' rel='noreferrer'>
                                Sign up for this event ↗
                              </a>
                            </span>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className={`w-full ${
          isDarkMode ? 'bg-black' : 'bg-light-grey-25'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-between gap-32'>
        <h2
            className={`heading-2 uppercase w-full align-start ${
              isDarkMode ? 'text-lime' : 'text-lavender'
            }`}
          >
            What To Expect
          </h2>

          {isMobile ? (
            <div className='flex flex-col items-center relative gap-6 mt-10'>
              <img
                src='/static/2024/img/Frame62.jpeg'
                alt='Year End Show Activities'
                role='presentation'
                className='relative w-full pb-4'
              />

              <img
                src='/static/2024/img/Frame63.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
              />

              <img
                src='/static/2024/img/Frame64.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
              />

              <img
                src='/static/2024/img/Frame65.jpeg'
                alt='Year End Show Activities'
                onDragStart={handleDragStart}
                role='presentation'
              />
            </div>
          ) : (
            <ThreeSlider />
          )}
        </div>
      </section>

      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-between gap-32'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-20'>
            <div>
              <h2
                className={`heading-2 ${isDarkMode ? 'text-lime' : 'text-lavender'} border-b ${
                  isDarkMode ? 'border-white' : 'border-white'
                } border-dashed pb-[36px]`}
              >
                DIRECTIONS
              </h2>
              {directions.map((direction) => (
                <React.Fragment key={direction.id}>
                  <div
                    className={`grid grid-cols-3 border-b ${
                      isDarkMode ? 'border-white' : 'border-black'
                    } border-dashed px-[32px] pt-[16px] pb-[24px] cursor-pointer`}
                    onClick={() => toggleEventVisibility(direction.id)}
                  >
                    <h4 className={`heading-4-bold m-0 col-span-2 ${textColors.light}`}>
                      {direction.name}
                    </h4>
                    <div className='grid col-span-1 w-full justify-items-end'>
                      <ChevronIcon
                        isUp={visibleEvent === direction.id}
                        isDarkMode={isDarkMode}
                        className='justify-self-end self-center'
                      />
                    </div>
                    <div
                      className={`col-span-3 transition-max-height duration-500 ease-in-out overflow-hidden ${
                        visibleEvent === direction.id ? 'max-h-[600px]' : 'max-h-0'
                      }`}
                    >
                      <p className={`${textColors.light} whitespace-pre-line body-text`}>
                        {direction.content}
                      </p>
                      {direction.link && direction.link !== '' && (
                        <span
                          className={`border-b ${
                            isDarkMode ? 'border-white' : 'border-white'
                          } border-dotted pb-[4px]`}
                        >
                          <a
                            href={direction.link}
                            target='_blank'
                            rel='noreferrer'
                            className={`${textColors.light} hover:${textColors.accent}`}
                          >
                            {direction.linkTitle} ↗
                          </a>
                        </span>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div>
              <div ref={mapRef} className='relative'>
                <img
                  src='/static/2024/img/lowerJmap.png'
                  alt='Map'
                  className='w-full pb-4 cursor-pointer'
                  onClick={() => showInMapClicked()}
                />
                <Locationpin isDarkMode={isDarkMode} onClick={() => showInMapClicked()} />
                <span
                  className={`${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block wavy-text-link theme-transition cursor-pointer`}
                  onClick={() => showInMapClicked()}
                >
                  3 Lower Jarvis St, Toronto, ON M5E 3Y5
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`relative w-full flex flex-col items-center justify-center w-full ${
          isDarkMode ? 'bg-black' : 'bg-light-grey-25'
        }`}
      >
        <div
          className={`relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-black max-w-[1440px] ${
            isDarkMode ? 'bg-black' : 'bg-light-grey-25'
          }`}
        >
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
