import React, { useContext } from 'react'
import { disciplines } from '../../constant'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../context/2025/ThemeContext'

export const ProjectCard = ({ project, scrollTop }) => {
  const { isDarkMode } = useContext(ThemeContext)
  return (
    <div className={`flex flex-col self-stretch ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      <Link
        to={{
          pathname: '/project',
          search: `id=${project?.id}`,
        }}
        onClick={() => {
          scrollTop && scrollTop()
        }}
        className='h-[300px] w-full overflow-hidden flex items-center justify-center'
      >
        <img
          src={encodeURI(project?.coverImage)}
          alt={project.title}
          className='object-cover w-full h-full'
        />
      </Link>
      <div
        className={`flex flex-col self-stretch ${isDarkMode ? 'bg-black' : 'bg-white'} py-8 px-4`}
      >
        <Link
          to={{
            pathname: '/student',
            search: `id=${project?.members[0]?.user?.id}`,
          }}
          onClick={() => {
            scrollTop && scrollTop()
          }}
        >
          <h3
            className={`heading-4-bold w-fit border-b border-dotted  ${
              isDarkMode
                ? 'text-lime border-lime hover:text-lime'
                : 'text-lavender border-lavender hover:text-lavender'
            } cursor-pointer`}
          >
            {project?.members[0]?.user?.firstName} {project?.members[0]?.user?.lastName}
          </h3>
        </Link>
        <div className='body-text flex flex-wrap justify-start items-center gap-4 pt-6'>
          {project?.members[0]?.user?.program && (
            <p
              className={`body-text  ${
                isDarkMode ? 'text-white ' : 'text-black'
              } inline-block p-0 m-0`}
            >
              <span className={`${isDarkMode ? 'text-lime ' : 'text-lavender'}`}>◼</span>{' '}
              {project?.members[0]?.user?.program}
            </p>
          )}
          {project?.primaryProjectType && disciplines[project?.primaryProjectType] && (
            <p
              className={`body-text ${
                isDarkMode ? 'text-white ' : 'text-black'
              } inline-block p-0 m-0`}
            >
              ◼ {disciplines[project?.primaryProjectType]}
            </p>
          )}
        </div>
        <p className={`body-text ${isDarkMode ? 'text-white ' : 'text-black'} mt-2`}>
          {project?.title}
        </p>
      </div>
    </div>
  )
}
