import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import cn from 'classnames'

const Main = styled.div`
  a:hover {
    color: #5933ff;
  }
`

export default function FixedBottomNav({ className = '', goBack = false, toggleMarqueeAnimation }) {
  const { pathname } = useLocation()
  const [isPaused, setIsPaused] = useState(false)

  const onClickScroll = () => {
    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    // })
    let scroll2023top = document.getElementById('scroll2023top')
    if (scroll2023top) {
      scroll2023top.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const toggleAnimations = () => {
    let moving1 = document.getElementById('moving1')
    let moving2 = document.getElementById('moving2')
    let moving3 = document.getElementById('moving3')
    let moving4 = document.getElementById('moving4')
    let moving5 = document.getElementById('moving5')
    let moving6 = document.getElementById('moving6')
    let moving7 = document.getElementById('moving7')
    let moving8 = document.getElementById('moving8')
    let moving9 = document.getElementById('moving9')
    let moving10 = document.getElementById('moving10')
    let moving11 = document.getElementById('moving11')
    let moving12 = document.getElementById('moving12')
    let moving13 = document.getElementById('moving13')

    moving1.classList.toggle('moving1')
    moving2.classList.toggle('moving2')
    moving3.classList.toggle('moving3')
    moving4.classList.toggle('moving4')
    moving5.classList.toggle('moving5')
    moving6.classList.toggle('moving6')
    moving7.classList.toggle('moving7')
    moving8.classList.toggle('moving8')
    moving9.classList.toggle('moving9')
    moving10.classList.toggle('moving10')
    moving11.classList.toggle('moving11')
    moving12.classList.toggle('moving12')
    moving13.classList.toggle('moving13')

    setIsPaused(!isPaused)
    toggleMarqueeAnimation()
  }

  return (
    <Main
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        backgroundColor: '#C7C2E1',
        height: '40px',
        zIndex: 1001,
      }}
    >
      <link rel='stylesheet' href='/static/2023/css/transition-css.css' type='text/css' />
      <div className='left-layer'></div>
      <div className='right-layer'></div>
      <Link
        onClick={() => (window.location.href = '/2020/home')}
        to={{ pathname: '/2020/home' }}
        className='text-5xl inline-block mt-6 mb-20'
      >
        <div
          className={cn('w-36 sm:w-72')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            left: 0,
            height: '40px',
            backgroundColor: '#efd6d9',
            zIndex: '1002',
          }}
        >
          <p
            style={{
              position: 'relative',
              fontWeight: 900,
              fontFamily: 'sharp_grotesque',
              fontSize: '30px',
              lineHeight: 'normal',
              letterSpacing: '0.02em',
              background: 'transparent',
            }}
          >
            2020
          </p>
        </div>
      </Link>
      <Link
        onClick={() => (window.location.href = '/2021/home')}
        to={{ pathname: '/2021/home' }}
        className='text-5xl inline-block mt-6 mb-20'
      >
        <div
          className={cn('w-36 sm:w-72 left-36 sm:left-72')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            height: '40px',
            backgroundColor: '#5933ff',
            zIndex: '1002',
          }}
        >
          <p
            style={{
              fontWeight: 900,
              fontFamily: 'sharp_grotesque',
              fontSize: '30px',
              lineHeight: 'normal',
              letterSpacing: '0.02em',
              color: '#defc1c',
              background: 'transparent',
            }}
          >
            2021
          </p>
        </div>
      </Link>
      <Link to={{ pathname: '/2022/home' }} className='text-5xl inline-block mt-6 mb-20'>
        <div
          className={cn('w-36 sm:w-72 left-72 sm:left-144')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            height: '40px',
            backgroundColor: '#ff6044',
            zIndex: '1002',
          }}
        >
          <p
            style={{
              fontWeight: 900,
              fontFamily: 'sharp_grotesque',
              fontSize: '30px',
              lineHeight: 'normal',
              letterSpacing: '0.02em',
              color: '#191919',
              background: 'transparent',
            }}
          >
            2022
          </p>
        </div>
      </Link>
      <Link to={{ pathname: '/2024/home' }} className='text-5xl inline-block mt-6 mb-20'>
        <div
          className={cn('w-36 sm:w-72 left-120 sm:left-192 bg-almost-black')}
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            height: '40px',
            zIndex: '1002',
          }}
        >
          <p
           className='text-comfort-green'
            style={{
                fontWeight: 900,
                fontFamily: 'sharp_grotesque',
                fontSize: '30px',
                lineHeight: 'normal',
                letterSpacing: '0.02em',
                background: 'transparent'
            }}
          >
            2024
          </p>
        </div>
      </Link>
      {pathname === '/2023/home' && (
        <button onClick={toggleAnimations} className='text-5xl inline-block mt-6 mb-20'>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bottom: 0,
              right: 40,
              height: '40px',
              width: '40px',
              background: '#C7C2E1',
              zIndex: '1002',
            }}
          >
            <img
              src={isPaused ? '/static/svg/2023/play.svg' : '/static/svg/2023/pause.svg'}
              alt='glass'
              style={{ background: '#C7C2E1' }}
            />
          </div>
        </button>
      )}

      <button onClick={onClickScroll} className='text-5xl inline-block mt-6 mb-20'>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            right: 0,
            height: '40px',
            width: '40px',
            background: '#C7C2E1',
            zIndex: '1002',
          }}
        >
          <img
            src='/static/svg/2023/back-to-top-42x42.svg'
            alt='glass'
            style={{ background: '#C7C2E1' }}
          />
        </div>
      </button>
    </Main>
  )
}
