import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import cn from 'classnames'
import upperFirst from 'lodash.upperfirst'
import { useDisclosure } from 'react-use-disclosure'
import { Link } from 'react-router-dom'
import { VscMenu } from 'react-icons/vsc'
import { default as UserProfile, ProfileSmall } from '../../../components/2021/Profile'
import Footer from '../../../components/2021/Footer'
import StickerNavs from '../StickerNavs'
import SearchCol from '../../../components/2021/SearchCol'
import { projectType } from '../../../constant'
import { MobileNavsBottom, MobileRightNavs } from '../MobileRightNavs'

const TextLogo = styled.h2`
  font-family: 'sharp_grotesque';
  font-size: 8.5rem;
  line-height: 9rem;
  letter-spacing: 0.01em;
  font-weight: 400;
`

const CurvedContainer = styled.div`
  background: #5933ff;
  display: inline-block;
  position: relative;
  width: 100%;

  height: 600px;
  vertical-align: middle;
  overflow: hidden;
`

const WorksContainer = styled.div`
  svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .curve-container {
    background: #5933ff;
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 15%;
    vertical-align: middle;
    overflow: hidden;
  }
  a {
    color: #fff;
  }
`
const StudentName = styled.h3`
  font-family: 'reckless_light';
`

export default function Profile(props) {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { id } = qs.parse(search)
  // console.log('🚀 ~ Profile ~ id', id)

  useEffect(() => {
    const leftLayer = document.getElementsByClassName('left-layer')?.[0]
    const rightLayer = document.getElementsByClassName('right-layer')?.[0]
    if (leftLayer && rightLayer) {
      let topWidth = window.innerWidth
      if (window.innerWidth < 600) {
        topWidth = 5840
      }
      leftLayer.style.borderTopWidth = `${topWidth}px`
      leftLayer.style.borderRightWidth = `${window.innerWidth / 3}px`
      rightLayer.style.borderBottomWidth = `${window.innerWidth}px`
      rightLayer.style.borderRightWidth = `${(window.innerWidth * 3) / 4}px`
      rightLayer.style.zIndex = 0
    }
  }, [])

  const navigate = useCallback(
    (url) => {
      if (pathname?.startsWith(url)) {
        return
      }

      const leftLayer = document.getElementsByClassName('left-layer')?.[0]
      const rightLayer = document.getElementsByClassName('left-layer')?.[0]
      if (leftLayer && rightLayer) {
        leftLayer.classList.add('active')
        rightLayer.classList.add('active')
        setTimeout(() => history.push(url), 1500)
      }
    },
    [history, pathname],
  )

  const { data: studentData } = useQuery(SINGLE_USER_QUERY, {
    variables: {
      id,
    },
  })

  const { data: studentProjectData } = useQuery(STUDENT_PROJECTS_QUERY, {
    variables: {
      id,
    },
  })

  const userId = props.id
  const user = studentData?.student || {}
  // console.log('🚀 ~ Profile ~ user', user)
  const projects = useMemo(
    () =>
      (studentProjectData?.studentProjects || [])
        .map((prj) => {
          const { orderList } = prj
          const meOrdersList = orderList.find((ord) => ord.userId === userId)
          return { ...prj, order: meOrdersList ? meOrdersList.order : 0 }
        })
        .sort((a, b) => a.order - b.order),
    [studentProjectData, userId],
  )

  const { isOpen, toggle } = useDisclosure()
  const { isOpen: isOpenMenuNavs, toggle: toggleMobileNavs } = useDisclosure()

  return (
    <>
      <div className='flex min-h-screen mt-28 lg:mt-0'>
        <div className='w-96 bg-blue hidden lg:block'>
        <Link to='/'>
          <TextLogo
            className='text-center px-6 text-secondary cursor-pointer uppercase'
            onClick={() => navigate('/2021/')}
          >
            Yes!2021
          </TextLogo>
          </Link>
          <div
            className='border-t border-b border-secondary border-dashed my-8 text-center text-secondary hover:bg-secondary hover:text-blue transition cursor-pointer'
            onClick={toggle}
          >
            <VscMenu className='text-5xl text-center mx-auto my-4' />
          </div>
          {!isOpen ? (
            <UserProfile user={user} />
          ) : (
            <StickerNavs withTop={false} className={cn('mt-16')} />
          )}
        </div>
        <div className={cn('w-full text-white lg:flex', {
          'bg-dark': !isOpenMenuNavs,
          'bg-blue': isOpenMenuNavs
        })}>
          <MobileRightNavs isOpenMenuNavs={isOpenMenuNavs} toggleMobileNavs={toggleMobileNavs} className='bg-blue' />
          <WorksContainer className={cn('w-full mx-auto mt-8 transition', {
            'flex visible opacity-100': !isOpenMenuNavs,
            'hidden invisible opacity-0': isOpenMenuNavs,
          })}>
          {
             (window.innerWidth < 600 && !isOpenMenuNavs)  ?  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 320" preserveAspectRatio="xMidYMin slice"
            style={{position: 'relative', top: '3px',width: '100%', paddingBottom: '20%', height: '50px',overflow: 'visible', marginTop:'-30px', zIndex:6, backgroundColor:"#333"}}><path fill="#5933FF" fillOpacity="1" d="M0,64L120,69.3C240,75,480,85,720,122.7C960,160,1200,224,1320,256L1440,288L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z" ></path></svg>: null
          }
            {/* Avatar small screen */}
            <img src={user?.image} className='w-64 h-64 rounded-full object-cover mx-auto pt-4' alt='user-avatar' style={{ filter: 'grayscale(100%)' }} />
            <StudentName className='text-6xl lg:text-7xl text-center my-8 lg:my-16'>
              {user?.firstName} {user?.lastName}
            </StudentName>
            {/* Profile small screen */}
            <div className='px-8 py-4 lg:hidden'>
              <ProfileSmall user={user} />
            </div>
            {projects.map((project) => (
              <div key={project.id} className='px-8 md:px-24 lg:px-96 mt-8 flex flex-col'>
                <img src={project.coverImage} alt={project.title} />
                <div className='flex flex-wrap py-4 lg:py-0'>
                  {['primaryProjectType', 'secondaryProjectType', 'secondaryProjectType_2'].map(
                    (key) =>
                      project[key] && (
                        <div key={key} className='flex items-center mr-12'>
                          <Link
                            to={{
                              pathname: '/2021/work',
                              search: `?type=${project[key]}`,
                            }}
                          >
                            <span className='w-3 h-3 bg-secondary rounded-full inline-block'></span>
                            <span className='break-normal pt-2 inline-block ml-2 border-b border-secondary border-dashed leading-relaxed hover:text-secondary transition'>
                              {projectType[project[key]] || upperFirst(project[key])}
                            </span>
                          </Link>
                        </div>
                      ),
                  )}
                </div>
                <Link
                  to={{ pathname: '/2021/project', search: `id=${project.id}` }}
                  className='text-5xl inline-block mt-6 mb-20 text-white hover:text-secondary transition'
                >
                  {project.title}
                </Link>
              </div>
            ))}
            {projects.length > 1 ? (
              <div className='curve-container'>
                <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
                  <path
                    d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                    style={{ keyStroke: 'none', fill: '#333' }}
                  ></path>
                </svg>
              </div>
            ) : (
              <CurvedContainer>
                <svg viewBox='0 0 500 500' preserveAspectRatio='xMinYMin meet'>
                  <path
                    d='M0,50 C150,100 350,0 500,10 L500,00 L0,0 Z'
                    style={{ keyStroke: 'none', fill: '#333' }}
                  ></path>
                </svg>
              </CurvedContainer>
            )}
          </WorksContainer>
          <MobileNavsBottom isOpenMenuNavs={isOpenMenuNavs} />
          <div className='w-12 bg-blue hidden lg:block'></div>
        </div>
      </div>
      <Footer className='pt-16' />
      <SearchCol className='hidden lg:block' />
    </>
  )
}

const SINGLE_USER_QUERY = gql`
  query SINGLE_USER_QUERY($id: ID!) {
    student(where: { id: $id }) {
      id
      email
      firstName
      lastName
      tagline
      program
      contactEmail
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
    }
  }
`

const STUDENT_PROJECTS_QUERY = gql`
  query STUDENT_PROJECTS_QUERY($id: ID!) {
    studentProjects(where: { id: $id }) {
      id
      title
      primaryProjectType
      secondaryProjectType
      secondaryProjectType_2
      contentType
      contentData
      coverImage
      orderList {
        userId
        order
      }
    }
  }
`
