import React, { useState, useCallback, useEffect } from 'react'
import Move from '../../components/2020/Move'
import ScrollBox from '../../components/2020/Scrollbox'
import styled from 'styled-components'
import UnMove from '../../components/2020/UnMove'
import { useNavContext } from '../../components/2020/states/nav'
import { useHistory } from 'react-router-dom'
import Confetti from '../../components/2020/Confetti'

const Main = styled.div`
  position: relative;
  max-width: 100%;
  overflow: visible;
  height: auto;
`

const MainContainer = styled.div`
  padding: 10vh;
  min-height: 100vh; // Ensure minimum viewport height
  overflow: visible; // Explicitly allow overflow
  @media (max-width: 768px) {
    padding: 5vh;
  }
`

const Content = styled.div`
  font-family: 'grotesque_pro_light';
  position: relative; // Ensure proper stacking context
  z-index: 2000;

  p {
    font-size: 20px;
    max-width: 600px; // Restrict text width
    margin: 0 auto 2rem; // Add vertical spacing
  }

  h1 {
    font-size: 24px;
    font-family: 'reckless_light';
    margin: 2rem auto;
    max-width: 600px;
  }
`

const randDirX1 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX2 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX3 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX4 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX5 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX6 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX7 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX8 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX9 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX10 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX11 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX12 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX13 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirX14 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
// const randDirX15 = Math.floor(Math.random() * 2) === 1 ? 1 : -1;
const randDirX16 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY1 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY2 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY3 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY4 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY5 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY6 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY7 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY8 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY9 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY10 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY11 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY12 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY13 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
const randDirY14 = Math.floor(Math.random() * 2) === 1 ? 1 : -1
// const randDirY15 = Math.floor(Math.random() * 2) === 1 ? 1 : -1;
const randDirY16 = Math.floor(Math.random() * 2) === 1 ? 1 : -1

const styles = {
  paddingTop: '30vh',
  margin: '10vh auto 0',
  maxWidth: '600px',
  position: 'relative',
  fontFamily: 'grotesque_pro_light',
  zIndex: 2000,
}
const ContentWrapper = styled.div`
  padding-top: 30vh;
  margin: 10vh auto 0;
  max-width: 600px;
  position: relative;
  font-family: 'grotesque_pro_light';
`

const leadStyles = {
  fontFamily: 'grotesque_pro_light',
  fontSize: '3rem',
  lineHeight: '1.2',
}
const yesStyles = {
  width: '20vw',
  maxWidth: '192px',
  minWidth: '48px',
  marginRight: '.25em',
}

const Index = () => {
  const history = useHistory()
  const { dispatch } = useNavContext()
  const [state, setState] = useState({ x: 0, y: 0 })
  const [showConfetti, setShowConfetti] = useState(false)

  const onMouseMove = useCallback((e) => {
    setState({ x: e.nativeEvent.x, y: e.nativeEvent.y })
  }, [])

  useEffect(() => {
    dispatch({ type: 'SET_PREV_PATH', payload: '/' })
    if (history.location.state && history.location.state.fromShow) {
      console.log('show confetti')
      setShowConfetti(true)
    }
    /* eslint-disable-next-line */
  }, [])

  const { x, y } = state

  return (
    <MainContainer onMouseMove={onMouseMove}>
      {showConfetti && <Confetti />}
      <ScrollBox y={y} />
      <Main>
        <Move
          x={x}
          y={y}
          posx={2.8}
          posy={-62.6}
          rotate={320}
          directionX={randDirX1}
          directionY={randDirY1}
          logoSrc={'/marks/2x/Mark6.png'}
        />
        <Move
          x={x}
          y={y}
          posx={21.38}
          posy={-66.25}
          rotate={200}
          directionX={randDirX2}
          directionY={randDirY2}
          logoSrc={'/marks/2x/Mark4.png'}
        />
        <Move
          x={x}
          y={y}
          posx={40.69}
          posy={-63}
          rotate={20}
          directionX={randDirX3}
          directionY={randDirY3}
          logoSrc={'/marks/2x/Mark3.png'}
        />
        <Move
          x={x}
          y={y}
          posx={54.72}
          posy={-67.26}
          rotate={150}
          directionX={randDirX4}
          directionY={randDirY4}
          logoSrc={'/marks/2x/Mark5.png'}
        />
        <Move
          x={x}
          y={y}
          posx={71.38}
          posy={-60.6}
          rotate={230}
          directionX={randDirX5}
          directionY={randDirY5}
          logoSrc={'/marks/2x/Mark1.png'}
        />
        <Move
          x={x}
          y={y}
          posx={-4}
          posy={-41.77}
          rotate={100}
          directionX={randDirX6}
          directionY={randDirY6}
          logoSrc={'/marks/2x/Mark2.png'}
        />
        <Move
          x={x}
          y={y}
          posx={77.72}
          posy={-43.92}
          rotate={290}
          directionX={randDirX7}
          directionY={randDirY7}
          logoSrc={'/marks/2x/Mark2.png'}
        />
        <Move
          x={x}
          y={y}
          posx={-5.83}
          posy={-18.39}
          rotate={30}
          directionX={randDirX8}
          directionY={randDirY8}
          logoSrc={'/marks/2x/Mark4.png'}
        />
        <Move
          x={x}
          y={y}
          posx={14.65}
          posy={-25.17}
          rotate={180}
          directionX={randDirX9}
          directionY={randDirY9}
          logoSrc={'/marks/2x/Mark7.png'}
        />
        <Move
          x={x}
          y={y}
          posx={44.72}
          posy={-15.03}
          rotate={330}
          directionX={randDirX10}
          directionY={randDirY10}
          logoSrc={'/marks/2x/Mark5.png'}
        />
        <Move
          x={x}
          y={y}
          posx={59.63}
          posy={-29.65}
          rotate={250}
          directionX={randDirX11}
          directionY={randDirY11}
          logoSrc={'/marks/2x/Mark7.png'}
        />
        <Move
          x={x}
          y={y}
          posx={9}
          posy={11.01}
          rotate={230}
          directionX={randDirX12}
          directionY={randDirY12}
          logoSrc={'/marks/2x/Mark6.png'}
        />
        <Move
          x={x}
          y={y}
          posx={28.88}
          posy={2.87}
          rotate={200}
          directionX={randDirX13}
          directionY={randDirY13}
          logoSrc={'/marks/2x/Mark4.png'}
        />
        <Move
          x={x}
          y={y}
          posx={70.27}
          posy={-11.49}
          rotate={29}
          directionX={randDirX14}
          directionY={randDirY14}
          logoSrc={'/marks/2x/Mark3.png'}
        />
        {/* <Move
          x={x}
          y={y}
          posx={65.13}
          posy={-10.7}
          rotate={53}
          directionX={randDirX15}
          directionY={randDirY15}
          logoSrc={"/marks/2x/Mark1.png"}
        /> */}
        <Move
          x={x}
          y={y}
          posx={61.5}
          posy={10.85}
          rotate={120}
          directionX={randDirX16}
          directionY={randDirY16}
          logoSrc={'/marks/2x/Mark2.png'}
        />
        <UnMove posx={13.88} posy={28.87} rotate={350} logoSrc={'/static/4_RGB.png'} />
        <ContentWrapper>
          <Content id='show' name='inTroduce'>
            <p style={leadStyles}>
              <img src={'/static/yes_narrow.svg'} alt='YES!' style={yesStyles} />
              Celebrates and showcases the talent of ten diverse programs at George Brown College's
              School of Design.
            </p>
            <br />
            <br />
            <h1>2020 Year End Show</h1>
            <p>
              This spring, nearly 500 students will graduate from the School of Design. Each has
              beneﬁtted from the wisdom and support of faculty, peers, staff, industry partners and
              the much broader School of Design community. YES! is a testament to their
              collaboration. Yes! We’re designers. Yes! We’re future-ready. Yes! We’re the School of
              Design!
            </p>
            <br />
            <br />
            <p>
              Sadly, this year’s show was suspended as a result of the COVID-19 pandemic. As our
              community can attest, the show is a massive culminating event that celebrates all of
              the incredible work created by our students throughout the year. The two day event
              typically garners more than 1,000 attendees including students, faculty, families,
              friends and industry professionals. Given the provisions that have been outlined with
              regards to risks associated with holding large public gatherings, we felt that there
              was no other option but to cancel the event— our priority has always been and will
              continue to be the safety and well-being of our community.
            </p>
            <br />
            <br />
            <p>
              This website, while certainly not a replication of the vivacity of the event, helps
              share the work of our talented and hard-working graduates. Yes! We’re resilient. Yes!
              We’re going to get through this. Yes! We’re still going to show you what we’ve been up
              to— no matter what.
            </p>
            <br />
            <br />
            <h1>YES! Branding</h1>
            <p>
              YES!— a shorthand for Year End Show— was developed by Toronto-based Underline Studio.
              Underline's vision was to use language to reﬂect the diversity, dynamism and energy of
              the talented student body across the School of Design. As a long-term brand, YES! will
              provide a more sustainable and recognizable identity to the show. Inspired by
              Underline's work, the graduating students have created a website to reﬂect the theme.
            </p>
            {/* <h1>Contact Details</h1>
        <p>
          School of Design<br />
          George Brown College<br />
          3 Lower Jarvis St.<br />
          Toronto, ON<br />
        </p>
        <br />
        <a href="mailto:design@georgebrown.ca">design@georgebrown.ca</a><br/>
        <a href="https://www.instagram.com/designgbc/">@designGBC</a> */}
          </Content>
        </ContentWrapper>
      </Main>
    </MainContainer>
  )
}

export default Index
