import React, { useContext, useEffect } from 'react'
import { useViewport } from '../../../hook/useViewport'
import { TextCenterDiv } from '../../../styles/2024/GradsStyles'
import { gradComments } from '../../../constant'
import { SpringContext } from '../../../context/SpringContext'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../../context/2025/ThemeContext'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const SlickSlider = React.lazy(() => import('../../../components/2025/SlickSlider'))

export default function Index() {
  const { width } = useViewport()
  const spring = useContext(SpringContext)
  const isMobile = width < 1024
  const { isDarkMode } = useContext(ThemeContext)

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const backgroundColors = {
    header: isDarkMode ? 'bg-black' : 'bg-white',
    campus: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    grads: isDarkMode ? 'bg-black' : 'bg-white',
    testimonials: isDarkMode ? 'bg-dark-grey' : 'bg-light-grey-25',
    footer: isDarkMode ? 'bg-black' : 'bg-light-grey-25',
  }

  const textColors = {
    heading: isDarkMode ? 'text-lime' : 'text-lavender',
    subheading: isDarkMode ? 'text-white' : 'text-black',
    dark: isDarkMode ? 'text-white' : 'text-black',
    light: isDarkMode ? 'text-white' : 'text-black',
    accent: isDarkMode ? 'text-lime' : 'text-lavender',
  }

  return (
    <div
      className={`absolute h-full w-full ${backgroundColors.header} flex flex-col items-center justify-start pb-20 lg:pb-0`}
    >
      {Header && <Header />}

      <section
        className={`w-full ${
          isDarkMode ? 'bg-black' : 'bg-white'
        } transition-colors duration-300 flex-1`}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-center gap-32'>
          <TextCenterDiv className='px-[16px] lg:px-[48px]'>
            <h1
              className={`${isMobile ? 'heading-1-mobile' : 'heading-1'} ${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } `}
              style={{ pointerEvents: 'none' }}
            >
              Award
            </h1>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <h3
                  className={`heading-4 ${textColors.subheading} normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  Let's send our encouragement
                </h3>
                <h3
                  className={`heading-4 ${textColors.subheading} normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  to this year's winners.
                </h3>
              </div>
            ) : (
              <h3
                className={`heading-4 ${textColors.subheading} normal-case`}
                style={{ pointerEvents: 'none' }}
              >
                Let's send our encouragement to this year's winners.
              </h3>
            )}
            <h3
              className={`heading-4 ${textColors.subheading} normal-case`}
              style={{ pointerEvents: 'none' }}
            >
              May 2, 2025
            </h3>
          </TextCenterDiv>
        </div>
      </section>

      <div
        className={`relative w-full ${backgroundColors.footer} flex flex-col items-center justify-center w-full`}
      >
        <div className='relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] max-w-[1440px]'>
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
