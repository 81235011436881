import React from 'react'
import { Route } from 'react-router-dom'

import { Home as Home25 } from '../pages/2025'
import Work from '../pages/2025/Work'
import Grads from '../pages/2025/Grads'
import Events from '../pages/2025/Events'
import About from '../pages/2025/About'
import Letterbox from '../pages/2025/Letterbox'
import Search from '../pages/2025/Search'
import Teaser from '../pages/2025/Teaser'
import Profile from '../pages/2025/Profile'
import Project from '../pages/2025/Project'
import Award from '../pages/2025/Award'

export const getRoutes2025 = (getScrollTop) => [
  <Route
    key={'Index25'}
    exact
    path='/'
    render={(props) => <Home25 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Home25'}
    exact
    path='/home'
    render={(props) => <Home25 {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Work25'}
    exact
    path='/work'
    render={(props) => <Work {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Grads25'}
    exact
    path='/grads'
    render={(props) => <Grads {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Events25'}
    exact
    path='/events'
    render={(props) => <Events {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'About25'}
    exact
    path='/about'
    render={(props) => <About {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Letterbox'}
    exact
    path='/letterbox'
    render={(props) => <Letterbox {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Search'}
    exact
    path='/search'
    render={(props) => <Search {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key={'Teaser25'}
    exact
    path='/teaser'
    render={(props) => <Teaser {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='student'
    exact
    path='/student'
    render={(props) => <Profile {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='project'
    exact
    path='/project'
    render={(props) => <Project {...props} getScrollTop={getScrollTop} />}
  />,
  <Route
    key='award'
    exact
    path='/award'
    render={(props) => <Award {...props} getScrollTop={getScrollTop} />}
  />,
]
