import * as React from 'react'
import { useContext } from 'react'
import { LengthLimitTextarea } from '../CMS/styles/InputLimit'
import { gql, useMutation } from '@apollo/client'
import { QUERY } from '../../graphql'
import cogoToast from 'cogo-toast'
import { ThemeContext } from '../../context/2025/ThemeContext'

const InputField = ({ label, placeholder, handleChange, sVar, sFunc, isDarkMode }) => {
  return (
    <div className='flex flex-col w-full'>
      <label htmlFor={label} className="text-black mb-2">
        {label}
        <input
          type='text'
          id={label}
          name={label}
          placeholder={placeholder}
          className={`justify-center items-start px-6 py-4 bg-white border border-solid ${isDarkMode ? 'border-lime' : 'border-lavender'} rounded-[10px] max-md:px-5 focus:outline-none focus:ring-2 ${isDarkMode ? 'focus:ring-lime' : 'focus:ring-lavender'} focus:border-transparent placeholder-opacity-50 w-full transition-colors duration-300`}
          value={sVar}
          onChange={(e) => handleChange(e, sFunc)}
        />
      </label>
    </div>
  )
}

const LetterForm = () => {
  const [sender, setSender] = React.useState('')
  const [recipient, setRecipient] = React.useState('')
  const [message, setMessage] = React.useState('')
  const { isDarkMode } = useContext(ThemeContext)

  const [createLetter, { loading: createLetterLoading, error: createLetterError }] = useMutation(
    CREATE_LETER_MUTATION,
    {
      update: (cache, { data: { createLetter } }) => {
        // Read the current state of the query from the cache
        const existingLetters = cache.readQuery({
          query: QUERY.LETTERS_QUERY,
          variables: {
            where: {
              createdAt_gt: '2024-12-31',
            },
          },
        })

        // Write the updated data back to the cache
        cache.writeQuery({
          query: QUERY.LETTERS_QUERY,
          variables: {
            where: {
              createdAt_gt: '2024-12-31',
            },
          },
          data: {
            letters: [...existingLetters.letters, createLetter],
          },
        })
      },
    },
  )

  const handlePublish = async (e) => {
    e.preventDefault()
    var Filter = require('bad-words-jd'),
      filter = new Filter()

    const variables = {
      sender,
      recipient,
      message,
    }

    if (filter.isProfane(variables.message)) {
      cogoToast.error('Message contains inappropriate language')
      return
    }
    if (filter.isProfane(variables.sender)) {
      cogoToast.error('Sender name contains inappropriate language')
      return
    }
    if (filter.isProfane(variables.recipient)) {
      cogoToast.error('Recipient name contains inappropriate language')
      return
    }
    if (!variables.sender || variables.sender === '') {
      cogoToast.error('Sender is required')
      return
    }
    if (!variables.recipient || variables.recipient === '') {
      cogoToast.error('Receiver is required')
      return
    }
    if (!variables.message || variables.message === '') {
      cogoToast.error('Message is required')
      return
    }

    const res = await createLetter({ variables }).then((res) => {
      if (res.data.createLetter) {
        cogoToast.success('Message sent successfully!')
        setRecipient('')
        setSender('')
        setMessage('')
      }
    })

    return res
  }

  const handleChange = (e, setter) => {
    const { value } = e.target
    setter(value)
  }

  const formBgColor = isDarkMode ? 'bg-lavender' : 'bg-lavender'
  const buttonBgColor = isDarkMode ? 'bg-black' : 'bg-black'
  const buttonTextColor = isDarkMode ? 'text-white' : 'text-white'

  return (
    <form
      className={`flex flex-col justify-center p-8 ${formBgColor} rounded-[10px] body-text max-w-[656px] text-black max-md:px-5 shadow-md transition-colors duration-300`}
      onSubmit={(e) => handlePublish(e)}
    >
      <h2 className='uppercase heading-2 mb-6 text-white'>WRITE A LETTER</h2>
      <div className='flex gap-4 mt-4 max-md:flex-wrap'>
        <InputField
          id='sender'
          key='sender'
          label='Sender'
          placeholder='Your Name'
          sVar={sender}
          sFunc={setSender}
          handleChange={handleChange}
          isDarkMode={isDarkMode}
        />
        <InputField
          id='recipient'
          key='recipient'
          label='Recipient'
          placeholder='Student Name'
          sVar={recipient}
          sFunc={setRecipient}
          handleChange={handleChange}
          isDarkMode={isDarkMode}
        />
      </div>
      <label htmlFor='message' className='w-full pt-4 text-black '>
        Message:
        <LengthLimitTextarea
          type='text'
          id='message'
          name='message'
          placeholder='Please send a supportive message to School of Design students who have worked hard throughout their academic journey.'
          maxLength={200}
          className={`w-full px-6 py-4 bg-white border border-solid ${isDarkMode ? 'border-lime' : 'border-lavender'} rounded-[8px] max-md:px-5 focus:outline-none focus:ring-2 ${isDarkMode ? 'focus:ring-lime' : 'focus:ring-lavender'} h-[180px] focus:border-transparent placeholder-opacity-50 transition-colors duration-300`}
          value={message}
          onChange={(e) => handleChange(e, setMessage)}
          fromtop={14}
          color='#181818'
        />
      </label>
      <button
        type='submit'
        className={`justify-center items-center px-16 py-4 mt-8 heading-4 ${buttonTextColor} ${buttonBgColor} rounded-[48px] max-md:px-5 max-md:max-w-full hover:opacity-90 transition-all duration-300 w-1/2`}
      >
        SEND MESSAGE
      </button>
    </form>
  )
}

export default LetterForm

const CREATE_LETER_MUTATION = gql`
  mutation CREATE_LETER_MUTATION($sender: String!, $recipient: String!, $message: String!) {
    createLetter(sender: $sender, recipient: $recipient, message: $message) {
      sender
      recipient
      message
    }
  }
`