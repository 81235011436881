import React, { Component } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { SpringSystem } from 'rebound'
import { SpringContext } from '../../context/SpringContext'
import { ThemeProvider } from '../../context/2025/ThemeContext'
import '../../styles/2025/ThemeStyles.css'
import '../../styles/2025/Fonts.css' // Import Fonts.css here

export default class ColoredScrollbars2025 extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.scrollbars = React.createRef()
    this.state = { top: 0, spring: null }
    this.handleUpdate = this.handleUpdate.bind(this)
    this.renderView = this.renderView.bind(this)
    this.renderThumb = this.renderThumb.bind(this)
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this)
  }

  componentDidMount() {
    // Add a class to the document body to scope our styles
    document.body.classList.add('theme-active')

    this.springSystem = new SpringSystem()
    this.spring = this.springSystem.createSpring()
    this.setState({ spring: this.spring })
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate })
  }

  componentWillUnmount() {
    // Remove the class when component unmounts to prevent style leaking
    document.body.classList.remove('theme-active')

    if (this.springSystem && this.spring) {
      this.springSystem.deregisterSpring(this.spring)
      this.spring.removeAllListeners()
      this.spring.destroy()
    }
    this.springSystem = undefined
    this.spring = undefined
  }

  componentDidUpdate(prevProps) {
    // console.log('Scroll update:', prevProps)
    if (prevProps.scrollTop !== this.props.scrollTop) {
      this.scrollTop(this.props.scrollTop)
    }
  }
  // Handle scroll updates
  // This is where you can handle scroll events if needed
  // For example, you can use this to trigger animations or other effects

  handleUpdate(values) {
    // console.log('Scroll update:', values);

    // Calculate scroll ratio (0 to 1)
    const scrollRatio = values.scrollTop / (values.scrollHeight - values.clientHeight);

    // Dispatch a custom event with scroll data
    const event = new CustomEvent('customScroll', {
      detail: {
        ...values,
        scrollRatio
      }
    });
    window.dispatchEvent(event);

    // Update spring if needed
    // if (this.spring) {
    //   this.spring.setCurrentValue(scrollRatio).setAtRest();
    //   this.spring.setEndValue(scrollRatio);
    // }

    // Call any passed onUpdate prop
    // if (this.props.onUpdate) {
    //   this.props.onUpdate(values);
    // }
  }

  renderView({ style, ...props }) {
    const viewStyle = {
      padding: 0,
      color: `#26439B`,
      position: 'relative',
      overflowX: 'hidden',
      top: 0,
      left: 0,
      marginRight: '0px',
      marginBottom: '0px',
      backgroundColor: `#000000`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
    return (
      <div
        className='theme-container min-safe-h-screen overflow-hidden w-screen lg:w-full bg-almost-black'
        style={{ ...style, ...viewStyle }}
        {...props}
      />
    )
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      position: 'absolute',
      right: window.innerWidth < 1000 ? '-2px' : '0px',
      bottom: 0,
      top: 0,
      borderRadius: '3px',
      zIndex: 1001,
      width: '8px',
      backgroundColor: `var(--theme-scrollbar-thumb)`,
      marginTop: 0,
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  getScrollTop() {
    return this.scrollbars.current.getScrollTop()
  }

  getScrollHeight() {
    return this.scrollbars.current.getScrollHeight()
  }

  getHeight() {
    return this.scrollbars.current.getHeight()
  }

  scrollTop(top) {
    if (!this.scrollbars.current) return;

    // Get current scroll dimensions
    const scrollHeight = this.scrollbars.current.getScrollHeight();
    const clientHeight = this.scrollbars.current.getHeight();

    // Calculate scroll ratio (0 to 1)
    const scrollRatio = top / (scrollHeight - clientHeight);

    // Update spring with the new position
    if (this.spring) {
      this.spring.setCurrentValue(scrollRatio).setAtRest();
      this.spring.setEndValue(scrollRatio);
    }

    // Actually scroll to the position
    this.scrollbars.current.scrollTop(top);
  }
  handleSpringUpdate(spring) {
    console.log('Spring update:', spring.getCurrentValue())
    if (this.spring && this.scrollbars.current) {
      const val = spring.getCurrentValue()
      this.scrollbars.current.scrollTop(val)
    }
  }

  render() {
    return (
      <ThemeProvider>
        <SpringContext.Provider value={this.state.spring}>
          <Scrollbars
            renderView={this.renderView}
            renderThumbVertical={this.renderThumb}
            autoHide={true}
            {...this.props}
            onScroll={this.props.onScroll}
            onUpdate={this.handleUpdate}
            ref={this.scrollbars}
            className="theme-scrollbars" // Add a class to further scope styling if needed
          />
        </SpringContext.Provider>
      </ThemeProvider>
    )
  }
}