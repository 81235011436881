import React, { useCallback, useEffect, useMemo, useState, useContext, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import qs from 'query-string'
import shuffle from 'lodash.shuffle'
import { useDebounce } from 'use-debounce'
import { QUERY } from '../../../graphql'
import { InputSearch } from './InputSearch'
import { GradCard } from '../../../components/2025/GradCard'
import { ProjectCard } from '../../../components/2025/ProjectCard'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { SpringContext } from '../../../context/SpringContext'
import { ThemeContext } from '../../../context/2025/ThemeContext'
import { useViewport } from '../../../hook/useViewport'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const FullScreenLoader = React.lazy(() => import('../../../components/FullScreenLoader'))

export const TextCenterDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 120px 0 0px 0;
`

export default function Search(props) {
  const history = useHistory()
  const location = useLocation()
  const { search: searchParams } = location
  const { keyword } = qs.parse(searchParams)
  const [searchInput, setSearchInput] = useState(keyword || '')
  const [searchInputDebounce] = useDebounce(searchInput, 800)
  const spring = useContext(SpringContext)
  const { isDarkMode } = useContext(ThemeContext)
  const inputRef = useRef(null)
  const { width } = useViewport()
  const isMobile = width < 1024

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(-1000).setAtRest()
      spring.setEndValue(-1000)
    }
  }, [spring])

  const [fetchSearch, { data: searchData, loading }] = useLazyQuery(QUERY.SEARCH_QUERY, {
    variables: {
      q: searchInputDebounce,
      year: 2025,
    },
  })

  const onInputChange = useCallback((e) => {
    const val = e.target.value
    setSearchInput(val)
  }, [])

  const onSearchClick = () => {
    const val = searchInput
    setSearchInput(val)
    if (val && val.length > 0) {
      fetchSearch()
    }
  }

  useEffect(() => {
    if (!searchInputDebounce || searchInputDebounce?.length === 0) {
      history.replace('/search')
      return
    }
    history.push({ search: `?keyword=${searchInputDebounce}` })
  }, [searchInputDebounce, history])

  useEffect(() => {
    // Only search if there's a valid query
    if (searchInputDebounce && searchInputDebounce.trim().length > 0) {
      fetchSearch()
    }
  }, [keyword, fetchSearch, searchInputDebounce])

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  useEffect(() => {
    if (!loading && inputRef.current) {
      // Double RAF to ensure all DOM updates complete
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          inputRef.current.focus({ preventScroll: true })
        })
      })
    }
  }, [loading])

  const results = useMemo(() => {
    // Return empty array if no search query
    if (!searchInputDebounce || searchInputDebounce.trim().length === 0) return []

    const users = searchData?.searchGradWork?.users || []
    const projects = searchData?.searchGradWork?.projects || []
    return shuffle([...users, ...projects])
  }, [searchData, searchInputDebounce])

  const backgroundColors = {
    header: isDarkMode ? 'bg-black' : 'bg-white',
    content: isDarkMode ? 'bg-[#222222]' : 'bg-black',
    footer: isDarkMode ? 'bg-black' : 'bg-black',
  }

  const textColors = {
    heading: isDarkMode ? 'text-white' : 'text-lavender',
    subheading: isDarkMode ? 'text-white' : 'text-black',
    body: isDarkMode ? 'text-white' : 'text-black',
  }

  require('./styles.scss')

  return (
    <div
      className={`absolute w-full min-h-full bg-black flex flex-col items-center justify-start pb-20 lg:pb-0  ${
        isDarkMode ? 'bg-black' : 'bg-white'
      } transition-colors duration-300 `}
    >
      {loading && (
        <FullScreenLoader
          color={isDarkMode ? `#DAFF01` : `#9167F0`}
          style={{ position: 'fixed' }}
        />
      )}
      {Header && <Header />}

      <section
        className={`w-full ${
          isDarkMode ? 'bg-black' : 'bg-white'
        } transition-colors duration-300 flex-1`}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-center gap-8'>
          <TextCenterDiv className=' px-[16px] lg:px-[48px]'>
            <h1
              className={`${isMobile ? 'heading-1-mobile' : 'heading-1'} ${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } `}
              style={{ pointerEvents: 'none' }}
            >
              Search
            </h1>
          </TextCenterDiv>
          <div className='text-center relative my-8 w-full lg:w-2/3'>
            <InputSearch
              ref={inputRef}
              type='text'
              id='search'
              name='search'
              placeholder='Search the website'
              className='w-full'
              onChange={onInputChange}
              onSearchClick={onSearchClick}
              value={searchInput}
              isDarkMode={isDarkMode}
            />
          </div>
          {!searchInputDebounce && (
            <h4 className={`${textColors.body} relative text-center my-8`}>
              Please enter a student name or project name to search
            </h4>
          )}

          {/* Existing no results message */}
          {results && results.length === 0 && searchInputDebounce && (
            <h4 className={`${textColors.body} relative text-center my-8`}>
              No results found, let's try again!
            </h4>
          )}
        </div>
      </section>

      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-grey-25'
        } transition-colors duration-300 `}
      >
        <div
          className={`max-w-[1440px] mx-auto px-6 lg:px-[48px] ${
            results && results.length === 0 ? 'py-0' : 'py-16'
          }  flex flex-col items-center justify-between gap-16`}
        >
          {results && results.length > 0 && results.length === 1 ? (
            <p className='body-text text-white w-full text-left !mt-16 px-[8px]'>
              There is a <span className='text-lime'>single</span> result.
            </p>
          ) : (
            results.length > 0 && (
              <p className='body-text text-white w-full text-left !mt-16 px-[8px]'>
                There are <span className='text-lime'>{results?.length}</span> results.
              </p>
            )
          )}

          <TransitionGroup
            className={`grid grid-cols-1 lg:grid-cols-3 gap-16 px-[16px]  lg:px-[8px]  ${
              results && results.length === 0 ? 'py-0' : 'pt-2 pb-16'
            }${isDarkMode ? 'bg-[#222222]' : 'bg-grey-25'}`}
          >
            {results &&
              results.length > 0 &&
              results.map((result) => (
                <CSSTransition key={result.id} timeout={300} classNames='item'>
                  <>
                    {result.__typename === 'User' && (
                      <GradCard grad={result} isDarkMode={isDarkMode} />
                    )}
                    {result.__typename === 'Project' && (
                      <ProjectCard project={result} isDarkMode={isDarkMode} />
                    )}
                  </>
                </CSSTransition>
              ))}
          </TransitionGroup>
        </div>
      </section>

      <div
        className={`relative w-full flex flex-col items-center justify-center w-full ${
          isDarkMode ? 'bg-black' : 'bg-light-grey-25'
        }`}
      >
        <div
          className={`relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-black max-w-[1440px] ${
            isDarkMode ? 'bg-black' : 'bg-light-grey-25'
          }`}
        >
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
