import React, {useRef, useEffect} from 'react'
import Lottie from 'lottie-react'

import loadingLottie from './Loading.json'


const hexToRgbNormalized = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) throw new Error('Invalid HEX color');

  return [
    parseInt(result[1], 16) / 255,
    parseInt(result[2], 16) / 255,
    parseInt(result[3], 16) / 255,
    1
  ];
};




export const LoadingLottie = ({ color = "#DAFF01" }) => {
  const lottieRef = useRef();

  // Create a deep copy of the original animation data to avoid mutation
  const modifiedAnimationData = JSON.parse(JSON.stringify(loadingLottie));

  // Convert the hex color to normalized RGB
  const targetColor = hexToRgbNormalized(color);

  // Modify the animation data
  modifiedAnimationData.layers.forEach((layer) => {
    layer.shapes.forEach((shape) => {
      shape.it.forEach((item) => {
        if (item.ty === "fl") { // 'fl' is the fill type
          item.c.k = targetColor;
        }
      });
    });
  });

  useEffect(() => {
    lottieRef.current.setSpeed(1.5);
  }, []);

  return <Lottie lottieRef={lottieRef} animationData={modifiedAnimationData} autoplay loop />;
};
