import styled from 'styled-components'

export const EmailSignUpCenter = styled.div`
  position: relative;
  form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    font-size: 2rem;
  }
  #email {
    width: calc(100vw - 40px);
    height: 60px;
    border-radius: 10px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;
    outline: 2px solid
      ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  #email:hover {
    outline: 4px solid
      ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
  }
  #email:focus {
    outline: 4px solid
      ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
  }

  #submit {
    width: calc(50vw - 40px);
    height: 60px;
    border-radius: 30px;
    cursor: pointer;
    display: ${(props) => (props.submitted ? 'none' : 'block')};
    transition: transform 0.3s;
  }
  #submit:hover {
    transform: scale(1.05);
  }
  #inline-error {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.hasError ? 'block' : 'none')};
  }
  #submitted {
    color: white;
    margin-top: 10px;
    display: ${(props) => (props.submitted ? 'block' : 'none')};
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }
    #submit {
      width: 240px;
    }
  }
`

export const MenuIcon = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s ease;
`

export const Bar = styled.div`
  height: 3px;
  width: 100%;
  transition: all 0.5s ease;
  border-radius: 3px;

  /* Fix: Change from ({ props }) to (props) */
  &:nth-child(1) {
    transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    transform-origin: top left;
  }

  &:nth-child(2) {
    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    transform: ${(props) => (props.isOpen ? 'translateX(-20px)' : 'translateX(0)')};
  }

  &:nth-child(3) {
    transform: ${(props) => (props.isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    transform-origin: bottom left;
  }
`

export const SearchContainer = styled.div`
    p {
      text-align: left;
      padding-left: 30px;
      color: #000000;
      font-size: 1.3em;
      position: absolute;
      line-height: 60px;
      margin-bottom: 0;
      width: 100%;
    }
    position: relative;

    img,
    svg {
      top: 36px;
      color: #000000;
      right: 22px;
      transition: transform 330ms;
    }

    input {
      font-size: 2rem;
      height: 60px;
      border-radius: 30px;
      padding: 5px 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      outline: 2px solid ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
      display: ${(props) => (props.submitted ? 'none' : 'block')};
    }

    input:hover {
      outline: 4px solid ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
    }
    input:focus {
      outline: 4px solid ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
    }

    @media (min-width: 640px) {
      #email {
        width: 480px;
      }
    }
  `