import React, { useEffect} from 'react'
import styled from 'styled-components'
import { useViewport } from '../../../hook/useViewport'
import { ThemeContext } from '../../../context/2025/ThemeContext'

const LogoContainer = styled.div`
  width: 100%;
  max-width: 351px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    max-width: 400px;
    margin: 0;
  }
`
const MainTitle = styled.h1`
  color: #e3ff2f;
  margin: 0;
  display: flex;
  align-items: center;
`

const DarkTeaser = () => {
  const { width } = useViewport()
  const isMobile = width < 1024
  const { setTheme } = React.useContext(ThemeContext)

  useEffect(() => {
    setTheme('dark')
  }, [setTheme])

  return (
    <div className={`w-full bg-[#000000] `}>
      <div className='h-[100vh] lg:max-w-[1440px]  flex flex-col lg:flex-row justify-between lg:gap-0 lg:mx-auto lg:items-center py-20 lg:py-0 px-16 box-border'>
        {/* SVG Container - will appear first on mobile, right on desktop */}
        <LogoContainer className='lg:order-2 pb-12 lg:pb-2  lg:max-h-[691px] '>
          <svg
            width={isMobile ? '144' : '351'}
            height={isMobile ? '288' : '691'}
            viewBox='0 0 351 691'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M175.515 0C298.788 0 351 48.3849 351 133.574C351 274.514 180.724 277.667 180.724 391.263C180.724 404.935 185.963 413.333 185.963 422.822C185.963 430.189 181.778 434.403 175.515 434.403C169.252 434.403 165.067 430.189 165.067 422.822C165.067 413.363 170.276 404.935 170.276 391.263C170.276 277.667 0 274.514 0 133.574C0 48.3849 52.2421 0 175.485 0H175.515ZM336.396 567.946C336.396 644.737 281.023 691 175.515 691C60.6129 691 14.6339 644.707 14.6339 567.946C14.6339 495.369 78.3481 452.259 175.515 452.259C272.682 452.259 336.396 495.369 336.396 567.946Z'
              fill='#DAFF01'
            />
          </svg>
        </LogoContainer>

        {/* Content Container - will appear second on mobile, left on desktop */}
        <div className='flex-1 lg:order-1 flex flex-col lg:h-[691px] lg:min-h-[691px] lg:max-h-[691px]'>
          {/* Top Content */}
          <div className='flex flex-col justify-start items-start gap-5 order-3 lg:order-1'>
            <div className='justify-start '>
              <span className='heading-4-bold text-white !text-[30px] '>
                May 1-2
                <br />
              </span>
              <span className='heading-3 text-white !text-[30px]'>
                George Brown College
                <br />
                School of Design
              </span>
            </div>
            <a
              className='heading-3 text-lime !text-[34px] hover:scale-[1.04] transition-transform duration-300 hover:text-lime'
              href='https://www.instagram.com/sod_yes/'
              target='_blank'
              rel='noreferrer'
            >
              @sod_yes
            </a>
          </div>
          {!isMobile && <div className='flex-grow order-2'></div>}

          {/* Bottom Content - pushed to bottom */}
          <div className='flex flex-col order-1 lg:order-3'>
            <MainTitle className={isMobile ? 'teaser-mb-h1 text-lime ' : 'teaser-h1 text-lime '}>
              YES
              <svg
                width='0.5em'
                height='0.9em'
                viewBox='0 0 351 691'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{ display: 'inline-block', marginTop: '0.03em' }}
              >
                <path
                  d='M175.515 0C298.788 0 351 48.3849 351 133.574C351 274.514 180.724 277.667 180.724 391.263C180.724 404.935 185.963 413.333 185.963 422.822C185.963 430.189 181.778 434.403 175.515 434.403C169.252 434.403 165.067 430.189 165.067 422.822C165.067 413.363 170.276 404.935 170.276 391.263C170.276 277.667 0 274.514 0 133.574C0 48.3849 52.2421 0 175.485 0H175.515ZM336.396 567.946C336.396 644.737 281.023 691 175.515 691C60.6129 691 14.6339 644.707 14.6339 567.946C14.6339 495.369 78.3481 452.259 175.515 452.259C272.682 452.259 336.396 495.369 336.396 567.946Z'
                  fill='#DAFF01'
                />
              </svg>
            </MainTitle>
            <span
              className={isMobile ? 'teaser-mb-h1 text-white flex ' : 'teaser-h1 text-white flex '}
            >
              YEAR END
            </span>
            <span
              className={isMobile ? 'teaser-mb-h1 text-white flex ' : 'teaser-h1 text-white flex '}
            >
              SHOW <span className='text-lime'>25</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DarkTeaser
