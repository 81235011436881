import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  p {
    text-align: left;
    padding-left: 30px;
    color: #000000;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
  }
  position: relative;

  svg {
    top: 36px;
    color: #000000;
    right: 22px;
    transition: transform 330ms;
    cursor: pointer;
  }

  input {
    font-size: 2rem;
    height: 60px;
    border-radius: 10px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    outline: 2px solid ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  input:hover {
    outline: 4px solid ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
  }
  input:focus {
    outline: 4px solid ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }
  }
`

export const InputSearch = forwardRef(({ onChange, value, onSearchClick, isDarkMode }, ref) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      onSearchClick()
    }
  }

  return (
    <Container className='flex relative z-10 text-center items-center justify-center' isDarkMode={isDarkMode}>
      <div className='relative w-[480px]'>
        <input
          ref={ref}
          placeholder='Search the website'
          className='body-text text-black bg-white placeholder-black w-full'
          onChange={onChange}
          value={value}
          onKeyDown={handleKeyDown}
        />
        <svg
          width='26'
          height='26'
          viewBox='0 0 26 26'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='absolute'
          onClick={onSearchClick}
        >
          <g id='Frame 76'>
            <path
              id='Vector'
              d='M0.93934 22.9393C0.353553 23.5251 0.353553 24.4749 0.93934 25.0607C1.52513 25.6464 2.47487 25.6464 3.06066 25.0607L0.93934 22.9393ZM22.5 9.7C22.5 13.1242 19.7242 15.9 16.3 15.9V18.9C21.381 18.9 25.5 14.781 25.5 9.7H22.5ZM16.3 15.9C12.8758 15.9 10.1 13.1242 10.1 9.7H7.1C7.1 14.781 11.219 18.9 16.3 18.9V15.9ZM10.1 9.7C10.1 6.27584 12.8758 3.5 16.3 3.5V0.5C11.219 0.5 7.1 4.61898 7.1 9.7H10.1ZM16.3 3.5C19.7242 3.5 22.5 6.27583 22.5 9.7H25.5C25.5 4.61898 21.381 0.5 16.3 0.5V3.5ZM3.06066 25.0607L7.56066 20.5607L5.43934 18.4393L0.93934 22.9393L3.06066 25.0607ZM7.56066 20.5607L12.0607 16.0607L9.93934 13.9393L5.43934 18.4393L7.56066 20.5607Z'
              fill='#000000'
            />
          </g>
        </svg>
      </div>
    </Container>
  )
})

InputSearch.displayName = 'InputSearch'

export default InputSearch