import React, { useState, useEffect, useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import LoginForm from './styles/LoginForm';
import Error from './ErrorMessage';
import { CURRENT_USER_QUERY } from './User';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { client } from '../..';
import Loading from './Loading';

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      email
      firstName
      lastName
    }
  }
`;

const Signup = styled.p`
  font-size: 16px;
  font-weight: 300;
  display: inline;
  border-bottom: 1px solid;
`;

// Safari detection utility
const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const Signin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signin, { loading: loadingSignin, error: errorSignin }] = useMutation(SIGNIN_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const { token } = data.signin;

      // Check if we're in Safari or if cookies are blocked
      const shouldUseLocalStorage = isSafari() || !document.cookie.includes('token=');

      if (token && shouldUseLocalStorage) {
        localStorage.setItem('auth-token', token);
        console.log('Using localStorage fallback for token');
      }

      history.push('/projects');
    },
    onError: (error) => {
      console.error('Signin error:', error);
      // Clear any existing tokens on error
      localStorage.removeItem('auth-token');
    }
  });

  useEffect(() => {
    let isMounted = true;

    const checkAuth = async () => {
      try {
        // First try to get token from cookie
        const cookieToken = document.cookie
          .split('; ')
          .find(row => row.startsWith('token='))
          ?.split('=')[1];

        // Fallback to localStorage if no cookie
        const authToken = cookieToken || localStorage.getItem('auth-token');

        if (authToken) {
          const { data } = await client.query({
            query: CURRENT_USER_QUERY,
            fetchPolicy: 'network-only'
          });

          if (isMounted && data?.me) {
            history.push('/projects');
          }
        }
      } catch (error) {
        console.error('Auth check error:', error);
        // Clear invalid token
        localStorage.removeItem('auth-token');
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    checkAuth();

    return () => {
      isMounted = false;
    };
  }, [history]);

  const handleChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const handleChangePasswd = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  if (loading) return <Loading />;

  return (
    <div>
      <LoginForm
        method='post'
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            // Clear any existing tokens before new signin
            localStorage.removeItem('auth-token');

            await signin({
              variables: { email, password }
            });
          } catch (error) {
            console.error('Signin submission error:', error);
          }
        }}
      >
        <fieldset disabled={loadingSignin} aria-busy={loadingSignin}>
          <Error error={errorSignin} />
          <label htmlFor='email'>
            <input
              type='email'
              name='email'
              placeholder='email'
              value={email}
              onChange={handleChangeEmail}
              autoComplete='username'
            />
          </label>
          <label htmlFor='password'>
            <input
              type='password'
              name='password'
              placeholder='password'
              value={password}
              onChange={handleChangePasswd}
              autoComplete='current-password'
            />
          </label>

          <button type='submit'>Sign In!</button>
        </fieldset>
        <br />
        <br />
      </LoginForm>
    </div>
  );
};

export default Signin;