import React, { useMemo, useState, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import LetterForm from '../../../components/2025/LetterForm'
import { useQuery } from '@apollo/client'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { QUERY } from '../../../graphql'
import LetterBox from '../../../components/2025/LetterBox'
import { SpringContext } from '../../../context/SpringContext'
import { ThemeContext } from '../../../context/2025/ThemeContext'
import { useViewport } from '../../../hook/useViewport'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))

const SearchContainer = styled.div`
  p {
    text-align: left;
    padding-left: 30px;
    color: #000000;
    font-size: 1.3em;
    position: absolute;
    line-height: 60px;
    margin-bottom: 0;
    width: 100%;
  }
  position: relative;

  svg {
    top: 36px;
    color: #000000;
    right: 22px;
    transition: transform 330ms;
  }

  input {
    font-size: 2rem;
    height: 60px;
    border-radius: 10px;
    padding: 5px 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    outline: 2px solid
      ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
    display: ${(props) => (props.submitted ? 'none' : 'block')};
  }

  input:hover {
    outline: 4px solid
      ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
  }
  input:focus {
    outline: 4px solid
      ${(props) => (props.hasError ? 'red' : props.isDarkMode ? '#DAFF01' : '#9167F0')};
  }

  @media (min-width: 640px) {
    #email {
      width: 480px;
    }
  }
`

export default function Index() {
  const [gradsSearch, setGradsSearch] = useState('')
  const spring = useContext(SpringContext)
  const { isDarkMode } = useContext(ThemeContext)
  const inputRef = useRef(null)
  const { width } = useViewport()
  const isMobile = width < 1024

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }, [spring])

  const { data, loading } = useQuery(QUERY.LETTERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        createdAt_gt: '2025-03-31',
      },
      order: {
        createdAt: 'inc',
      },
    },
  })

  const filteredLetters = useMemo(() => {
    if (!data?.letters) {
      return []
    }
    let letters = data?.letters || []
    // update the data based on the search if there is any search in data.senders or data.recipients
    if (gradsSearch) {
      letters = letters.filter((letter) => {
        const { sender, recipient } = letter
        return (
          sender.toLowerCase().includes(gradsSearch.toLowerCase()) ||
          recipient.toLowerCase().includes(gradsSearch.toLowerCase())
        )
      })
    }

    return letters
  }, [data, gradsSearch])

  useEffect(() => {
    if (!loading && inputRef.current) {
      // Double RAF to ensure all DOM updates complete
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          inputRef.current.focus({ preventScroll: true })
        })
      })
    }
  }, [loading])

  const backgroundColors = {
    form: isDarkMode ? 'bg-black' : 'bg-white',
    letters: isDarkMode ? 'bg-[#222222]' : 'bg-light-grey-25',
    footer: isDarkMode ? 'bg-black' : 'bg-white',
  }

  const textColors = {
    heading: isDarkMode ? 'text-lime' : 'text-lavender',
    subheading: isDarkMode ? 'text-white' : 'text-black',
    light: isDarkMode ? 'text-white' : 'text-black',
    accent: isDarkMode ? 'text-lime' : 'text-lavender',
  }

  return (
    <div
      className={`absolute w-full ${
        isDarkMode ? 'bg-black' : 'bg-white'
      } flex flex-col items-center justify-start pb-20 lg:pb-0`}
    >
      {Header && <Header />}

      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-center gap-32'>
          <div className='relative w-full flex flex-col items-left justify-center px-[16px] lg:px-[48px] py-32'>
            <div className='relative grid grid-cols-1 lg:grid-cols-2 gap-14'>
              <div className='relative flex flex-col'>
                <div className='flex flex-col text-lg font-light text-black'>
                  <h1
                    className={`uppercase ${isMobile ? 'heading-1-mobile' : 'heading-1'} ${
                      isDarkMode ? 'text-lime' : 'text-lavender'
                    } `}
                    style={{ pointerEvents: 'none' }}
                  >
                    Send
                    <br />
                    your
                    <br />
                    wishes!
                  </h1>
                  <h4 className={`mt-4 lg:pr-[20%] ${textColors.subheading} heading-4-bold`}>
                    Leave your congratulatory messages for the graduating class of 2025!
                  </h4>
                </div>
              </div>
              <LetterForm />
            </div>
          </div>
        </div>
      </section>

      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-light-grey-25'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-center gap-32'>
          <div className='grid grid-cols-1 lg:grid-cols-2 w-full items-center'>
            {filteredLetters?.length === 0 ? (
              <p className={`${textColors.light} body-text`}>No messages found.</p>
            ) : filteredLetters?.length === 1 ? (
              <p className={`${textColors.light} body-text`}>
                There is <span className={textColors.accent}>1</span> message.
              </p>
            ) : filteredLetters?.length > 1 ? (
              <p className={`${textColors.light} body-text`}>
                There are <span className={textColors.accent}>{filteredLetters?.length}</span>{' '}
                messages.
              </p>
            ) : (
              <p className={`${textColors.light} body-text`}>Loading</p>
            )}
            <SearchContainer
              className='flex relative w-full items-end justify-end'
              isDarkMode={isDarkMode}
            >
              <input
                type='text'
                id='gname'
                name='gname'
                ref={inputRef}
                placeholder='Search by name'
                value={gradsSearch}
                className='body-text text-black bg-white placeholder-black w-full lg:w-4/5'
                onChange={(e) => setGradsSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              />
              <svg
                width='26'
                height='26'
                viewBox='0 0 26 26'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='absolute'
              >
                <g id='Frame 76'>
                  <path
                    id='Vector'
                    d='M0.93934 22.9393C0.353553 23.5251 0.353553 24.4749 0.93934 25.0607C1.52513 25.6464 2.47487 25.6464 3.06066 25.0607L0.93934 22.9393ZM22.5 9.7C22.5 13.1242 19.7242 15.9 16.3 15.9V18.9C21.381 18.9 25.5 14.781 25.5 9.7H22.5ZM16.3 15.9C12.8758 15.9 10.1 13.1242 10.1 9.7H7.1C7.1 14.781 11.219 18.9 16.3 18.9V15.9ZM10.1 9.7C10.1 6.27584 12.8758 3.5 16.3 3.5V0.5C11.219 0.5 7.1 4.61898 7.1 9.7H10.1ZM16.3 3.5C19.7242 3.5 22.5 6.27583 22.5 9.7H25.5C25.5 4.61898 21.381 0.5 16.3 0.5V3.5ZM3.06066 25.0607L7.56066 20.5607L5.43934 18.4393L0.93934 22.9393L3.06066 25.0607ZM7.56066 20.5607L12.0607 16.0607L9.93934 13.9393L5.43934 18.4393L7.56066 20.5607Z'
                    fill='#000000'
                  />
                </g>
              </svg>
            </SearchContainer>
          </div>
        </div>
      </section>

      <section className={`w-full ${backgroundColors.letters} transition-colors duration-300`}>
        <div className='max-w-[1440px] mx-auto w-full px-6 lg:px-[48px] pb-20'>
          <TransitionGroup className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-14'>
            {filteredLetters.length > 0 &&
              filteredLetters?.map((letter, index) => (
                <CSSTransition key={index} timeout={500} classNames='item'>
                  <LetterBox
                    key={`letter${index}`}
                    letter={letter}
                    index={index}
                    isDarkMode={isDarkMode}
                  />
                </CSSTransition>
              ))}
          </TransitionGroup>
        </div>
      </section>

      <div
        className={`relative w-full flex flex-col items-center justify-center w-full ${
          isDarkMode ? 'bg-black' : 'bg-light-grey-25'
        }`}
      >
        <div
          className={`relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-black max-w-[1440px] ${
            isDarkMode ? 'bg-black' : 'bg-light-grey-25'
          }`}
        >
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
