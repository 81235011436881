import React, { useContext, useState, useEffect, useRef } from 'react'
import { ThemeContext } from '../../../context/2025/ThemeContext'
import { Link } from 'react-router-dom'
import { useViewport } from '../../../hook/useViewport'
import { SpringContext } from '../../../context/SpringContext'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))

export default function Home2025() {
  const { isDarkMode } = useContext(ThemeContext)
  const videoRef = useRef(null)
  const containerRef = useRef(null)
  const scrollbarsRef = useRef(null) // Add this ref
  const { width } = useViewport()
  const isMobile = width < 1024
  const [videoReady, setVideoReady] = useState(false)
  const playbackSpeedFactor = 33.0
  // Get access to the scrollbars instance
  const springContext = useContext(SpringContext)

  useEffect(() => {
    if (!isMobile || !videoRef.current) return

    const video = videoRef.current

    const handleLoadedData = () => {
      // console.log('Video loaded successfully')
      video.pause()
      setVideoReady(true)
    }

    video.addEventListener('loadeddata', handleLoadedData)
    video.load()

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData)
    }
  }, [isMobile, isDarkMode])

  useEffect(() => {
    if (!isMobile || !videoReady || !containerRef.current) return

    const video = videoRef.current
    const container = containerRef.current
    let animationFrameId

    const handleScroll = (event) => {
      if (!video || !video.duration) return

      let scrollRatio

      if (event.detail) {
        // From custom scrollbars
        scrollRatio = event.detail.scrollRatio
      } else {
        // From native scroll
        const containerRect = container.getBoundingClientRect()
        const viewportHeight = window.innerHeight

        // Calculate position of container relative to viewport
        // This is more responsive than the previous calculation
        const containerTop = containerRect.top
        const containerHeight = containerRect.height

        // Calculate how much the container has moved through the viewport
        // 0 when container is at the bottom of viewport, 1 when it's at the top
        scrollRatio = 1 - (containerTop + containerHeight) / (viewportHeight + containerHeight)

        // Add a multiplier to make movement more dramatic
        scrollRatio = scrollRatio * playbackSpeedFactor
      }

      const clampedProgress = Math.max(0, Math.min(1, scrollRatio * 10))
      // console.log('Scroll Ratio:', scrollRatio, 'Clamped Progress:', clampedProgress, 'Video Duration:', video.duration, 'Video Current Time:', video.currentTime);
      video.currentTime = clampedProgress * video.duration
    }

    const optimizedScrollHandler = (e) => {
      animationFrameId = requestAnimationFrame(() => handleScroll(e))
    }

    // Set up listeners
    window.addEventListener('scroll', optimizedScrollHandler, { passive: true })
    window.addEventListener('touchmove', optimizedScrollHandler, { passive: true })
    window.addEventListener('customScroll', optimizedScrollHandler, { passive: true })

    // Also use spring if available
    if (springContext) {
      springContext.addListener({
        onSpringUpdate: (spring) => {
          const springValue = spring.getCurrentValue()
          video.currentTime = springValue * video.duration
        },
      })
    }

    // Initial setup
    handleScroll({})

    return () => {
      window.removeEventListener('scroll', optimizedScrollHandler)
      window.removeEventListener('touchmove', optimizedScrollHandler)
      window.removeEventListener('customScroll', optimizedScrollHandler)
      cancelAnimationFrame(animationFrameId)

      if (springContext && springContext.removeListener) {
        springContext.removeAllListeners()
      }
    }
  }, [isMobile, videoReady, springContext, isDarkMode])

  return (
    <div
      className={`absolute w-full bg-almost-black flex flex-col items-center justify-start pb-20 lg:pb-0  ${
        isDarkMode ? 'bg-black' : 'bg-white'
      } transition-colors duration-300 `}
    >
      {/* Header */}
      <Header />

      {/* Hero Section with Logo */}
      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-between gap-32'>
          <div className='w-full lg:w-1/2 flex justify-center lg:justify-start'>
            {/* YES:25 Logo - Different in light vs dark mode */}
            <div className='relative h-[300px] w-full' ref={containerRef}>
              <div className='relative h-full w-full'>
                {/* Static image */}
                <img
                  src={
                    isDarkMode
                      ? '/static/svg/2025/yes-25-big.svg'
                      : '/static/svg/2025/yes-25-big-light.svg'
                  }
                  alt='YES:25'
                  className='absolute inset-0 w-full h-full object-contain z-0  duration-1000 scale-[97.8%] transition-scale'
                />

                {/* Scroll-controlled video */}
                <video
                  ref={videoRef}
                  key={isDarkMode ? 'dark-video' : 'light-video'}
                  className={`absolute inset-0 w-full h-full object-contain ${
                    isMobile ? 'opacity-100' : 'opacity-0 hover:opacity-100'
                  } transition-opacity duration-1000 z-10`}
                  muted
                  playsInline
                  preload='auto'
                  disablePictureInPicture
                  onMouseEnter={!isMobile ? () => videoRef.current?.play() : undefined}
                  onMouseLeave={
                    !isMobile
                      ? () => {
                          videoRef.current?.pause()
                          videoRef.current.currentTime = 0
                        }
                      : undefined
                  }
                >
                  <source
                    src={
                      isDarkMode
                        ? '/static/2025/vid/yellow_light_v2.mp4'
                        : '/static/2025/vid/purple_light_v2.mp4'
                    }
                    type='video/mp4'
                  />
                </video>
              </div>
            </div>
          </div>
          <div className='w-full lg:w-1/2 px-6 mt-6 lg:mt-0 flex flex-col items-start max-w-[607px]'>
            <h3 className={`${isDarkMode ? 'text-lime' : 'text-lavender'} heading-3`}>May 1-2</h3>
            <h3 className={`${isDarkMode ? 'text-white' : 'text-black'} heading-3`}>
              George Brown College
            </h3>
            {isMobile ? (
              <>
                <h3 className={`${isDarkMode ? 'text-white' : 'text-black'} heading-3`}>
                  School of Design
                </h3>
                <h3 className={`${isDarkMode ? 'text-white' : 'text-black'} heading-3`}>
                  Year End Show
                </h3>
              </>
            ) : (
              <h3 className={`${isDarkMode ? 'text-white' : 'text-black'} heading-3`}>
                School of Design Year End Show
              </h3>
            )}

            <br />
            <a
              className={`${
                isDarkMode ? 'text-white hover:text-lime  ' : 'text-black hover:text-lavender  '
              } heading-3 pointer  hover:scale-[1.02] transition-transform duration-300`}
              href='https://www.instagram.com/sod_yes/'
              target='_blank'
              rel='noreferrer'
            >
              @sod_yes
            </a>
            {/* <p className={`${isDarkMode ? 'text-white' : 'text-black'} heading-3`}>@sod_yes</p> */}
          </div>
        </div>
      </section>

      {/* Join Us Section */}
      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-[#EDEDED]'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32'>
          <div className='flex flex-col lg:flex-row gap-8'>
            <div className='w-full lg:w-1/2 body-text pr-32'>
              <h2
                className={`${
                  isDarkMode ? 'text-lime' : 'text-lavender'
                } text-4xl font-bold uppercase mb-6 heading-2-caps`}
              >
                JOIN US FOR YES!
              </h2>
              <p className={`${isDarkMode ? 'text-white' : 'text-black'} mb-4`}>
                Experience the pinnacle of design innovation at YES!—George Brown College's premier
                annual graduate showcase for the School of Design. This spring, over 400 talented
                students will unveil the culmination of their creative journeys by presenting
                groundbreaking, interactive design solutions that have defined their studies.
              </p>
              <p className={`${isDarkMode ? 'text-white' : 'text-black'} mb-6`}>
                Join us May 1-2 as we transform our campus into a dynamic exhibition space spanning
                three floors. Celebrate the ingenuity and passion of our students and faculty across
                twelve diverse programs, and witness firsthand the future of design in action.
              </p>
              <Link
                to='/events'
                className={`${
                  isDarkMode ? 'text-white' : 'text-lavender'
                } link inline-block wavy-text-link theme-transition`}
              >
                Learn more about our event <span className='body-text-bold'>→</span>
              </Link>
            </div>
            <div className='w-full lg:w-1/2'>
              <img
                src='/static/2024/img/Frame54.jpeg'
                alt='Students at YES exhibition'
                className='w-full h-full object-cover rounded'
                onError={(e) => {
                  e.target.src =
                    'https://via.placeholder.com/600x400/333333/FFFFFF?text=Exhibition+Photo'
                }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* School of Design Section */}
      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32'>
          <div className='flex flex-col lg:flex-row gap-8'>
            <div className='w-full lg:w-1/2 order-2 lg:order-1'>
              <img
                src='/static/2024/img/GBCSOD.png'
                alt='School of Design'
                className='w-full h-full object-cover rounded'
                onError={(e) => {
                  e.target.src =
                    'https://via.placeholder.com/600x400/000000/DAFF01?text=School+of+Design'
                }}
              />
            </div>
            <div className='w-full lg:w-1/2 order-1 lg:order-2 lg:pl-32'>
              <h2
                className={`${
                  isDarkMode ? 'text-lime' : 'text-lavender'
                } text-4xl font-bold uppercase mb-6 heading-2-caps`}
              >
                YES! WE'RE THE <br />
                SCHOOL OF DESIGN
              </h2>
              <p className={`${isDarkMode ? 'text-white' : 'text-black'} mb-4 body-text`}>
                Located in the heart of Downtown Toronto, the School of Design at George Brown
                College offers a dynamic and inclusive environment for aspiring designers to
                flourish. As a team, we communicate and exchange ideas, fostering a strong cohort
                that values inclusivity and diversity.
              </p>
              <Link
                to='/about'
                className={`${
                  isDarkMode ? 'text-white' : 'text-lavender'
                } link inline-block wavy-text-link theme-transition`}
              >
                Learn more about School of Design <span className='body-text-bold'>→</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Programs Section */}
      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-[#EDEDED]'
        } transition-colors duration-300 py-32`}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px]'>
          <div className='flex flex-row items-center justify-between flex-wrap mb-12'>
            <h2
              className={`${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } text-4xl font-bold uppercase heading-2-caps`}
            >
              PROGRAMS
            </h2>
            <Link
              to='/work'
              className={`${
                isDarkMode ? 'text-white' : 'text-lavender'
              } link inline-block wavy-text-link theme-transition`}
            >
              Check out all students' work <span className='body-text-bold'>→</span>
            </Link>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-32 lg:gap-16'>
            {/* Visual Design */}
            <div className='flex flex-col'>
              <div className='bg-[#444] mb-4 aspect-square'>
                <img
                  src='/static/2024/gif/VisualDesign.gif'
                  alt='Visual Design'
                  className='w-full h-full object-cover'
                  onError={(e) => {
                    e.target.parentNode.style.backgroundColor = '#f05'
                  }}
                />
              </div>
              <h3 className={`${isDarkMode ? 'text-white' : 'text-lavender'} heading-4-bold mb-2`}>
                Visual Design
              </h3>
              <ul className={`${isDarkMode ? 'text-white' : 'text-black'} link space-y-1`}>
                <Link
                  to='/work?program=Art%20&%20Design%20Foundation'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Art & Design Foundation
                </Link>
                <br />
                <Link
                  to='/work?program=Graphic%20Design'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Graphic Design
                </Link>
              </ul>
            </div>

            {/* Game Design */}
            <div className='flex flex-col'>
              <div className='bg-[#444] mb-4 aspect-square'>
                <img
                  src='/static/2024/gif/GameDesign.gif'
                  alt='Game Design'
                  className='w-full h-full object-cover'
                  onError={(e) => {
                    e.target.parentNode.style.backgroundColor = '#f50'
                  }}
                />
              </div>
              <h3 className={`${isDarkMode ? 'text-white' : 'text-lavender'} heading-4-bold mb-2`}>
                Game Design
              </h3>
              <ul className={`${isDarkMode ? 'text-white' : 'text-black'} link space-y-1 `}>
                <Link
                  to='/work?program=Concept%20Art'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Concept Art
                </Link>
                <br />
                <Link
                  to='/work?program=Game%20Art'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Game Art
                </Link>
                <br />
                <Link
                  to='/work?program=Game%20Design'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Game Design
                </Link>
              </ul>
            </div>

            {/* Interactive Design */}
            <div className='flex flex-col'>
              <div className='bg-[#444] mb-4 aspect-square'>
                <img
                  src='/static/2024/gif/InteractiveDesign.gif'
                  alt='Interactive Design'
                  className='w-full h-full object-cover'
                  onError={(e) => {
                    e.target.parentNode.style.backgroundColor = '#05f'
                  }}
                />
              </div>
              <h3 className={`${isDarkMode ? 'text-white' : 'text-lavender'} heading-4-bold mb-2`}>
                Interactive Design
              </h3>
              <ul className={`${isDarkMode ? 'text-white' : 'text-black'} link space-y-1`}>
                <Link
                  to='/work?program=Interaction%20Design'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Interaction Design
                </Link>
                <br />
                <Link
                  to='/work?program=Digital%20Experience%20Design'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Digital Experience Design
                </Link>
                <br />
                <Link
                  to='/work?program=Immersive%20Media%20Development'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Immersive Media Development
                </Link>
                <br />
                <Link
                  to='/work?program=Front%20End%20Design'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Front End Design
                </Link>
              </ul>
            </div>

            {/* Strategic Design */}
            <div className='flex flex-col'>
              <div className='bg-[#444] mb-4 aspect-square'>
                <img
                  src='/static/2024/gif/StrategicDesign.gif'
                  alt='Strategic Design'
                  className='w-full h-full object-cover'
                  onError={(e) => {
                    e.target.parentNode.style.backgroundColor = '#0f5'
                  }}
                />
              </div>
              <h3 className={`${isDarkMode ? 'text-white' : 'text-lavender'} heading-4-bold mb-2`}>
                Strategic Design
              </h3>
              <ul className={`${isDarkMode ? 'text-white' : 'text-black'} link space-y-1`}>
                <Link
                  to='/work?program=Advertising%20and%20Digital%20Strategy'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Advertising and Digital Strategy
                </Link>
                <br />
                <Link
                  to='/work?program=Design%20Management'
                  className={`group ${
                    isDarkMode ? 'text-white' : 'text-lavender'
                  } link inline-block dotted-text-link theme-transition`}
                >
                  <span
                    className={
                      isDarkMode
                        ? 'text-lime group-hover:text-black'
                        : 'text-lavender group-hover:text-black'
                    }
                  >
                    ◼
                  </span>{' '}
                  Design Management
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Showreel Section */}
      <section
        className={`w-full ${
          isDarkMode ? 'bg-black' : 'bg-white'
        } transition-colors duration-300 py-32`}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-16'>
          <h2
            className={`${
              isDarkMode ? 'text-lime' : 'text-lavender'
            } text-4xl font-bold uppercase mb-8 heading-2-caps`}
          >
            SHOWREEL
          </h2>

          <div className='relative w-full aspect-video rounded overflow-hidden'>
            {/* Video placeholder */}
            <div className='absolute inset-0 bg-[#111] flex items-center justify-center'>
              <img
                src='/static/2024/img/showreel.jpg'
                alt='YES 2025 Showreel'
                className='w-full h-full object-cover'
                onError={(e) => {
                  e.target.parentNode.innerHTML = `
                    <div class="flex items-center justify-center w-full h-full bg-[#111]">
                      <span class="${
                        isDarkMode ? 'text-lime' : 'text-lavender'
                      } text-2xl transition-colors duration-300 ">Video Showreel</span>
                    </div>
                  `
                }}
              />
              <div className='absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
                <button
                  className={`w-20 h-20 rounded-full ${
                    isDarkMode ? 'bg-lime' : 'bg-lavender'
                  } transition-colors duration-300  flex items-center justify-center`}
                >
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M6 4v16l14-8L6 4z' fill='black' />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className={`relative w-full ${
          isDarkMode ? ' bg-black' : 'bg-[#EDEDED]'
        } flex flex-col items-center justify-center w-full`}
      >
        <div
          className={`relative bottom-0 w-full px-[16px] lg:px-[48px] ${
            isDarkMode ? ' bg-black' : 'bg-[#EDEDED]'
          }  max-w-[1440px]`}
        >
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
