import React, { useState, useRef, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { programGroups24Encoded, projectType, disciplines } from '../../constant'
import { ThemeContext } from '../../context/2025/ThemeContext'

const FilterSelect = styled.div`
  border-radius: 48px;
`

export const DropdownFilter = ({ program, type }) => {
  const [isProgramFilter, setIsProgramFilter] = useState(true)
  const [isFilterClicked, setIsFilterClicked] = useState(false)
  const [selectedFilterItem, setSelectedFilterItem] = useState('')
  const { pathname, search } = useLocation()
  const eventDetailsRef = useRef({ currentTarget: null, target: null })
  const { isDarkMode } = useContext(ThemeContext)

  const isMobile = window.innerWidth < 1024

  // Get current filter from URL
  useEffect(() => {
    const params = new URLSearchParams(search)
    const programParam = params.get('program')
    const typeParam = params.get('type')

    if (programParam) {
      setSelectedFilterItem(programParam)
      setIsProgramFilter(true)
    } else if (typeParam) {
      setSelectedFilterItem(typeParam)
      setIsProgramFilter(false)
    } else {
      setSelectedFilterItem('')
    }
  }, [search])

  // Theme-aware styling functions
  const filterSelectClass = (isActive) =>
    cn('body-text-sm rounded px-6 py-2 border cursor-pointer', {
      'bg-black text-white border-white': isDarkMode && !isActive,
      'bg-white text-black border-black': !isDarkMode && !isActive,
      'bg-lime text-black border-lime ': isDarkMode && isActive,
      'bg-lavender text-black border-lavender ': !isDarkMode && isActive,
    })

  const filterItemClass = (isActive) =>
    cn('w-full flex items-center justify-center h-[64px] border hover:text-black', {
      'border-white text-white hover:bg-lime': isDarkMode && !isActive,
      'border-black text-black hover:bg-lavender': !isDarkMode && !isActive,
      'text-black bg-lime border-lime ': isDarkMode && isActive,
      'text-black bg-lavender border-lavender ': !isDarkMode && isActive,
    })

  const handleFilterClick = (e) => {
    setIsFilterClicked((current) => !current)
    eventDetailsRef.current = {
      currentTarget: e.currentTarget,
      target: e.target,
    }
  }

  const renderFilterItems = (items, isEncoded, paramName) => {
    // Decode items if they're encoded (for program filters)
    const displayItems = isEncoded ? items.map((item) => decodeURIComponent(item)) : items

    return (
      <div
        className={`grid grid-cols-1 lg:grid-cols-6 ${
          isFilterClicked
            ? 'h-[380px] lg:h-[128px] overflow-y-auto'
            : 'h-[64px] lg:h-[128px] overflow-hidden'
        }`}
        onClick={handleFilterClick}
        style={{
          overflowX: 'hidden',
          scrollbarWidth: 'none',
        }}
      >
        {isMobile && !isFilterClicked && selectedFilterItem !== '' && (
          <Link
            key={'selectedFilterItem'}
            className={filterItemClass(true)}
            to={{
              pathname,
              search: `?${paramName}=${
                isEncoded ? encodeURIComponent(selectedFilterItem) : selectedFilterItem
              }`,
            }}
          >
            <p
              className={`flex items-center justify-center h-full w-full px-12 text-center body-text-sm !font-bold`}
            >
              {selectedFilterItem}
            </p>
          </Link>
        )}

        <Link
          className={filterItemClass(
            selectedFilterItem === '' &&
              ((paramName === 'program' && isProgramFilter) ||
                (paramName === 'type' && !isProgramFilter)),
          )}
          to={{ pathname }}
          onClick={() => setSelectedFilterItem('')}
        >
          <p className='flex items-center justify-center text-center h-full w-full body-text-sm'>
            Show All
          </p>
        </Link>

        {displayItems.map((displayItem, index) => {
          const originalItem = isEncoded ? items[index] : displayItem
          return (
            <Link
              key={originalItem}
              className={filterItemClass(selectedFilterItem === displayItem)}
              to={{
                pathname,
                search: `?${paramName}=${isEncoded ? originalItem : displayItem}`,
              }}
              onClick={() => setSelectedFilterItem(displayItem)}
            >
              <p
                className={`flex items-center justify-center h-full w-full px-12 text-center body-text-sm ${
                  selectedFilterItem === displayItem ? '!font-bold' : ''
                }`}
              >
                {displayItem}
              </p>
            </Link>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className='w-full grid grid-cols-1 gap-10'
      style={{
        color: isDarkMode ? 'white' : 'black',
      }}
    >
      <div className='w-full flex gap-4'>
        <FilterSelect
          className={filterSelectClass(isProgramFilter)}
          onClick={() => setIsProgramFilter(true)}
        >
          <p className={`body-text-sm ${isProgramFilter ? '!font-bold' : ''}`}>Filter by program</p>
        </FilterSelect>
        <FilterSelect
          className={filterSelectClass(!isProgramFilter)}
          onClick={() => setIsProgramFilter(false)}
        >
          <p className={`body-text-sm ${!isProgramFilter ? '!font-bold' : ''}`}>Filter by discipline</p>
        </FilterSelect>
      </div>

      {isProgramFilter
        ? renderFilterItems(programGroups24Encoded, true, 'program')
        : renderFilterItems(Object.values(disciplines), false, 'type')}
    </div>
  )
}
