import { Link } from 'react-router-dom'
import UserNavStyles from './styles/UserNavStyles'
import Signout from './Signout'
import React from 'react'
import Loading from './Loading'
import { gql, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import LayeredToggle from '../2025/LayeredToggle'

const UserNav = ({ color = '#000' }) => {
  const { data, loading } = useQuery(CURRENT_USER_QUERY)

  if (loading) return <Loading size='mini' />
  if (!data || !data.me) return <p />
  return (
    <UserNavStyles>
      {data.me && (
        <>
          <LayeredToggle />
          <Link to='/projects' style={{ color }}>
            Projects
          </Link>
          <Link to='/edit' style={{ color }}>
            Profile
          </Link>
          <Signout color={color} />
        </>
      )}
      {!data.me && (
        <Link to='/login' style={{ color }}>
          Log In
        </Link>
      )}
    </UserNavStyles>
  )
}

// Add prop type validation
UserNav.propTypes = {
  color: PropTypes.string
}

export default UserNav

const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      tagline
      program
      primaryExpertise
      secondaryExpertise
      about
      image
      portfolio
      contactEmail
      behance
      linkedIn
      instagram
      twitter
      facebook
      dribble
      youtube
      vimeo
      medium
      permissions
    }
  }
`