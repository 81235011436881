import React from 'react'
import { createRoot } from 'react-dom/client'
import * as serviceWorker from './serviceWorker'
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter } from 'react-router-dom'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { HelmetProvider } from 'react-helmet-async'
import App from './App'
import './styles.scss'

const cache = new InMemoryCache()

// Create a link to capture response headers
const responseHeadersLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const responseHeaders = context.response && context.response.headers;

    // Store the most recent response globally for header access
    if (typeof window !== 'undefined' && responseHeaders) {
      window.lastGraphQLResponse = context.response;

      // Check for auth token in headers
      const authToken = responseHeaders.get('X-Auth-Token');
      if (authToken) {
        localStorage.setItem('auth-token', authToken);
      }

      // Check for auth action instructions
      const authAction = responseHeaders.get('X-Auth-Action');
      if (authAction === 'CLEAR_TOKEN') {
        localStorage.removeItem('auth-token');
        // Also clear any cached user data
        cache.evict({ fieldName: 'me' });
        cache.gc();
      }
    }

    return response;
  });
});

// Auth link to add token from localStorage to headers
const authLink = setContext((_, { headers }) => {
  // Get token from localStorage
  const token = localStorage.getItem('auth-token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

// Create the upload link
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_ENDPOINT || 'https://api.georgebrown.ca/graphql',
  credentials: 'include',
  fetch,
  fetchOptions: {
    credentials: 'include',
    mode: 'cors',
  },
});

// Combine the links
export const client = new ApolloClient({
  ssrMode: typeof window === 'undefined', // set to true for SSR
  cache: cache,
  link: ApolloLink.from([
    responseHeadersLink,
    authLink,
    uploadLink
  ]),
});

const container = document.getElementById('root')
const root = createRoot(container) // Create a root.

root.render(
  // <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
      </BrowserRouter>
    </HelmetProvider>
//  </React.StrictMode>,
)

// Service worker setup remains the same
serviceWorker.unregister()