import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Psm } from '../../styles/2025/TextStyles'
import cn from 'classnames'
import { ThemeContext } from '../../context/2025/ThemeContext'
import { SpringContext } from '../../context/SpringContext'

export default function Archive({ showBackToTop = true }) {
  const spring = useContext(SpringContext)
  const [isExpanded, setIsExpanded] = useState(false)
  const timeoutId = useRef(null)
  const { isDarkMode } = useContext(ThemeContext)
  const location = useLocation()

  // Get current year from URL
  const currentYear = location.pathname.split('/')[1]

  const handleScrollTop = () => {
    if (spring) {
      spring.setCurrentValue(0).setAtRest()
      spring.setEndValue(0)
    }
  }

  useEffect(() => {
    if (spring) {
      spring.addListener({ onSpringUpdate: handleSpringUpdate })
      return () => spring.removeListener(handleSpringUpdate)
    }
  }, [spring])

  const handleSpringUpdate = (spring) => {
    const value = spring.getCurrentValue()
    // Use this value to update UI
  }

  const menuClasses = cn(
    'fixed bottom-0 left-[16px] lg:left-[48px] bg-black h-screen transition-transform duration-500 cursor-pointer',
    {
      'translate-y-[100vh] opacity-80': !isExpanded,
      'translate-y-0 opacity-100': isExpanded,
    },
  )

  const handleExpand = () => {
    if (!isExpanded) {
      setIsExpanded(true)
      if (timeoutId.current) clearTimeout(timeoutId.current)
      timeoutId.current = setTimeout(() => {
        setIsExpanded(false)
        timeoutId.current = null
      }, 3000)
    } else {
      setIsExpanded(false)
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }

  // Archive years data
  const archiveYears = [
    { year: '2020', bgColor: '#efd6d9', textColor: '#26439B' },
    { year: '2021', bgColor: '#5933ff', textColor: '#defc1c' },
    { year: '2022', path: '/2022/home', bgColor: '#FF6044', textColor: '#26439B' },
    { year: '2023', path: '/2023/home', bgColor: '#26439B', textColor: '#C7C2E1' },
    { year: '2024', path: '/2024/home', bgColor: '#181818', textColor: '#71DF71' },
  ].filter(item => item.year !== currentYear) // Filter out current year

  return (
    <div className={`fixed bottom-0 left-0 w-full ${isDarkMode ? 'bg-black' : 'bg-white'} h-16 z-50 center-text`}>
      <div className={`absolute w-full ${isDarkMode ? 'bg-black' : 'bg-white'} h-16 z-50 op-1/2 left-1/2 transform -translate-x-1/2 max-w-[1440px]`}>
        <button onClick={handleExpand} className='text-5xl inline-block mt-6 mb-20'>
          <div className={`absolute flex items-center justify-center left-[16px] lg:left-[48px] h-16 ${isDarkMode ? 'bg-black' : 'bg-white'} z-50 gap-4 bottom-[-4px]`}>
            {isExpanded ? (
              <svg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M9.64645 2.87736L9.79289 3.02381H10H16C16.2009 3.02381 16.3927 3.0633 16.568 3.13504L5.00488 3.03266C4.19182 3.02546 3.51934 3.65738 3.47101 4.46386L3.36936 4.98479L2.44373 8.68731L1.66624 11.7973C1.51049 12.4203 1.98168 13.0238 2.62387 13.0238C3.07285 13.0238 3.46529 12.7208 3.5789 12.2864L5.38068 5.39727C5.43822 5.17726 5.63699 5.02379 5.86441 5.02379H7.19685H18.2326C18.9869 5.02379 19.6852 5.8728 19.4581 6.90105L18.1332 12.901C17.9783 13.6028 17.4446 14.0238 16.9077 14.0238L16.0097 14.0238L16.0085 14.0238L16 14.0238L15.9988 14.0238L2 14.0238C1.17157 14.0238 0.5 13.3522 0.5 12.5238V2.52381C0.500003 2.01159 0.500745 1.66982 0.528196 1.42276C0.555804 1.17428 0.601634 1.12924 0.603513 1.12739C0.60353 1.12738 0.603544 1.12736 0.603554 1.12735C0.603563 1.12734 0.603577 1.12733 0.603593 1.12731C0.605441 1.12543 0.650486 1.0796 0.898961 1.052C1.14601 1.02455 1.48778 1.02381 2 1.02381H7.79289L9.64645 2.87736Z' fill={isDarkMode ? '#DAFF01' : '#9167F0'} stroke={isDarkMode ? '#DAFF01' : '#9167F0'} />
              </svg>
            ) : (
              <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M1 2.52383V12.5238C1 13.6284 1.89543 14.5238 3 14.5238H17C18.1046 14.5238 19 13.6284 19 12.5238V4.52383C19 3.41926 18.1046 2.52383 17 2.52383H11L9 0.523827H3C0.999988 0.523827 1.00001 0.523804 1 2.52383Z' fill={isDarkMode ? '#ffffff' : '#9167F0'} stroke={isDarkMode ? '#ffffff' : '#9167F0'} strokeLinecap='round' strokeLinejoin='round' />
              </svg>
            )}
            <Psm className={`body-text-sm ${isDarkMode ? 'text-white' : 'text-black'} w-64 text-left`}>
              Archive
            </Psm>
          </div>
        </button>

        <div className={menuClasses}>
          {archiveYears.map((item, index) => (
            <Link key={item.year} to={{ pathname: item.path || `/${item.year}` }} className='text-5xl inline-block mt-6 mb-20'>
              <div
                className={cn('w-64')}
                style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '41px',
                  bottom: `${(archiveYears.length - index) * 40}px`,
                  backgroundColor: item.bgColor,
                }}
              >
                <p
                  style={{
                    color: item.textColor,
                    fontFamily: 'sharp_grotesque',
                    fontSize: '42px',
                    lineHeight: '100%',
                    letterSpacing: '0.02em',
                    background: 'transparent',
                  }}
                >
                  {item.year}
                </p>
              </div>
            </Link>
          ))}
        </div>

        {showBackToTop && (
          <button onClick={handleScrollTop} className='text-5xl inline-block mt-6 mb-20'>
            <div className={`absolute flex items-center justify-center bottom-0 right-[16px] lg:right-[48px] z-50 gap-4 bottom-[8px]`}>
              <Psm className={`body-text-sm ${isDarkMode ? 'text-white hover:text-lime' : 'text-black hover:text-lavender'} m-0`}>
                Back to top
              </Psm>
              {isDarkMode ? (
                <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' className='mb-[3px]'>
                  <path d='M7 0.523804L13.9282 12.5238H0.0717969L7 0.523804Z' fill='#DAFF01' />
                </svg>
              ) : (
                <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' className='mb-[3px]'>
                  <path d='M7 0.523804L13.9282 12.5238H0.0717969L7 0.523804Z' fill='#9167F0' />
                </svg>
              )}
            </div>
          </button>
        )}
      </div>
    </div>
  )
}