import React, { useEffect, useMemo, useState, useContext, useRef } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { DropdownFilter } from '../../../components/2025/DropdownFilter'
import qs from 'query-string'
import groupBy from 'lodash.groupby'
import { QUERY } from '../../../graphql'
import shuffle from 'lodash.shuffle'
import { TextCenterDiv } from '../../../styles/2024/GradsStyles'
import { GradCard } from '../../../components/2025/GradCard'
import { programGroups24 as programGroups, projectTypeMap25 } from '../../../constant'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { SpringContext } from '../../../context/SpringContext'
import { useViewport } from '../../../hook/useViewport'
import { ThemeContext } from '../../../context/2025/ThemeContext'
import { SearchContainer } from '../../../styles/2025/Styles'
import { use } from 'react'

const Header = React.lazy(() => import('../../../components/2025/Header'))
const Footer = React.lazy(() => import('../../../components/2025/Footer'))
const FullScreenLoader = React.lazy(() => import('../../../components/FullScreenLoader'))
const Pagination = React.lazy(() => import('../../../components/2025/Pagination'))

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'p',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const ITEMS_PER_PAGE = 12

export default function Grads() {
  const location = useLocation()
  const { search: searchParams } = location
  const { program, type, page } = qs.parse(searchParams)
  const [gradsSearch, setGradsSearch] = useState('')
  const spring = useContext(SpringContext)

  const { isDarkMode } = useContext(ThemeContext)

  const inputRef = useRef(null)
  const history = useHistory()
  const { width } = useViewport()
  const isMobile = width < 1024

  useEffect(() => {
    console.log('Grads page mounted')
  }, [])

  useEffect(() => {
    if (spring != null && spring !== undefined) {
      spring.setCurrentValue(-1000).setAtRest()
      spring.setEndValue(-1000)
    }
  }, [spring])

  const { data, loading } = useQuery(QUERY.USERS_QUERY, {
    variables: {
      where: {
        studentYear: 2025,
        activeAt_gt: '2024-12-31',
        nameSearch: gradsSearch,
      },
    },
  })

  const grads = useMemo(() => {
    if (!data?.users) {
      return {}
    }
    let users = data?.users || []

    if (program) {
      users = users.filter((user) => user.program === program)
    }
    if (type) {
      console.log('TYPE: ', type)

      let typeSafe = type?.replace(' ', '_').replace('/', '_').toLowerCase()
      if (typeSafe === '3d_art_animation') {
        typeSafe = 'art_animation_3d'
      }
      const typeList = projectTypeMap25[typeSafe]
      users = users.filter((stu) => {
        const stdPrimaryExpertise = stu.primaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
        const stdSecondaryExpertise = stu.secondaryExpertise?.replace(' ', '_')?.toLowerCase() || ''
        if (!stdPrimaryExpertise) return typeList.includes(stdSecondaryExpertise)
        if (!stdSecondaryExpertise) return typeList.includes(stdPrimaryExpertise)
        return typeList.includes(stdPrimaryExpertise) || typeList.includes(stdSecondaryExpertise)
      })
    }

    const grouped = groupBy(shuffle(users), 'program')

    const obj = {}
    programGroups.forEach((key) => {
      if (grouped[key]?.length > 0) {
        obj[key] = grouped[key]
      }
    })

    return obj
  }, [data, program, type])

  const gradsSorted = useMemo(() => {
    const alphabetKey = {}
    Object.keys(grads).forEach((key) => {
      const sorted = grads[key].sort(function (a, b) {
        var textA = a.firstName.toUpperCase()
        var textB = b.firstName.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      alphabetKey[key] = sorted
    })

    let gradList = []
    Object.entries(alphabetKey).forEach(([gradName, students]) => {
      students.map((student) => {
        return gradList.push(student)
      })

      const obj = {}
      alphabet.forEach((char) => {
        obj[char] = students
          .filter((grad) => !!grad.firstName)
          .filter((grad) => grad.firstName.charAt(0).toUpperCase() === char)
      })
      alphabetKey[gradName] = obj
    })

    gradList.sort(function (a, b) {
      var textA = a.firstName?.toUpperCase() || ''
      var textB = b.firstName?.toUpperCase() || ''
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })

    return gradList
  }, [grads])

  // Pagination logic
  const currentPage = parseInt(page) || 1
  const totalPages = Math.ceil(gradsSorted.length / ITEMS_PER_PAGE)
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const endIndex = startIndex + ITEMS_PER_PAGE
  const paginatedGrads = gradsSorted.slice(startIndex, endIndex)

  const handlePageChange = (newPage) => {
    const params = qs.parse(location.search)
    const newSearch = qs.stringify({ ...params, page: newPage })

    // Update URL without triggering scroll
    history.push(`${location.pathname}?${newSearch}`)

    // Only scroll if user clicked a pagination button (not on initial load)
    if (currentPage !== newPage) {
      if (spring) {
        spring.setCurrentValue(900).setAtRest()
        spring.setEndValue(900)
      }
    }
  }

  useEffect(() => {
    const params = qs.parse(location.search)
    if (params.page && (params.page !== '1' || gradsSorted.length < ITEMS_PER_PAGE)) {
      history.replace({
        pathname: location.pathname,
        search: qs.stringify({ ...params, page: 1 }),
      })
    }
  }, [gradsSearch, program, type, history, location.pathname, gradsSorted.length])

  useEffect(() => {
    if (!loading && inputRef.current) {
      // Double RAF to ensure all DOM updates complete
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          inputRef.current.focus({ preventScroll: true });
        });
      });
    }
  }, [loading]);
  require('./grads.scss')

  return (
    <div
      className={`absolute w-full min-h-screen ${
        isDarkMode ? 'bg-black' : 'bg-white'
      }flex flex-col items-center justify-start pb-20 lg:pb-0`}
    >
      {loading && (
        <FullScreenLoader
          color={isDarkMode ? `#DAFF01` : `#9167F0`}
          style={{ position: 'fixed' }}
        />
      )}
      {Header && <Header />}

      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-center gap-32'>
          <TextCenterDiv className=' px-[16px] lg:px-[48px]'>
            <h1
              className={`${isMobile ? 'heading-1-mobile !text-[50px]' : 'heading-1 '} ${
                isDarkMode ? 'text-lime' : 'text-lavender'
              } `}
              style={{ pointerEvents: 'none' }}
            >
              Graduates
            </h1>
            {isMobile ? (
              <div className='text-center pt-[16px]'>
                <h3
                  className={`heading-3b ${
                    isDarkMode ? 'text-white' : 'text-black'
                  } normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  YES! We're innovative
                </h3>
                <h3
                  className={`heading-3b ${
                    isDarkMode ? 'text-white' : 'text-black'
                  } normal-case m-0`}
                  style={{ pointerEvents: 'none' }}
                >
                  problem solvers!
                </h3>
              </div>
            ) : (
              <h3
                className={`heading-3b ${isDarkMode ? 'text-white' : 'text-black'} normal-case m-0`}
                style={{ pointerEvents: 'none' }}
              >
                YES! We're innovative problem solvers!
              </h3>
            )}
          </TextCenterDiv>
        </div>
      </section>

      <section
        className={`w-full ${isDarkMode ? 'bg-black' : 'bg-white'} transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] w-full mx-auto px-6 lg:px-[48px] py-32 flex flex-col lg:flex-row items-center justify-between gap-32'>
          <div className='w-full flex gap-6 px-[16px] lg:px-[48px]'>
            <DropdownFilter program={program} type={type} onChangeSearchGrads={setGradsSearch} />
          </div>
        </div>
      </section>

      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-grey-25'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] flex flex-col lg:flex-row items-center justify-between gap-32'>
          <div
            className={`grid grid-cols-1 lg:grid-cols-2 w-full mt-16 items-center ${
              isDarkMode ? 'bg-[#222222]' : 'bg-grey-25'
            } px-[16px] lg:px-[48px]`}
          >
            {gradsSorted?.length === 0 ? (
              <p className={`heading-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                No graduates found.
              </p>
            ) : gradsSorted?.length === 1 ? (
              <p className={`heading-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                There is <span className='text-lime'>1</span> graduate.
              </p>
            ) : gradsSorted?.length > 1 ? (
              <p className={`heading-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                There are{' '}
                <span className={`${isDarkMode ? 'text-lime' : 'text-lavender'}`}>
                  {gradsSorted?.length}
                </span>{' '}
                graduates.
              </p>
            ) : (
              <p className='text-white'>Loading</p>
            )}
            <SearchContainer
              className='flex relative w-full items-end justify-end'
              isDarkMode={isDarkMode}
            >
              <input
                type='text'
                id='gname'
                name='gname'
                ref={inputRef}
                placeholder='Search graduates'
                className='body-text text-black bg-white placeholder-black w-full lg:w-4/5'
                value={gradsSearch}
                onChange={(e) => setGradsSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault() // Prevent form submission
                  }
                }}
              />
              <svg
                width='26'
                height='26'
                viewBox='0 0 26 26'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                className='absolute'
              >
                <g id='Frame 76'>
                  <path
                    id='Vector'
                    d='M0.93934 22.9393C0.353553 23.5251 0.353553 24.4749 0.93934 25.0607C1.52513 25.6464 2.47487 25.6464 3.06066 25.0607L0.93934 22.9393ZM22.5 9.7C22.5 13.1242 19.7242 15.9 16.3 15.9V18.9C21.381 18.9 25.5 14.781 25.5 9.7H22.5ZM16.3 15.9C12.8758 15.9 10.1 13.1242 10.1 9.7H7.1C7.1 14.781 11.219 18.9 16.3 18.9V15.9ZM10.1 9.7C10.1 6.27584 12.8758 3.5 16.3 3.5V0.5C11.219 0.5 7.1 4.61898 7.1 9.7H10.1ZM16.3 3.5C19.7242 3.5 22.5 6.27583 22.5 9.7H25.5C25.5 4.61898 21.381 0.5 16.3 0.5V3.5ZM3.06066 25.0607L7.56066 20.5607L5.43934 18.4393L0.93934 22.9393L3.06066 25.0607ZM7.56066 20.5607L12.0607 16.0607L9.93934 13.9393L5.43934 18.4393L7.56066 20.5607Z'
                    fill='#000000'
                  />
                </g>
              </svg>
            </SearchContainer>
          </div>
        </div>
      </section>

      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-grey-25'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-between gap-32'>
          <TransitionGroup
            className={`grid grid-cols-1 lg:grid-cols-3 gap-16 pt-10 px-[16px] pb-16 lg:px-[48px] ${
              isDarkMode ? 'bg-[#222222]' : 'bg-grey-25'
            }`}
          >
            {paginatedGrads?.length > 0 &&
              paginatedGrads.map((grad) => (
                <CSSTransition key={grad.id} timeout={500} classNames='item'>
                  <GradCard grad={grad} key={grad.id} />
                </CSSTransition>
              ))}
          </TransitionGroup>
        </div>
      </section>
      <section
        className={`w-full ${
          isDarkMode ? 'bg-[#222222]' : 'bg-light-grey-25'
        } transition-colors duration-300 `}
      >
        <div className='max-w-[1440px] mx-auto px-6 lg:px-[48px] py-32 flex flex-col items-center justify-between gap-32'>
          {totalPages > 1 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              itemsPerPage={ITEMS_PER_PAGE}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      </section>
      <div
        className={`relative w-full flex flex-col items-center justify-center w-full ${
          isDarkMode ? 'bg-black' : 'bg-light-grey-25'
        }`}
      >
        <div
          className={`relative bottom-0 w-full pt-16 px-[16px] lg:px-[48px] bg-black max-w-[1440px] ${
            isDarkMode ? 'bg-black' : 'bg-light-grey-25'
          }`}
        >
          {Footer && <Footer />}
        </div>
      </div>
    </div>
  )
}
