import React, { useEffect, useState, useContext } from 'react'
import { programToImage, programColour, projectTypeMap, disciplines } from '../../constant'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../context/2025/ThemeContext'

export const GradCard = ({ grad, scrollTop }) => {
  const { isDarkMode } = useContext(ThemeContext)
  const [gradImage, setGradImage] = useState(programToImage[grad?.program])
  const isValidUrl = (string) => {
    try {
      new URL(string)
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    const _gradImage =
      grad?.image && isValidUrl(grad.image) ? encodeURI(grad.image) : programToImage[grad?.program]
    setGradImage(_gradImage)
  }, [grad])

  return (
    <div className={`flex flex-col self-stretch ${isDarkMode ? 'bg-black' : 'bg-white'}`}>
      <Link
        to={{
          pathname: '/student',
          search: `id=${grad?.id}`,
        }}
        onClick={() => {
          scrollTop && scrollTop()
        }}
        className='h-[300px] w-full overflow-hidden flex items-center justify-center'
      >
        <img
          src={gradImage}
          alt={grad?.firstName}
          className='object-cover w-full h-full grayscale'
          onError={() => setGradImage(programToImage[grad?.program])}
        />
      </Link>
      <div
        className={`flex flex-col self-stretch ${isDarkMode ? 'bg-black' : 'bg-white'} py-8 px-4`}
      >
        <Link
          to={{
            pathname: '/student',
            search: `id=${grad?.id}`,
          }}
          onClick={() => {
            scrollTop && scrollTop()
          }}
        >
          <h4
            className={`heading-4-bold mt-2 cursor-pointer ${
              isDarkMode ? '!text-white hover:text-lime' : 'text-black  hover:text-lavender'
            } `}
          >
            {grad?.firstName} {grad?.lastName}
          </h4>
        </Link>
        <div className='flex flex-wrap justify-start items-center gap-4 pt-6'>
          <p
            className={`body-text  ${
              isDarkMode ? 'text-white ' : 'text-black'
            } inline-block p-0 m-0`}
          >
            <span className={`${isDarkMode ? 'text-lime ' : 'text-lavender'}`}>◼</span>{' '}
            {grad?.program}
          </p>
          {grad?.primaryExpertise && (
            <p
              className={`body-text  ${
                isDarkMode ? 'text-white ' : 'text-black'
              } inline-block p-0 m-0`}
            >
              {/* ◼ {disciplines[projectTypeMap[grad?.primaryExpertise]]} */}◼{' '}
              {grad?.primaryExpertise}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
