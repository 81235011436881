import React, { useContext } from 'react';
import { ThemeContext } from '../../context/2025/ThemeContext';

const LayeredToggle = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <button
      onClick={toggleTheme}
      className="focus:outline-none"
      aria-label={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
    >
      <div className='relative w-[62px] h-[24px] rounded-full theme-bg-lavender overflow-hidden'>
        {/* Rays with transition */}
        <img
          className={`absolute ${isDarkMode ? '' : 'rotate-180 right-0'}`}
          src='/static/svg/2025/rays.svg'
          alt='Rays'
        />

        {/* Sun and Moon with transition */}
        <img
          className={`absolute px-[2px] transition-all duration-300 top-[2px]
            ${
              isDarkMode
                ? 'left-[0px] hover:left-[2px]'
                : 'invert brightness-[200] right-[0px] hover:right-[2px]'
            }`}
          src='/static/svg/2025/sun.svg'
          alt='Sun & Moon'
        />
      </div>
    </button>
  );
};

export default LayeredToggle;